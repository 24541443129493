import React, { useState } from "react";
import { useSelector } from "react-redux";
import {
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Paper,
} from "@mui/material";

const headerCellStyles = {
  fontWeight: 600,
  fontFamily: "DM Sans",
  fontSize: "16px",
  lineHeight: "20px",
  backgroundColor: "#FFFFFF",
  color: "#252525",
  borderRight: "0.8px solid #A9A9A9",
};

const cellStyles = {
  fontFamily: "DM Sans",
  fontSize: "14px",
  lineHeight: "18px",
  color: "#252525",
  borderRight: "0.8px solid #A9A9A9",
};

export const CompetitorInterchange = () => {
  const { interchanges } = useSelector((state) => state.CommonStore);

  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("interchange_name");

  if (!interchanges.length) {
    return null;
  }

  // Sorting function
  const sortData = (array, order, orderBy) => {
    return [...array].sort((a, b) => {
      const aValue = a[orderBy];
      const bValue = b[orderBy];

      if (order === "asc") {
        return aValue < bValue ? -1 : aValue > bValue ? 1 : 0;
      } else {
        return aValue > bValue ? -1 : aValue < bValue ? 1 : 0;
      }
    });
  };

  const handleSort = (property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const sortedData = sortData(interchanges, order, orderBy);

  return (
    <Box
      className="interchange_Competitor"
      sx={{
        width: "50%",
      }}
    >
      <Box
        sx={{
          borderRadius: "13px",
          border: "1px solid #ccc",
          backgroundColor: "#EAEAEA",
          height: "auto",
          maxHeight: "570px",
        }}
      >
        {/* Header Section */}
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            marginBottom: "10px",
            marginLeft: "2%",
          }}
        >
          <Typography
            sx={{
              fontFamily: "DM Sans",
              fontWeight: 700,
              fontSize: "16px",
              color: "#55120B",
              paddingTop: "20px",
            }}
          >
            Competitor Interchange
          </Typography>
        </Box>

        {/* Table Section */}
        <TableContainer
          component={Paper}
          sx={{
            maxHeight: "450px",
            overflowY: "auto",
            "&::-webkit-scrollbar": {
              width: "3px",
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: "#EC1B24",
              borderRadius: "4px",
            },
            "&::-webkit-scrollbar-track": {
              backgroundColor: "#D3D3D3",
            },
          }}
        >
          <Table stickyHeader>
            <TableHead>
              <TableRow>
              <TableCell sx={headerCellStyles}
                  sortDirection={orderBy === "interchange_name" ? order : false}
                >
                  <TableSortLabel
                    active={orderBy === "interchange_name"}
                    direction={orderBy === "interchange_name" ? order : "asc"}
                    onClick={() => handleSort("interchange_name")}
                  >
                    Competitors Name
                  </TableSortLabel>
                </TableCell>
              <TableCell sx={headerCellStyles}
                  sortDirection={orderBy === "partIntPartNumber" ? order : false}
                >
                  <TableSortLabel
                    active={orderBy === "partIntPartNumber"}
                    direction={orderBy === "partIntPartNumber" ? order : "asc"}
                    onClick={() => handleSort("partIntPartNumber")}
                  >
                    Cross Reference
                  </TableSortLabel>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {sortedData.map((intc, index) => (
                <TableRow
                  key={index}
                  sx={{
                    backgroundColor: index % 2 === 0 ? "#EAEAEA" : "#FFFFFF",
                  }}
                >
                  <TableCell sx={cellStyles}>{intc.interchange_name}</TableCell>
                  <TableCell align="center" sx={cellStyles}>{intc.partIntPartNumber}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Box>
  );
};
