import { Box, Button, Typography } from "@mui/material";
import React from "react";
import PwrReady from "../../Assets/PwrReady.png";
import GridViewIcon from "@mui/icons-material/GridView";
import { useDispatch } from "react-redux";
import { getNewRealses } from "../ProductPages/ClassicSearchAction";
import { useNavigate } from "react-router-dom";
import routes from "../../AppRoutes/routes.json";

const ViewProducts = () => {
 const dispatch=useDispatch()
 const navigate=useNavigate();

  return (
    <Box
      className="View_Products_container"

      sx={{
        position: "relative",
        height: "480px",
        backgroundColor: "#000000",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        marginTop:'10%'
      }}
    >
      <Box sx={{ position: "absolute", marginTop: -5 }}>
        <img
          src={PwrReady}
          alt="pwrReady"
          style={{ width: "231px", height: "183px" }}
        />
      </Box>
      <Box sx={{ marginTop: 20 }}>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {" "}
          <Typography
           className="view_product_main_text"
            sx={{
              fontWeight: 500,
              fontSize: "36px",
              color: "#EC1B24",
              fontFamily: "DM Sans",
            }}
          >
            When Your Reputation Is on the Line,
          </Typography>
        </Box>
        <Box
         
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography
           className="view_product_main_text"
            sx={{
              fontWeight: 500,
              fontSize: "36px",
              color: "#ffffff",
              fontFamily: "DM Sans",
              textAlign:'center'
            }}
          >
            Choose ALL NEW Installation-Ready Power Steering
          </Typography>
        </Box>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            marginTop: 1,
          }}
        >
          <Typography
             className="view_product_sub_text"
            sx={{ fontWeight: 400, fontSize: "14px", color: "#D5D7D6",   fontFamily: "DM Sans", }}
          >
            For unmatched reliability and easy installation, choose the all-new
            power steering solution.
          </Typography>
        </Box>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            marginTop: 4,
            paddingBottom:'150px'

          }}
        >
          <Button
            onClick={()=>{dispatch(getNewRealses(1)); sessionStorage.clear(); navigate(routes.Ecatlog)}}
            variant="contained"
            sx={{
              textTransform: "none",
              width: "182px",
              height: "50px",
              borderRadius: "6100px",
              backgroundColor: "#333333",
              fontFamily: "DM Sans",
              fontWeight:500,
              fontSize:'14px',
              color:'#D5D7D6',
            }}
            startIcon={<GridViewIcon sx={{ color: "#EC1B24" }} />}
          >
            View Products
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default ViewProducts;
