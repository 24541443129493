import React from "react";
import PDF from "../../Assets/Warranty/pdfdownload.png";
import { Box, Typography } from "@mui/material";

const EnglishWarrantyStat = () => {
  return (
    <Box className="war-stat-sub-section">
      <Typography
        className="state-head-typo"
        sx={{
          width: "100%",
          height: "auto",
          fontFamily: "DM Sans",
          fontWeight: 600,
          fontSize: "25px",
          lineHeight: "27px",
          color: "#55120B",
        }}
      >
        Power Steering Warranty Statement
      </Typography>
      <Box
        className="content-main-box"
        sx={{
          width: "100%",
          height: "auto",
          marginTop: "3%",
          fontFamily: "DM Sans",
          fontWeight: 400,
          fontSize: "18px",
          lineHeight: "27px",
          color: "#000000",
        }}
      >
        <Box
          className="content-first-second-row-box">
          <Typography
            className="content-first-second-row-typo"
            sx={{
              fontFamily: "DM Sans",
              fontWeight: 400,
              fontSize: "18px",

              lineHeight: "30px",
              color: "#000000",
            }}
          >
            PWR Steer warrants its Power Steering Products to be free from
            defects in materials or workmanship when used on privately owned,
            non-commercial vehicles for as long as such original purchaser owns
            the vehicle on which the part was originally installed.
            <br /> <br />
            The warranty will begin upon the date of first installation on the
            original vehicle. Subject to the specific limitations as listed in
            this document, the product, when installed in accordance with PWR
            Steer installation instructions, will be exchanged if returned to
            the seller who is authorized to represent this warranty. Proof of
            purchase may be required. PWR Steer approved instructions are
            packaged with the PWR Steer product, available through authorized
            PWR Steer distributors, or may be found at www.pwrsteer.com.
            <br />
            <br />
            This warranty may exclude failures that are the direct result of,
            but not limited to:
          </Typography>
        </Box>
        <br />
        <Box>
          <ol
            className="stat-list-ol"
            style={{
              width: "100%",
              fontFamily: "DM Sans",
              fontWeight: 400,
              fontSize: "18px",
              color: "#000000",
              paddingLeft: "10px",
              marginTop: "10px",
            }}
          >
            <li>
              Alterations to the OEM suspension and steering system design
            </li>
            <br />
            <li>
              Alterations to wheel and tire size or vehicle height that exceed
              the OEM vehicle specifications • Use of non- OE approved fluids
            </li>
            <br />
            <li>
              Internal damage from excessive system debris or extended operation
              of the vehicle when fluid level is below the OEM minimum capacity
              specification
            </li>
            <br />
            <li>Non-compliance with PWR Steer installation instructions</li>
            <br />
            <li>
              Exceeding the OEM specific gross vehicle weight or towing
              limitations • Installation on a vehicle that is not specified in
              the PWR Steer catalog application data
            </li>
            <br />
            <li>
              Fire, flood, explosions, collisions, external damage, physical
              alterations, disassembly, or missing components
            </li>
            <br />
            <li>
              Use on Commercial Vehicles for more than 18 months from
              installation. This warranty is in lieu of all other warranties
              either expressed or implied, including the implied warranty of
              merchantability and fitness for purposes which are expressly
              excluded. PWR Steer shall not be liable for consequential or
              incidental damages resulting from or caused by the use, operation,
              failure, or defect of any PWR Steer product. This warranty grants
              you specific legal rights, and you may also have other rights
              which vary from state to state. Oct
            </li>
          </ol>

          <Box
            sx={{
              width: "100%",

              height: "auto",
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-start",
              alignItems: 'center',
              gap: 3,
              marginTop: "70px",
            }}
          >
            {/* PDF Icon Section */}
            <Box>
              <a
                download
                aria-label="Download PDF"
                style={{ display: "flex", alignItems: "center" }}
                onClick={() =>
                  window.open(
                    "https://www.pwrsteer.com/docs/Lifetime_Warranty_English_v2.pdf",
                    "_blank",
                    "noopener,noreferrer"
                  )
                }
              >
                <img
                  src={PDF}
                  alt="Download PDF Icon"
                  style={{ cursor: "pointer" }}
                />
              </a>
            </Box>
            <Box
              sx={{
                width: "100%",
                height: "74px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-start",
              }}
            >
              <Box
                sx={{
                  width: "191px",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Typography
                  sx={{
                    fontFamily: "DM Sans",
                    fontWeight: 800,
                    fontSize: "18px",
                    lineHeight: "20px",
                    color: "#252525",
                    marginTop: "5px",
                  }}
                >
                  Power Steering Warranty Statement
                </Typography>
              </Box>
              <Box
                sx={{
                  width: "100%",
                }}
              >
                <Typography
                  onClick={() =>
                    window.open(
                      "https://www.pwrsteer.com/docs/Lifetime_Warranty_English_v2.pdf",
                      "_blank",
                      "noopener,noreferrer"
                    )
                  }
                  style={{
                    cursor: "pointer",
                    fontFamily: "DM Sans",
                    color: "#EC1B24",
                    fontSize: "16px",
                    fontWeight: 600,
                  }}
                  tabIndex={0}
                  onKeyDown={(e) => {
                    if (e.key === "Enter" || e.key === " ") {
                      window.open(
                        "https://www.pwrsteer.com/docs/Lifetime_Warranty_English_v2.pdf",
                        "_blank",
                        "noopener,noreferrer"
                      );
                    }
                  }}
                >
                  Download Now
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default EnglishWarrantyStat;
