import { Box, Button, Typography } from "@mui/material";
import React, { useState } from "react";
import audi from "../../Assets/BackgroudImages/Audi.png";
import "../Home/Css/Home.css";

import AutoComplete from "../CommonComponent/AutoComplete";
import { useDispatch } from "react-redux";
import { Routes, useNavigate } from "react-router-dom";
import { getNewRealses } from "../ProductPages/ClassicSearchAction";
import routes from "../../AppRoutes/routes.json"
import CommonLoader from "../CommonComponent/CommonLoader";


const PwrProductSearch = () => {

  const dispatch=useDispatch()
  const navigate=useNavigate()
  const [openModal, setOpenModal] = useState(false);

  const handleClosee = () => {
    setOpenModal(false);
  };
  const handleOpen = () => {
    setOpenModal(true);
  };
  return (
    <Box
      className="pwr_product_search_caontainer"
      sx={{
        backgroundImage: `url(${audi})`,
        width: "100%",
        // display: "flex",
        marginTop:'-80px',
        backgroundSize: "cover",
       // Ensure the background covers the container
        // backgroundPosition: 'center', // Center the image
        backgroundRepeat: "no-repeat",
      }}
    >
      <Box
        className="pwr_search_main_container"
        sx={{
          width: "100%",
          alignItems: "center",
          display: "flex",
          flexDirection: "column",
          marginTop: "270px",
        }}
      >
        <Typography
          className="pwr_search_maintext"
         sx={{letterSpacing:"-2px", lineHeight:'43px'}}
        >
         <span  className="pwr_serch_new" style={{fontFamily: "DM Sans, sans-serif"}} >New</span> {" "}
          <span
            className="pwr_serch_new"
            style={{ color: "#EC1B24", fontFamily: "DM Sans",letterSpacing:"-2px" }}
          >
            Power Steering
          </span>
        </Typography>
        <Typography
          className="pwr_search_product_text"
          sx={{
            color: "white",
            fontWeight: 700,
            fontSize: "75px",
            fontFamily: "DM Sans",
            letterSpacing:"-2px"
          }}
        >
          Products
        </Typography>
      </Box>
      <Box
        sx={{
          width: "100%",
          alignItems: "center",
          display: "flex",
          flexDirection: "column",
          marginTop: "30px",
        }}
      >
        <AutoComplete />
      </Box>
      {/* <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          gap: 2,
          width: "100%",
          alignItems: "center",
          justifyContent: "center",
          marginTop: "25px",
        }}
      >
        <Button className="pwr-home-search-btns"
          variant="outlined"
          sx={{
            textTransform: "none",
            borderColor: "white",
            color: "white",
            borderRadius: "50px",
            fontFamily: "DM Sans",
            fontWeight: 400,
            fontSize: "12px",
          }}

          onClick={()=>{dispatch(getNewRealses(1,setOpenModal));navigate(routes.Ecatlog)}}
        >
          Power Steering Pump
        </Button>
        <Button className="pwr-home-search-btns"
         onClick={()=>{dispatch(getNewRealses(2,setOpenModal));navigate(routes.Ecatlog)}}
          variant="outlined"
          sx={{
            textTransform: "none",
            borderColor: "white",
            color: "white",
            borderRadius: "50px",
            fontFamily: "DM Sans",
            fontWeight: 400,
            fontSize: "12px",
          }}
        >
          Power Steering Rack
        </Button>
        <Button className="pwr-home-search-btns"
          variant="outlined"
          onClick={()=>{dispatch(getNewRealses(3,setOpenModal));navigate(routes.Ecatlog)}}
          sx={{
            textTransform: "none",
            borderColor: "white",
            color: "white",
            borderRadius: "50px",
            fontFamily: "DM Sans",
            fontWeight: 400,
            fontSize: "12px",
          }}
        >
          Steering Gear Box
        </Button>
      </Box> */}
      <CommonLoader
        open={openModal}
        handleOpen={handleOpen}
        handleClose={handleClosee}
      />
    </Box>
  );
};

export default PwrProductSearch;
