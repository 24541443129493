import React,{useEffect} from "react";
import "./App.css";
import AppRoutes from "./AppRoutes/AppRoutes";
import { Provider } from "react-redux";
import Store, { persistor } from "./Store/store"; // Import persistor
import { PersistGate } from "redux-persist/integration/react"; // Import PersistGate

function App() {

  

  return (
    <Provider store={Store}>
      {/* Wrap your app with PersistGate */}
      <PersistGate loading={null} persistor={persistor}>
        <AppRoutes />
      </PersistGate>
    </Provider>
  );
}

export default App;
