import React from "react";
import { CommonSetupActions } from "../../../Slices/Commonslice";
import routes from "../../../AppRoutes/routes.json";
import { ApiLink } from "../../Utils/Apilink";

export const getProductAppData = (partNo, navigate, product,ymm,setOpenModal) => {
  return (dispatch, getState) => {
    const { selectedPart, selectedProduct,selectYear,selectMake,selectModel } = getState().CommonStore;
    console.log("product", product, selectedProduct);
  if(setOpenModal){
    setOpenModal(true)
  }
    try {
      console.log("getProductAppData:", partNo,ymm);
     

    ;

      fetch(ApiLink + "websiteProductPage/" + partNo, {
        method: "GET",
        headers: {
          "Content-Type": "application/json; charset=utf-8",
        },
      })
        .then((resp) => {
          console.log("product display...status", resp);
          return resp.json();
        })
        .then((response) => {
          console.log("response", response);
          console.log("app res:: ", response.applications);
          dispatch(CommonSetupActions.setApplication(response.applications));
          dispatch(CommonSetupActions.setProductImages(response.imageInfo));
          dispatch(
            CommonSetupActions.setBulletinsPdf(response.techBulletinsPDF)
          );
          dispatch(CommonSetupActions.setSpecification(response.product_specs));
          dispatch(CommonSetupActions.setInterchange(response.interchanges));
          if(setOpenModal){
            setOpenModal(false)
          }

          if (product.hasOwnProperty("type")) {
            sessionStorage.clear()
            navigate(
              `/product-page/${partNo}/${ymm}/${slugify(
                product.value.split("===")[0]
              )}`
            );
          } else if (product.hasOwnProperty("note")) {

            if(selectedProduct.type==="VIN"){
              sessionStorage.clear()
              navigate(
                `/product-page/${partNo}/${slugify(
                  selectedProduct.value
                  .split("!!")[5] +"-"+
                    selectedProduct.value.split("!!")[4] +"-"+
                    selectedProduct.value.split("!!")[3]
                )}/${product.newparttypename}`
              );
            }
           else{

            if(selectedProduct.hasOwnProperty('type')){
              sessionStorage.clear()
              navigate(
                `/product-page/${partNo}/${slugify(
                  selectedProduct.label.split(" ")[0] +"-"+
                    selectedProduct.label.split(" ")[1] +"-"+
                    selectedProduct.label.split(" ")[2]
                )}/${product.newparttypename}`
              );
            }
            else{
              sessionStorage.clear()
              navigate(
                `/product-page/${partNo}/${slugify(
                 selectYear +"-"+
                  selectMake.label +"-"+
                   selectModel.label
                )}/${product.newparttypename}`
              );
            }
           
           }
          } 
          
          else {
            sessionStorage.clear()
            navigate(
              `/product-page/${partNo}/${product.ymm}/${product.newparttypename}`
            );
          }
        window.scrollTo({top:0})
          // setLoading(false);
        });
    } catch (err) {
      console.log(err);
    }
  };
};
export const slugify = (text) => {
  return text
    .toString()
    .toLowerCase()
    .trim()
    .replace(/[\s]+/g, "-") // Replace spaces with -
    .replace(/[^\w\-]+/g, ""); // Remove all non-word chars
};
