import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Typography } from "@mui/material";
import LayoutComponent from "../../Layout/LayoutComponent";
import CommonFooter from "../Footer/CommonFooter";
import bgImg from "../../Assets/Rack&Pinions/rackBg.png";
import routes from "../../AppRoutes/routes.json";
import GearBoxFig from "../../Assets/Rack&Pinions/ReservoirDetails.jpg";
import gearIcon from "../../Assets/Rack&Pinions/gearIcon.png";
import GearBox from "../../Assets/Rack&Pinions/Reservoir_plain.jpg";
import MaskGroup from "../../Assets/Rack&Pinions/Reservoir.jpeg";
import rack2 from "../../Assets/Rack&Pinions/Reservoir_3.jpg";
import Video from "../CommonComponent/CommonVideos";

export const Reservoirs = () => {
  const navigate = useNavigate();
  useEffect(() => {
    if (!sessionStorage.getItem("hasRefreshed")) {
      sessionStorage.setItem("hasRefreshed", "true"); // Mark as refreshed
      window.location.reload();
    }
  }, []);
  const RESERVOIRS_FEATURES = [
    {
      label: "OE-Grade Fit, Form, and Function",
      description:
        "Designed to match or exceed the original reservoir for seamless integration.",
    },
    {
      label: "Supplied with Reservoir Cap",
      description:
        "New caps included where required for a complete replacement solution.",
    },
    {
      label: "Built-In Brackets (Where Applicable)",
      description:
        "Ensures easy installation and proper alignment with the steering system.",
    },
    {
      label: "Seals, Washers, and Clamps Included",
      description:
        "All necessary hardware is included for a leak-free installation.",
    },
    {
      label: "Prevents Pump Starvation",
      description:
        "Ensures consistent fluid flow and prevents contamination in the system.",
    },
    {
      label: "Durable Construction",
      description:
        "Made with high-quality materials to withstand temperature and pressure variations.",
    },
    {
      label: "Comprehensive Vehicle Coverage",
      description:
        "Covers 70% of vehicles in operation (VIO) across North America.",
    },
    {
      label: "Rigorous Testing",
      description:
        "Each reservoir undergoes pressure and leakage testing to ensure optimal performance.",
    },
  ];

  <Box component="ul">
    {RESERVOIRS_FEATURES.map((feature, index) => (
      <li key={index} style={{ marginBottom: "10px" }}>
        <strong>{feature.label}:</strong> {feature.description}
      </li>
    ))}
  </Box>;

  return (
    <LayoutComponent>
      <Box
        sx={{
          position: "relative",
          width: "100%",
          boxSizing: "border-box",
          display: "flex",
          flexDirection: "column",
        }}
      >
        {/* 1st row -- main image */}
        <Box className="bgSection" sx={{ position: "relative", zIndex: 1 }}>
          <Box
            className="bgSubSection"
            id="reservoirs-bgSubSection"
            sx={{
              position: "relative",
              width: "100%",
              height: "auto",
              overflow: "hidden",
            }}
          >
            {/* Image */}
            <img
              src={bgImg}
              className="bgImage"
              alt="Steering Gear Box"
              style={{
                objectFit: "cover",
                width: "100%",
                height: "100%",
                filter: "brightness(0.7)",
              }}
            />
            {/* Dark Overlay */}
            <Box
              className="darkOverlay"
              sx={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                backgroundColor: "rgba(0, 0, 0, 0.6)",
              }}
            ></Box>
          </Box>

          {/* Navigation Box */}
          <Box
            className="navBox"
            sx={{
              position: "absolute",
              top: "10%",
              // marginTop:'30px',
              left: "8%",
            }}
          >
            <Typography
              className="navTypography"
              sx={{
                fontSize: "14px",
                fontWeight: 500,
                display: "flex",
                alignItems: "center",
                gap: "12px",
                color: "#FFFFFF",
              }}
            >
              <Typography
                className="navLink"
                onClick={() => {
                  sessionStorage.clear();
                  navigate(routes.Home);
                }}
                tabIndex={0}
                onKeyDown={(e) => {
                  if (e.key === "Enter" || e.key === " ") {
                    sessionStorage.clear();
                    navigate(routes.Home);
                    window.scrollTo({ top: 0 });
                  }
                }}
                sx={{
                  fontFamily: "DM Sans",
                  fontWeight: 400,
                  lineHeight: "27px",
                  textAlign: "center",
                  color: "#FFFFFF",
                  textDecoration: "none",
                  cursor: "pointer",
                }}
              >
                Home
              </Typography>
              <span
                style={{
                  color: "#EC1B24",
                }}
              >
                &gt;
              </span>

              <Typography
                className="navLink"
                sx={{
                  color: "#FFFFFF",
                  fontWeight: 700,
                  fontFamily: "DM Sans",
                  textDecoration: "none",
                  lineHeight: "27px",
                  textAlign: "center",
                  cursor: "pointer",
                }}
              >
                Reservoirs
              </Typography>
            </Typography>
          </Box>
          {/* end of navigation */}

          {/* Floating Box */}
          <Box
            className="floatMainBox"
            id="reservoir_float"
            sx={{
              position: "absolute",
              top: "95%",
              left: "50%",
              transform: "translate(-50%, -90%)",
              width: "80%",

              // width: "1500px",
              height: "auto",
              backgroundColor: "white",
              borderRadius: "13px",
              boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
              border: "0.7px solid #808080",
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              padding: "40px",
              gap: 0,
              // marginTop: "40px",
            }}
          >
            {/* left box */}

            <Box
              className="floatLeftBox"
              sx={{
                width: "40%",
                // height: "102px",
              }}
            >
              <Box sx={{ width: "auto" }}>
                <Typography
                  className="floatHeading"
                  sx={{
                    fontFamily: "DM Sans",
                    fontWeight: 700,
                    fontSize: "51px",
                    lineHeight: "65.79px",
                    letterSpacing: "1%",
                    color: "#0E1415",
                    display: "inline-block", // Ensures accurate width calculation
                    // textDecoration: 'underline',
                    // textDecorationColor: 'red',
                    // textUnderlineOffset: '15px'
                  }}
                >
                  Reservoirs
                  <hr
                    style={{
                      // width: "auto",
                      color: "#E30000",
                      borderRadius: "5px",
                      border: "2px solid #E30000",
                    }}
                  />
                </Typography>
              </Box>
            </Box>

            {/* right side box */}

            <Box
              className="floatImageBox"
              sx={{
                width: "60%",
                height: "100%",
                // display:'contents',

                // height: "auto",
                // marginTop:'100px'
              }}
            >
              <Box sx={{ width: "100%", height: "90%" }}>
                <img
                  src={GearBoxFig}
                  style={{
                    width: "80%",
                    height: "auto",
                    objectFit: "contain",
                  }}
                  alt="Power Steering Gear"
                  className="rightImgBox"
                />
              </Box>
            </Box>
          </Box>
        </Box>
        <Box
          className="fourth-fifth-sixth-main"
          id="reservoir_fourth-fifth-sixth-main"
          sx={{
            padding: "60px",
            padding: "60px",
            maxWidth: "100vw",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {/* 2nd Row */}

          <Box
            className="keyFeatMain"
            sx={{
              position: "relative",
              // marginTop: "8%",
              display: "flex",
              // flexWrap: 'wrap',
              flexDirection: "row",
              justifyContent: "center",
              // alignItems: "center",
              gap: 6,
              width: "100%",
            }}
          >
            {/* Left Box */}
            <Box
              className="leftMain_container"
              sx={{ width: "50%", height: "100%" }}
            >
              <Box
                className="rightMainSection"
                sx={{
                  width: "100%",
                  // marginTop: "24px",
                  display: "flex",
                  flexDirection: "column",
                  gap: 2,
                }}
              >
                <Box
                  className="rightMainHead"
                  sx={{
                    width: "100%",
                    // height: "102px",
                  }}
                >
                  <Typography
                    className="rightHeading"
                    sx={{
                      fontFamily: "DM Sans",
                      fontWeight: 700,
                      fontSize: "50px",
                      lineHeight: "65px",
                      color: "#000000",
                    }}
                  >
                    Performance and Reliability You Can Count On
                  </Typography>
                </Box>
                <Box
                  className="rightContentSection"
                  sx={{
                    width: "100%",
                  }}
                >
                  <Typography
                    className="rightContent"
                    sx={{
                      fontFamily: "DM Sans",
                      fontWeight: 400,
                      fontSize: "14px",
                      lineHeight: "25px",
                      color: "#252525",
                    }}
                  >
                    PWR Power Steering Reservoirs are engineered to maintain
                    consistent hydraulic fluid flow while protecting the power
                    steering system from contamination and wear. Built with
                    OE-grade precision, our reservoirs ensure a seamless
                    installation and reliable operation, helping technicians get
                    it right the first time.
                  </Typography>
                </Box>
                <Box
                  className="third-box-main"
                  sx={{
                    width: "100%",
                    height: "auto",
                    // backgroundColor: "#FAFAFA",
                    // marginTop: 5,
                  }}
                >
                  <Box
                    className="third-box-sub"
                    sx={{
                      width: "100%",
                      height: "100%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <img
                      className="steerPump3img"
                      id="reservoirs3_img"
                      src={GearBox}
                      style={{
                        width:'55%',height:'auto',
                        // width:'500px',height:'400px',
                        objectFit:'contain'
                      }}
                      alt="Reservoirs"
                    />
                  </Box>
                </Box>
              </Box>
            </Box>

            {/* Right Box */}
            <Box
              className="leftMain_container"
              sx={{
                width: "50%",
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "flex-start",
              }}
            >
              <Box
                className="leftMain"
                sx={{
                  width: "100%",
                  height: "auto",

                  borderRadius: "19px",
                  backgroundColor: "#F0F3F3",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                  padding: "40px",
                }}
              >
                <Box
                  className="Left-sub-section"
                  sx={{
                    width: "90%",
                    // height: "553px",
                    height: "auto",
                    backgroundColor: "#FFFFFF",
                    borderRadius: "10px",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    padding: "40px",
                    // marginTop: "38px",
                  }}
                >
                  <Box
                    className="leftHeadingBox"
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      flexDirection: "row",
                      gap: "10px",
                      width: "100%",
                    }}
                  >
                    <img
                      className="gearIconImg"
                      src={gearIcon}
                      alt="key points"
                      style={{
                        width: "32px",
                        height: "32px",
                      }}
                    />
                    <Typography
                      className="leftHeadingText"
                      sx={{
                        fontFamily: "DM Sans",
                        fontWeight: 700,
                        fontSize: "19px",
                        lineHeight: "27px",
                        color: "#000",
                      }}
                    >
                      Key Features
                    </Typography>
                  </Box>

                  <Box
                    className="listSection"
                    sx={{
                      // height: "461px",
                      color: "#252525",
                    }}
                  >
                    <ul className="leftList">
                      {RESERVOIRS_FEATURES.map((feature, index) => (
                        <li
                          className="listItem"
                          key={index}
                          style={{
                            width: "auto",
                            height: "auto",
                            color: "#252525",
                            marginBottom: "10px",
                            fontWeight: 400,
                            fontSize: "14px",
                            lineHeight: "22px",
                            fontFamily: "DM Sans",
                          }}
                        >
                          <strong>{feature.label}:</strong>{" "}
                          {feature.description}
                        </li>
                      ))}
                    </ul>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>

          {/* 4th row */}
          <Box
            className="fourth-box-main"
            sx={{
              width: "100%",
              height: "auto",
              display: "flex",
              flexWrap: "wrap",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              marginTop: "5%",
            }}
          >
            {/* left box */}
            <Box
              className="fourth-left-box"
              sx={{
                width: "50%",
                height: "auto",
              }}
            >
              <Typography
                className="fourth-left-heading"
                sx={{
                  width: "100%",
                  height: "auto",
                  color: "#000000",
                  fontFamily: "DM Sans",
                  fontWeight: 700,
                  fontSize: "24px",
                  lineHeight: "41px",
                }}
              >
                Why Choose PWR Steer Reservoirs?
              </Typography>

              <Typography
                className="fourth-left-content"
                sx={{
                  width: "100%",
                  height: "auto",
                  fontFamily: "DM Sans",
                  fontWeight: 400,
                  fontSize: "14px",
                  lineHeight: "25px",
                  color: "#0B0F11",
                  marginTop: "10px",
                }}
              >
                At PWR Steer, we specialize in designing and manufacturing power
                steering pumps that address the challenges technicians face with
                remanufactured alternatives. Built from 100% new materials, our
                pumps are engineered for peak performance, seamless
                installation, and long-lasting durability.
              </Typography>

              <Typography
                className="fourth-question"
                sx={{
                  width: "100%",
                  height: "auto",
                  fontFamily: "DM Sans",
                  fontWeight: 700,
                  fontSize: "16px",
                  lineHeight: "22px",
                  color: "#000000",
                  marginTop: "40px",
                }}
              >
                What Sets Us Apart?
              </Typography>
              <ol>
                <li className="fouth-list-item">
                  <Typography
                    className="list-item"
                    sx={{
                      // width: "561px",
                      width: "100%",

                      height: "auto",
                      fontFamily: "DM Sans",
                      fontWeight: 600,
                      fontSize: "14px",
                      lineHeight: "22px",
                      color: "#000000",
                    }}
                  >
                    Decades of Collective Automotive Expertise
                    <br />
                    <span
                      className="list-item-content"
                      style={{
                        fontFamily: "DM Sans",
                        fontWeight: 400,
                        fontSize: "14px",
                        lineHeight: "22px",
                        color: "#000000",
                      }}
                    >
                      Our engineering and manufacturing teams bring extensive
                      experience across the automotive industry, specializing in
                      power steering systems, product development, and quality
                      assurance.
                    </span>
                  </Typography>
                </li>
                <li className="fouth-list-item">
                  <Typography
                    className="list-item"
                    sx={{
                      // width: "561px",
                      width: "100%",

                      marginTop: "10px",
                      fontFamily: "DM Sans",
                      fontWeight: 600,
                      fontSize: "14px",
                      lineHeight: "22px",
                      color: "#000000",
                    }}
                  >
                    Advanced Quality Standards
                    <br />
                    <span
                      className="list-item-content"
                      style={{
                        fontFamily: "DM Sans",
                        fontWeight: 400,
                        fontSize: "14px",
                        lineHeight: "22px",
                        color: "#000000",
                      }}
                    >
                      ISO/TS 16949-compliant processes ensure that every product
                      meets or exceeds industry standards for durability,
                      performance, and safety.
                    </span>
                  </Typography>
                </li>

                <li className="fouth-list-item">
                  <Typography
                    className="list-item"
                    sx={{
                      // width: "561px",
                      width: "100%",

                      marginTop: "10px",
                      fontFamily: "DM Sans",
                      fontWeight: 600,
                      fontSize: "14px",
                      lineHeight: "22px",
                      color: "#000000",
                    }}
                  >
                    Design Innovation
                    <br />
                    <span
                      className="list-item-content"
                      style={{
                        fontFamily: "DM Sans",
                        fontWeight: 400,
                        fontSize: "14px",
                        lineHeight: "22px",
                        color: "#000000",
                      }}
                    >
                      Each product is engineered to address common OE design
                      flaws, delivering improved durability and long-lasting
                      performance.
                    </span>
                  </Typography>
                </li>
              </ol>
              <Typography
                className="list-item-content1"
                sx={{
                  // width: "591px",
                  width: "100%",

                  height: "auto",
                  fontFamily: "DM Sans",
                  fontWeight: 400,
                  fontSize: "14px",
                  lineHeight: "25px",
                  color: "#000000",
                }}
              >
                By choosing PWR Power Steering Pumps, you get a product that’s
                ready for installation and guaranteed to perform, giving both
                technicians and drivers peace of mind.
              </Typography>
            </Box>
            {/* right box */}
            <Box
              className="fourth-right-box"
              sx={{ width: "50%", display: "flex", justifyContent: "center" }}
            >
              <Box
                className="fourth-right-box-main"
                sx={{
                  borderRadius: "19px",
                  backgroundColor: "#F0F3F3",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  padding: "30px",
                  width: "80%",
                }}
              >
                <Box
                  className="fourth-right-box-sub"
                  sx={{
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <img
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "contain",
                      display: "block",
                      borderRadius: "12px",
                    }}
                    className="fourth-right-image"
                    src={MaskGroup}
                  />
                </Box>
              </Box>
            </Box>
          </Box>

          {/* 5th row */}
          <Box
            className="fifth-box-main"
            sx={{
              width: "95%",
              height: "auto",
              flexWrap: "wrap",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              // gap: 5,
              marginTop: "5%",
              // padding:"30px"
            }}
          >
            {/* right box */}
            <Box className="fifth-image-right-box" sx={{ width: "50%" }}>
  <Box
    className="fourth-right-box-main"
    sx={{
      borderRadius: "19px",
      backgroundColor: "#F0F3F3",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      width: "80%",
      padding: "30px",
    }}
  >
    <Box
      className="fourth-right-box-sub"
      sx={{
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        overflow: "hidden",
      }}
    >
      <img
        className="fourth-right-image"
        id="reservoir-fourth-right-image"
        style={{
          width: "auto", 
          height: "373px",
         maxWidth: "100%", 
         objectFit: 'fill',
         borderRadius: "12px",
          display: "block",
        }}
        src={rack2}
        alt="Reservoirs"
      />
    </Box>
  </Box>
</Box>


            {/* left box */}
            <Box
              className="fourth-left-box"
              sx={{
                width: "50%",
                height: "auto",
              }}
            >
              <Typography
                className="fourth-left-heading"
                sx={{
                  width: "100%",
                  // width: "553px",
                  // height: "auto",
                  color: "#000000",
                  fontFamily: "DM Sans",
                  fontWeight: 700,
                  fontSize: "20px",
                  lineHeight: "22px",
                  // marginBottom: "30px",
                }}
              >
                New Product Development Quality Control
              </Typography>

              <ol>
                <li className="fouth-list-item">
                  <Typography
                    className="list-item-content"
                    style={{
                      fontFamily: "DM Sans",
                      fontWeight: 500,
                      fontSize: "14px",
                      lineHeight: "22px",
                      color: "#000000",
                      
                    }}
                  >
                    <strong>Built-In Quality from the Start:</strong> Every
                    product is developed using our Advanced Product Quality
                    Planning (APQP) process, ensuring a flawless launch.
                  </Typography>
                </li>
                <li>
                  <Typography
                    className="list-item-content"
                    style={{
                      fontFamily: "DM Sans",
                      fontWeight: 500,
                      fontSize: "14px",
                      lineHeight: "22px",
                      color: "#000000",
                      marginTop: "20px",
                    }}
                  >
                    <strong>Collaborative Engineering: </strong>Our teams work
                    directly with manufacturers to develop products that meet
                    OE-level standards for fit, form, and function.
                  </Typography>
                </li>

                <li>
                  <Typography
                    className="list-item-content"
                    style={{
                      fontFamily: "DM Sans",
                      fontWeight: 500,
                      fontSize: "14px",
                      lineHeight: "22px",
                      color: "#000000",
                      marginTop: "20px",
                    }}
                  >
                    <strong>Comprehensive Validation:</strong> Each component
                    undergoes extensive validation and testing for real-world
                    performance and reliability.
                  </Typography>
                </li>
                <li>
                  <Typography
                    className="list-item-content"
                    style={{
                      fontFamily: "DM Sans",
                      fontWeight: 500,
                      fontSize: "14px",
                      lineHeight: "22px",
                      color: "#000000",
                      marginTop: "20px",
                    }}
                  >
                    <strong> Customer-First Approach: </strong>Designed with
                    technicians and end-users in mind, PWR products combine ease
                    of installation, durability, and exceptional value, backed
                    by industry-leading warranties.
                  </Typography>
                </li>
              </ol>
            </Box>
          </Box>
          {/* 6th row */}
          <Box
            className="sixth-box-main"
            sx={{
              width: "100%",
              boxSizing: "border-box",
              marginTop: "60px",
            }}
          >
            <Video />
          </Box>
        </Box>
      </Box>
      <CommonFooter />
    </LayoutComponent>
  );
};
