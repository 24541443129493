import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Box, Button, Typography, CircularProgress } from "@mui/material";
import routes from "../../../AppRoutes/routes.json";
import CommonFooter from "../../Footer/CommonFooter";
import LayoutComponent from "../../../Layout/LayoutComponent";
import { ProductSpecification } from "./ProductSpecification";
import { Application } from "./Application";
import { PackageProductBulletin } from "./PackageProductBulletin";
import { CompetitorInterchange } from "./CompetitorInterchange";
import { getProductAppData } from "./GearBoxAction";
import { useSelector } from "react-redux";

export const GearBox = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const {
    specification,
    selectedProduct,
    selectedPart,
    selectYear,
    selectMake,
    selectModel,
    selectEngine,
  } = useSelector((state) => state.CommonStore);

  function capitalizeWords(str) {
    return str
      .split(" ")
      .map((word) => {
        return word.charAt(0).toUpperCase() + word.slice(1);
      })
      .join(" ");
  }

  const getProductName = () => {
    if (selectedProduct.type === "Part Numbers") {
      console.log("test1");
      return `${selectedProduct.label}
      `;
    } else if (selectedProduct.type === "Applications") {
      console.log("test2");
      return `${selectedProduct.label} `;
    } else if (selectedProduct.type === "VIN") {
      return `${selectedPart.partNumber}`;
    } else {
      console.log("test3");
      if (selectedPart.hasOwnProperty("ymm")) {
        console.log("test3 if");

        console.log("... selected year: ", selectedPart.newparttypename);
        return `${selectedPart.partNumber}`;

        // return `${selectedPart.partNumber} -  ${selectedPart.ymm.replace(
        //   /-/g,
        //   " "
        // )}  ${selectedPart.newparttypename.replace(
        //   /-/g,
        //   " "
        // )}`;
      } else {
        console.log("test3 else");

        console.log(
          "... ",
          `${selectYear} ${selectMake.label} ${selectModel.label} ${selectEngine.label}`
        );
        return `${selectYear} ${selectMake.label} ${selectModel.label} ${selectEngine.label}`;
      }
    }
  };

   useEffect(()=>{
        if (!sessionStorage.getItem("hasRefreshed")) {
          sessionStorage.setItem("hasRefreshed", "true"); // Mark as refreshed
          window.location.reload();
        }
      },[])
  return (
    <LayoutComponent>
      <Box
        className="main_product_container"
        sx={{
          width: "100%",
          padding: "100px",
          paddingTop: "50px",
          paddingBottom:'0',
          boxSizing: "border-box",
          height:'auto'
        }}
      >
       

        {/* Main Content */}
        {loading ? (
          // Show CircularProgress loader while loading
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
            }}
          >
            <CircularProgress sx={{ color: "#EC1B24" }} />
          </Box>
        ) : (
          <Box className="product-content-main-div"
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 3,
              width: "100%",
            }}
          >
             {/* nav bar code starts here */}
        <Box
          className="backlink_box"
          sx={{
            width: "100%",
            // height: "10px",
            marginTop: "34px",
            border: "1px",
            // marginBottom: "20px",
          }}
        >
          {/* navigate(-1 */}
          <Typography
            // onClick={() => navigate(routes.Ecatlog)}
            
            onClick={() =>{sessionStorage.clear(); navigate(-1)}}
            sx={{
              // width: "50%",
              fontFamily: "DM Sans",
              textAlign: "left",
              fontWeight: 400,
              lineHeight: "27px",
              // textAlign: "center",
              color: "#565656",
              textDecoration: "none",
              cursor: "pointer",
            }}
            tabIndex={0}
            onKeyDown={(e) => {
              if (e.key === "Enter" || e.key === " ") {
                sessionStorage.clear()
                navigate(-1);
              }
            }}
          >
            {"< BACK TO SEARCH RESULT FOR:"} {getProductName()}
          </Typography>
        </Box>
        {/* nav bar code ends here */}
            {/*  First Row: 3 divs */}
            <ProductSpecification />

            {/* Second Row: Single Table */}

            <Application />
            {/* Third Row */}
            <Box
              className="product_bulletin"
              sx={{
                display: "flex",
                flexDirection: "row",
                gap: "20px",
                width: "100%",
              }}
            >
              {/* First Column */}
              <CompetitorInterchange />

              {/* Second Column */}
              <PackageProductBulletin />
            </Box>
          </Box>
        )}
      </Box>

      <CommonFooter />
    </LayoutComponent>
  );
};
