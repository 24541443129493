import React, { useEffect } from "react";
import LayoutComponent from "../../Layout/LayoutComponent";
import CommonFooter from "./CommonFooter";
import {
  Box,
  Typography,
  List,
  ListItem,
  ListItemText,
  Link,
} from "@mui/material";
import routes from "../../AppRoutes/routes.json";
import { useNavigate, useLocation } from "react-router-dom";
import { CommonSetupActions } from "../../Slices/Commonslice";
import { useDispatch } from "react-redux";

export const Accessibility = ({ scrollToGetInTouch }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

     useEffect(()=>{
          if (!sessionStorage.getItem("hasRefreshed")) {
            sessionStorage.setItem("hasRefreshed", "true"); 
            window.location.reload();
          }
        },[])

  const features = [
    {
      label: "Text Alternatives",
      description:
        "Meaningful images include descriptive alternative text for screen readers.",
    },
    {
      label: "Resizable Text",
      description:
        "Text can be resized up to 200% without loss of functionality or clarity.",
    },
    {
      label: "Keyboard Navigation",
      description:
        "All functionality can be accessed using a keyboard, supporting users who cannot use a mouse.",
    },
    {
      label: "Consistent Navigation",
      description:
        "Pages use clear and predictable navigation patterns for ease of use.",
    },
    {
      label: "Error Identification",
      description:
        "Forms provide clear feedback on errors, helping users understand and correct mistakes.",
    },
    {
      label: "Contrast and Visibility",
      description:
        "Text maintains a contrast ratio of at least 4.5:1 against its background to ensure readability.",
    },
    {
      label: "Target Size",
      description:
        "Interactive elements have a minimum target size of 24x24 pixels for ease of use.",
    },
    {
      label: "Drag Alternatives",
      description:
        "Features that require dragging can also be operated via alternative actions like clicks or taps.",
    },
  ];
  return (
    <LayoutComponent>
      <Box
        sx={{
          width: "100%",
          height: "auto",
          boxSizing: "border-box",
          display: "flex",
          flexDirection: "column",
          fontFamily: "DM Sans",
        }}
      >
        <Box
          className="main-war-state-box"
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            boxSizing: "border-box",
            margin: "70px",
            flexWrap: "wrap",
          }}
        >
          {/* Accessibility heading */}
          <Box
            className="warr-head-box"
            sx={{
              
              marginTop: "5%",
            }}
          >
            <Typography
              className="head-typo-access"
              sx={{
                fontFamily: "DM Sans",
                fontSize: "60px",
                fontWeight: 800,
                lineHeight: "27px",
                color: "#000000",
              }}
            >
              Accessibility Statement
            </Typography>
            <hr
              className="war-hr-line"
              style={{
                width: "100%",
                marginTop: "46px",
              }}
            />
          </Box>

          <Box sx={{ width: "100%", marginTop: 2 }}>
            <Box>
              <Typography
                className="state-head-typo"
                sx={{
                  width: "100%",
                  height: "auto",
                  fontFamily: "DM Sans",
                  fontWeight: 600,
                  fontSize: "25px",
                  lineHeight: "27px",
                  color: "#55120B",
                  padding: "10px 0px",
                }}
              >
                Commitment to Accessibility
              </Typography>
              <Box>
                <Typography
                  className="content-first-second-row-typo"
                  sx={{
                    fontFamily: "DM Sans",
                    fontWeight: 400,
                    fontSize: "18px",

                    lineHeight: "30px",
                    color: "#000000",
                  }}
                >
                  At PWR Steer, we are committed to making our website
                  accessible to all individuals, including those with
                  disabilities. We aim to ensure compliance with the latest Web
                  Content Accessibility Guidelines (WCAG) 2.2 at the AA level,
                  providing an inclusive online experience for all users.
                </Typography>
                <br />
                <Typography
                  className="content-first-second-row-typo"
                  sx={{
                    fontFamily: "DM Sans",
                    fontWeight: 400,
                    fontSize: "18px",

                    lineHeight: "30px",
                    color: "#000000",
                  }}
                >
                  In addition, we adhere to the Americans with Disabilities Act
                  (ADA) requirements to ensure that all visitors, regardless of
                  ability, have equal access to our website and services.
                </Typography>
              </Box>
            </Box>
            <hr />
            <Box>
              <Typography
                className="state-head-typo"
                sx={{
                  width: "100%",
                  height: "auto",
                  fontFamily: "DM Sans",
                  fontWeight: 600,
                  fontSize: "25px",
                  lineHeight: "27px",
                  color: "#55120B",
                  padding: "10px 0px",
                }}
              >
                Accessibility Features
              </Typography>

              <List component="ol" sx={{ listStyleType: "decimal", pl: 4 }}>
                {features.map((feature, index) => (
                  <ListItem key={index} sx={{ display: "list-item" }}>
                    <ListItemText
                    
                    ><Typography className="list-label-item"
                    sx={{
                      fontWeight: "bold",
                      fontFamily: "DM Sans",
                      fontSize: "18px",
                    }}
                  >
                    {feature.label}:
                  <Typography className="list-label-item"
                    sx={{
                      fontFamily: "DM Sans",
                      fontSize: "18px",
                      fontWeight: 400,
                      lineHeight: "30px",
                      paddingLeft: "5px",
                    }}
                  >
                    {feature.description}
                  </Typography>
                  </Typography></ListItemText>
                  </ListItem>
                ))}
              </List>
            </Box>
            <hr />
            <Box>
              <Typography
                className="state-head-typo"
                sx={{
                  width: "100%",
                  height: "auto",
                  fontFamily: "DM Sans",
                  fontWeight: 600,
                  fontSize: "25px",
                  lineHeight: "27px",
                  color: "#55120B",
                  padding: "10px 0px",
                }}
              >
                Feedback and Support
              </Typography>
              <Typography
                className="content-first-second-row-typo"
                sx={{
                  fontFamily: "DM Sans",
                  fontWeight: 400,
                  fontSize: "18px",

                  lineHeight: "30px",
                  color: "#000000",
                }}
              >
                If you encounter any issues accessing content on our website or
                need assistance, please let us know. We are continually
                improving our accessibility efforts and value your input.
              </Typography>
              <Box>
                <Typography
                  className="state-head-typo"
                  sx={{
                    width: "100%",
                    height: "auto",
                    fontFamily: "DM Sans",
                    fontWeight: 600,
                    fontSize: "20px",
                    lineHeight: "27px",
                    color: "#55120B",
                    padding: "10px 0px",
                  }}
                >
                  Contact Us:
                </Typography>
                <List component="ul" sx={{ listStyleType: "disc", pl: 4 }}>
                  <ListItem sx={{ display: "list-item", pl: 0 }}>
                    <ListItemText
                      primary={
                        <Typography
                         className="list-label-item"
                          sx={{
                            fontWeight: 400,
                            fontFamily: "DM Sans",
                            fontSize: "18px",

                          }}
                        >
                          Email: <span className="list-label-item" style={{fontFamily:'DM Sans',fontSize:'18px',paddingLeft:'5px',fontWeight:400}}> <Link className="list-label-item"
                            href="mailto:customersupport@premiumguard.com"
                            underline="hover"
                            sx={{
                              fontFamily: "DM Sans",
                              fontSize: "18px",
                            }}
                          >
                            customersupport@premiumguard.com
                          </Link></span>
                        </Typography>
                      }   />
                  </ListItem>
                  <ListItem sx={{ display: "list-item", pl: 0 }}>
                    <ListItemText 
                      primary={
                        <Typography className="list-label-item"
                          component="span"
                          variant="body1"
                          sx={{
                            fontWeight: 400,
                            fontFamily: "DM Sans",
                            fontSize: "18px",
                          }}
                        >
                          Phone:
                        </Typography>
                      }
                      secondary={
                        <Typography className="list-label-item"
                          component="span"
                          variant="body2"
                          sx={{
                            fontFamily: "DM Sans",
                            fontSize: "18px",
                            paddingLeft: "5px",
                            fontWeight: "bold",
                          }}
                        >
                          (866) 990-5120
                        </Typography>
                      }
                    />
                  </ListItem>
                  <ListItem sx={{ display: "list-item", pl: 0 }}>
                    <ListItemText
                      primary={
                        <Typography className="list-label-item"
                          component="span"
                          variant="body1"
                          sx={{
                            fontWeight: 400,
                            fontFamily: "DM Sans",
                            fontSize: "18px",
                          }}
                        >
                          Accessibility Feedback Form:
                        </Typography>
                      }
                      secondary={
                        <Typography className="list-label-item"
                          component="span"
                          variant="body2"
                          sx={{
                            fontFamily: "DM Sans",
                            fontSize: "18px",
                            paddingLeft: "5px",
                            fontWeight: 400,
                            cursor: "pointer",
                          }}
                        >
                          <Link className="list-label-item"
                          href="https://chatgpt.com/g/g-XFUHACcJF-pwr-steer-product-manager"
                            underline="always"
                            sx={{
                              fontFamily: "DM Sans",
                              fontSize: "18px",
                            }}
                          >
                            Submit Feedback Here
                          </Link>
                        </Typography>
                      }
                    />
                  </ListItem>
                </List>
              </Box>
            </Box>
            <hr />
            <Box>
              <Typography
                className="state-head-typo"
                sx={{
                  width: "100%",
                  height: "auto",
                  fontFamily: "DM Sans",
                  fontWeight: 600,
                  fontSize: "25px",
                  lineHeight: "27px",
                  color: "#55120B",
                  padding: "10px 0px",
                }}
              >
                Ongoing Commitment
              </Typography>
            </Box>
            <Box>
              <Typography
                className="content-first-second-row-typo"
                sx={{
                  fontFamily: "DM Sans",
                  fontWeight: 400,
                  fontSize: "18px",

                  lineHeight: "30px",
                  color: "#000000",
                }}
              >
                PWR Steer is committed to regular accessibility reviews and
                updates to maintain compliance with WCAG standards and ADA
                requirements. Accessibility is a vital part of our mission to
                serve all users effectively.
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
      <CommonFooter />
    </LayoutComponent>
  );
};
