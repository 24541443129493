import {
  Autocomplete,
  Box,
  Button,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
  OutlinedInput,
  Chip,
} from "@mui/material";
import React, { useEffect, useState, useRef, useCallback } from "react";
import LayoutComponent from "../../Layout/LayoutComponent";
import backgroundimg from "../../Assets/BackgroudImages/Ecatlogbackground.png";
import AutoComplete from "../CommonComponent/AutoComplete";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import CommonFooter from "../Footer/CommonFooter";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { PartNoModal } from "../Home/PartNoModal";
import { getProductList } from "../CommonComponent/AutocompleteAction";
import { getNewRealses, showYearList } from "./ClassicSearchAction";
import { CommonSetupActions } from "../../Slices/Commonslice";
import { getProductAppData } from "./ProductDisplay/GearBoxAction";
import {
  showMakeList,
  showModelList,
  showEngineList,
  showDriveTypes,
} from "./ClassicSearchAction";
import "../ProductPages/Ecatlog.css";
import { persistor } from "../../Store/store";

import CommonLoader from "../CommonComponent/CommonLoader";
import noimage from "../../Assets/noimage.png";
import { ApiLink } from "../Utils/Apilink";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const Ecatlog = () => {
  const isLoading = useSelector((state) => state.isLoading);

  //states to set the selected year, make, model
  const [selectedYear, setSelectedYear] = useState(null);
  const [selectedMake, setSelectedMake] = useState(null);
  const [selectedModel, setSelectedModel] = useState(null);
  const [selectedEngine, setSelectedEngine] = useState(null);
  const [submodelVal, setSubmodelVal] = useState();
  const [driverType, setDriverType] = useState(null);
  const [selectedSubModal, setSelectedSubModal] = useState(null);

  // states to open dropdowns automatically
  const [isMakeDropdownOpen, setMakeDropdownOpen] = useState(false);
  const [isModelDropdownOpen, setModelDropdownOpen] = useState(false);
  const [isEngineDropdownOpen, setEngineDropdownOpen] = useState(false);
  const [isDriverDropdownOpen, setDriverDropdownOpen] = useState(false);
  const [isSubmodalOpen, setSubmodalOpen] = useState(false);
  const [openModal, setOpenModal] = useState(false);

  const { state } = useLocation();
  const dispatch = useDispatch();
  const productContainerRef = useRef(null);

  const scrollToProductList = () => {
    if (productContainerRef.current) {
      productContainerRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const makeSelectRef = useRef(null);

  const {
    quallifierCollection,
    subModel,
    productList,
    selectedProduct,
    radioValue,
    selectedPart,
    productlistmessage,
  } = useSelector((state) => state.CommonStore);
  const currentSubModelVal = useSelector(
    (state) => state.CommonStore.selectSubModelVal
  );
  console.log("sele val:::::::::::::: ", selectedProduct);
  const {
    classicYearList,
    classicMakeList,
    classicModelList,
    classicEngineList,
    selectYear,
    selectMake,
    selectModel,
    selectEngine,
  } = useSelector((state) => state.CommonStore);
  useEffect(()=>{
       if (!sessionStorage.getItem("hasRefreshed")) {
         sessionStorage.setItem("hasRefreshed", "true");
         window.location.reload();
       }
     },[])
  useEffect(() => {
    dispatch(showYearList());
  }, []);

  const handleClosee = () => {
    setOpenModal(false);
  };
  const handleOpen = () => {
    setOpenModal(true);
  };

  useEffect(() => {
    if (productList && productList.length > 0) {
      scrollToProductList();
    }
  }, [productList]);

  useEffect(() => {
    const handleBeforeUnload = () => {
      dispatch(CommonSetupActions.resetState());
      persistor.purge();
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  console.log("delectedYeer#");

  const isDisabled = (selectNo) => {
    if (selectNo === 1) return false;
    if (selectNo === 2) return !selectYear;
    if (selectNo === 3) return !selectMake;
    if (selectNo === 4) return !selectModel;
    if (selectNo === 5) return !selectEngine;
    if (selectNo === 6) {
      return (
        !driverType ||
        !subModel ||
        !subModel.values ||
        subModel.values.length === 0
      );
    }
    if (selectNo === 7) {
      return !subModel || !subModel.values || subModel.values.length === 0;
    }
    return true;
  };

  //set selected year
  const handleYearChange = (e, value) => {
    console.log(value);

    dispatch(CommonSetupActions.setClassicMakeList([]));
    dispatch(CommonSetupActions.setClassicModelList([]));
    dispatch(CommonSetupActions.setClassicEngineList([]));
    dispatch(CommonSetupActions.setCurrentSelectedEngine(""));
    dispatch(CommonSetupActions.setCurrentSelectedMake(""));
    dispatch(CommonSetupActions.setCurrentSelectedModel(""));
    setSelectedYear(value);
    dispatch(CommonSetupActions.setProductList());
    dispatch(CommonSetupActions.setSelectedPart({}));
    dispatch(CommonSetupActions.setQuallifierCollection({}));
    dispatch(CommonSetupActions.setSubModel([]));
    dispatch(CommonSetupActions.setSelectedProduct({}));
    setSelectedMake(null);
    setSelectedModel(null);
    setSelectedEngine(null);
    setDriverType(null);
    setSelectedSubModal(null);
    setValue2(null);
    setSubmodelVal(null);
    dispatch(CommonSetupActions.setCurrentSelectedYear(value));
    setMakeDropdownOpen(true);
    dispatch(showMakeList());
  };

  //set selected make
  const handleMakeChange = (e, value) => {
    console.log(value);
    dispatch(CommonSetupActions.setProductList());
    dispatch(CommonSetupActions.setSelectedPart({}));
    dispatch(CommonSetupActions.setQuallifierCollection({}));
    dispatch(CommonSetupActions.setClassicModelList([]));
    dispatch(CommonSetupActions.setClassicEngineList([]));
    dispatch(CommonSetupActions.setCurrentSelectedModel(""));
    dispatch(CommonSetupActions.setCurrentSelectedEngine(""));
    dispatch(CommonSetupActions.setProductList());
    dispatch(CommonSetupActions.setSelectedPart({}));
    dispatch(CommonSetupActions.setQuallifierCollection({}));
    dispatch(CommonSetupActions.setSubModel([]));

    setSelectedMake(value);
    setSelectedModel(null);
    setSelectedEngine(null);
    setDriverType(null);
    setSelectedSubModal(null);
    setValue2(null);
    setSubmodelVal(null);
    dispatch(CommonSetupActions.setCurrentSelectedMake(value));
    setModelDropdownOpen(true);
    dispatch(showModelList());
  };
  //set selected model
  const handleModelChange = (e, value) => {
    console.log(value);
    dispatch(CommonSetupActions.setProductList());
    dispatch(CommonSetupActions.setSelectedPart({}));
    dispatch(CommonSetupActions.setQuallifierCollection({}));
    dispatch(CommonSetupActions.setClassicEngineList([]));
    dispatch(CommonSetupActions.setCurrentSelectedEngine(""));

    setSelectedModel(value);
    setSelectedEngine(null);
    setDriverType(null);
    setSelectedSubModal(null);
    setValue2(null);
    setSubmodelVal(null);
    dispatch(CommonSetupActions.setCurrentSelectedModel(value));
    setEngineDropdownOpen(true);
    dispatch(showEngineList());
  };
  //set selected engine
  const handleEngineChange = (e, value) => {
    console.log(value);
    dispatch(CommonSetupActions.setProductList());
    dispatch(CommonSetupActions.setSelectedPart({}));
    dispatch(CommonSetupActions.setQuallifierCollection({}));
    dispatch(CommonSetupActions.setSubModel({}));
    setSelectedEngine(value);
    setDriverType(null);
    setSelectedSubModal(null);
    setValue2(null);
    setSubmodelVal(null);
    setDriverDropdownOpen(true);
    dispatch(CommonSetupActions.setCurrentSelectedEngine(value));
    dispatch(showDriveTypes(navigate, scrollToProductList, setOpenModal));
  };

  const [value, setValue] = useState();
  const [value2, setValue2] = useState();
  console.log("location", state);
  const handleClick = () => {
    setOpen(true);
  };

  const navigate = useNavigate();

  const handlesubModelChange = (e) => {
    setSubmodelVal(e.target.value);

    setSelectedSubModal(e.target.value);
    dispatch(
      CommonSetupActions.setCurrentSelectedSubModelValue(e.target.value)
    );
    console.log("qualli", quallifierCollection);
    const a = quallifierCollection.values[0];

    let b = subModel.values.find((data, ind) => data.id === e.target.value);

    let qualifierId = `${a.id},${e.target.value}`;
    let quallifiername = `${a.values},${b.values}`;
    dispatch(
      getProductList(
        ApiLink,
        state.baseVehicleID,
        state.engineID,
        qualifierId,
        quallifiername,
        scrollToProductList,
        setOpenModal
      )
    );
  };

  const handleChange = (e) => {
    console.log("event", e);
    let a = quallifierCollection.values.find(
      (data, ind) => data.id === e.target.value
    );
    setValue(e.target.value);
    const data = e.target.value;
    console.log("data: ", data);
    setValue2(data);
    setDriverType(data);
    setSelectedSubModal(null);
    setSubmodalOpen(true);
    dispatch(
      getProductList(
        ApiLink,
        state.baseVehicleID,
        state.engineID,
        e.target.value,
        a.values,
        scrollToProductList,
        setOpenModal
      )
    );
  };

  const handleOnChange = (e) => {
    dispatch(CommonSetupActions.setRadioValue(e.target.value));

    dispatch(getNewRealses(e.target.value, setOpenModal, scrollToProductList));
  };

  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };


  const handleoninputYearchange = (event, newInputValue) => {
    if (newInputValue === "") {
      dispatch(CommonSetupActions.setCurrentSelectedYear(""));
    }
  };

  const handleonInputMakechange = (event, newInputValue) => {
    if (newInputValue === "") {
      dispatch(CommonSetupActions.setCurrentSelectedMake(""));
    }
  };

  const handleonInputModelchange = (event, newInputValue) => {
    if (newInputValue === "") {
      dispatch(CommonSetupActions.setCurrentSelectedModel(""));
    }
  };
  const handleonInputEnginechange = (event, newInputValue) => {
    if (newInputValue === "") {
      dispatch(CommonSetupActions.setCurrentSelectedEngine(""));
    }
  };

  return (
    <LayoutComponent>
      {/* {isLoading ? <Loader /> :  */}
      <Box className="mainContainer">
        <Box className="subContainer">
          <img
            className="mainBgImage"
            src={backgroundimg}
            alt="e-catalog background image"
            width="100%"
            height="100%"
          />
          {/* heading section */}
          <Box
            className="mainHead"
          >
            <Typography className="eCatelogHead">E-Catalog</Typography>
            <Typography className="newPowerHead">
              New Power Steering Products
            </Typography>
          </Box>

          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "row",
              flexWrap: "wrap",
            }}
          >
            <Box className="newReleasesSub">
              <Typography
                sx={{ fontWeight: 600, fontSize: "24px", color: "#55120B" }}
              >
                New Releases
              </Typography>
              <FormControl>
                <RadioGroup
                  value={radioValue}
                  onChange={handleOnChange}
                 
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    gap: 2,
                    width: "100%",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                  aria-labelledby="demo-radio-buttons-group-label"
                  defaultValue="female"
                  name="radio-buttons-group"
                >
                  <FormControlLabel
                    className="power-steer-label"
                    value={1}
                    control={
                      <Radio
                        sx={{
                          color: "#EC1B24",
                          "&.Mui-checked": {
                            color: "#EC1B24",
                          },
                        }}
                        
                      />
                    }
                    label="Power Steering Pump"
                    sx={{
                      "& .MuiFormControlLabel-label": {
                        color: "#55120B",
                        fontWeight: 400,
                        fontSize: "20px",
                        fontFamily: "DM Sans",
                      },
                    }}

                    tabIndex={0}
                        onKeyDown={(e) => {
                          if (e.key === "Enter" || e.key === " ") {
                            handleOnChange(e);
                          }
                        }}
                  />
                  <FormControlLabel
                    className="power-steer-label"
                    value={2}
                    control={
                      <Radio
                        sx={{
                          color: "#EC1B24",
                          "&.Mui-checked": {
                            color: "#EC1B24",
                          },
                        }}
                      />
                    }
                    label="Power Steering Rack"
                    sx={{
                      "& .MuiFormControlLabel-label": {
                        color: "#55120B",
                        fontWeight: 400,
                        fontSize: "20px",
                        fontFamily: "DM Sans",
                      },
                    }}
                    tabIndex={0}
                    onKeyDown={(e) => {
                      if (e.key === "Enter" || e.key === " ") {
                        handleOnChange(e);
                      }
                    }}
                  />
                  <FormControlLabel
                    className="power-steer-label"
                    value={3}
                    control={
                      <Radio
                        sx={{
                          color: "#EC1B24",
                          "&.Mui-checked": {
                            color: "#EC1B24",
                          },
                        }}
                      />
                    }
                    label="Steering Gear Box"
                    sx={{
                      "& .MuiFormControlLabel-label": {
                        color: "#55120B",
                        fontWeight: 400,
                        fontSize: "20px",
                        fontFamily: "DM Sans",
                      },
                    }}
                    tabIndex={0}
                    onKeyDown={(e) => {
                      if (e.key === "Enter" || e.key === " ") {
                        handleOnChange(e);
                      }
                    }}
                  />
                </RadioGroup>
              </FormControl>
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            flexDirection: "column",
          }}
        >
          <Box
            className="steer_search"
            sx={{
              width: "85%",
              backgroundColor: "#000000",
              marginTop: "80px",
              padding: 2,
              borderRadius: "10px",
              alignItems: "center",
              display: "flex",
              flexDirection: "column",
              gap: 2,
            }}
          >
            <Typography
              sx={{ color: "#FFFFFF", fontWeight: 600, fontSize: "24px" }}
            >
              PWR Steer Search
            </Typography>

            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <AutoComplete
                onSearchComplete={scrollToProductList}
                setOpenModal={setOpenModal}
                sx={{ width: "90%" }} 
              />
            </Box>

            {Object.keys(selectedProduct).length > 0 &&
            quallifierCollection &&
            quallifierCollection.hasOwnProperty("name") &&
            quallifierCollection.values.length > 1 ? (
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <FormControl
                  className="Default-drive-type"
                  sx={{
                    m: 1,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "71%",
                    borderRadius: "10px",
                  }}
                >
                  <InputLabel
                    id="demo-select-small-label"
                    className="drive_label"
                    sx={{
                      color: "#000000",
                      display: value ? "none" : "flex",

                      position: "absolute", 
                      top: "50%", 
                      left: "25%", 
                      transform: "translate(-50%, -50%)", 
                      "&.Mui-focused": {
                        display: "none",
                      },
                    }}
                  >
                    Select {quallifierCollection.name[0]}
                  </InputLabel>
                  <Select
                    labelId="demo-select-small-label"
                    id="demo-select-small"
                    value={value}
                    label="Age"
                    onChange={handleChange}
                    sx={{
                      width: "70%",
                      height: "47px",
                      backgroundColor: "white",
                      "& .MuiInputLabel-root": {
                        color: "#000000 !important",
                      },

                      borderRadius: "40px", 
                      "& .MuiOutlinedInput-root": {
                        borderRadius: "40px",
                      },
                      "& .MuiOutlinedInput-notchedOutline": {
                        borderRadius: "40px",
                      },
                    }}
                  >
                    {quallifierCollection.values.map((data, ind) => {
                      return (
                        <MenuItem
                          value={data.id}
                          name={data.values}
                          id={data.values}
                        >
                          {data.values}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Box>
            ) : (
              Object.keys(selectedProduct).length > 0 &&
              quallifierCollection &&
              quallifierCollection.hasOwnProperty("name") && (
                <Box
                  sx={{
                    width: "100%",
                    justifyContent: "center",
                    alignItems: "center",
                    display: "flex",
                  }}
                >
                  <FormControl
                    className="Default-drive-type"
                    sx={{
                      m: 1,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      width: "71%",
                      borderRadius: "10px",
                    }}
                  >
                    <Select
                      labelId="demo-select-small-label"
                      id="demo-select-small"
                      value={quallifierCollection.values[0].id}
                      disabled
                      defaultValue={quallifierCollection.values[0].id}
                      sx={{
                        width: "70%",
                        height: "47px",
                        backgroundColor: "white",
                        "& .MuiInputLabel-root": {
                          color: "#000000 !important",
                        },
                        "& .MuiSelect-select": {
                          fontWeight: 500,
                          color: "#000000",
                          fontSize: { xs: "14px", sm: "16px", md: "16px" }, 
                        },
                        borderRadius: "40px", 
                        "& .MuiOutlinedInput-root": {
                          borderRadius: "40px", 
                        },
                        "& .MuiOutlinedInput-notchedOutline": {
                          borderRadius: "40px",
                        },
                      }}
                    >
                      <MenuItem value={quallifierCollection.values[0].id}>
                        {quallifierCollection.values[0].values}
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Box>
              )
            )}

            {Object.keys(selectedProduct).length > 0 &&
              subModel &&
              subModel.hasOwnProperty("name") && (
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginTop: "-10px",
                  }}
                >
                  <FormControl
                    className="Default-drive-type"
                    sx={{
                      m: 1,
                      width: "71%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      borderRadius: "10px",
                    }}
                  >
                    <InputLabel
                      id="demo-select-small-label"
                      className="subModel_label"
                      sx={{
                        color: "#000000",
                        display: currentSubModelVal ? "none" : "flex",
                        position: "absolute", 
                        top: "50%",
                        left: "25%", 
                        transform: "translate(-50%, -50%)", 

                        "&.Mui-focused": {
                          display: "none",
                        },
                      }}
                    >
                      Select {subModel.name[0]}
                    </InputLabel>
                    <Select
                      labelId="demo-select-small-label"
                      id="demo-select-small"
                      value={currentSubModelVal}
                      onChange={handlesubModelChange}
                      sx={{
                        width: "70%",
                        height: "47px",
                        backgroundColor: "white",
                        "& .MuiInputLabel-root": {
                          color: "#000000 !important",
                        },
                        borderRadius: "40px", 
                        "& .MuiOutlinedInput-root": {
                          borderRadius: "40px",
                        },
                        "& .MuiOutlinedInput-notchedOutline": {
                          borderRadius: "40px",
                        },
                      }}
                    >
                      {subModel.values.map((data, ind) => {
                        return (
                          <MenuItem value={data.id}>{data.values}</MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </Box>
              )}
            {/* </Box> */}
            <Box onClick={handleClick}>
              <Typography
                sx={{
                  color: "#EC1B24",
                  textDecoration: "underline",
                  cursor: "pointer",
                  fontWeight: 700,
                  fontSize: "16px",
                  fontFamily: "DM Sans",
                }}
                tabIndex={0}
                    onKeyDown={(e) => {
                      if (e.key === "Enter" || e.key === " ") {
                        handleClick();
                      }
                    }}
              >
                Part Numbering Highlight
              </Typography>
            </Box>
          </Box>
        </Box>

        <Box className="product-list-item-main"
          sx={{
            width: "100%",
            justifyContent: "center",
            display: "flex",
            alignItems: "center",
            marginTop: 6,
          }}
        >
          <Box
            className="productListMain"
          >
            <Box
              className="productListSub"
            >
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "flex-start",
                  alignItems: "flex-start",
                  gap: 2,
                }}
              >
                <Typography
                  sx={{
                    fontWeight: 600,
                    fontSize: "30px",
                    color: "#55120B",
                    fontFamily: "DM Sans",
                    textAlign: "left",
                  }}
                >
                  Still Can’t Find Your Product ?
                </Typography>
                <Typography
                  sx={{
                    width: "60%",
                    fontWeight: 600,
                    fontSize: "16px",
                    color: "#000000",
                    fontFamily: "DM Sans",
                  }}
                >
                  Try Our Classic Search Option
                </Typography>
                <Box
                  className="classic-search-sub-box"
                  sx={{
                    width: "90%",
                    backgroundColor: "#000000",
                    borderRadius: "10px",
                    boxShadow: "0px 12px 24px 0px #0000001A",
                    display: "flex",
                    flexDirection: "column",
                    gap: 2,
                    padding: 2,
                  }}
                >
                  <Typography
                    sx={{
                      fontWeight: 600,
                      fontSize: "16px",
                      color: "#ffffff",
                      fontFamily: "DM Sans",
                    }}
                  >
                    Classic Search
                  </Typography>
                  {/* First Autocomplete (Year) */}
                  <Autocomplete
                    className="classic-search-autocomplete"
                    options={classicYearList}
                    disableClearable
                    value={selectYear || ""}
                    onChange={handleYearChange}
                    onInputChange={handleoninputYearchange}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder="Year"
                        InputLabelProps={{
                          shrink: !!params.inputProps.value, 
                        }}
                        sx={{
                          backgroundColor: "#D9D9D9",
                          borderRadius: "5px",
                          "& .MuiFilledInput-root": {
                            display: "flex",
                            justifyContent: "center",
                            backgroundColor: "#D9D9D9",
                            width: "100%",
                            height: "100%",
                            borderRadius: "50px",
                            paddingRight: "4px!important",
                            "& input": {
                              marginBottom: 2,
                            },
                          },
                          "& .MuiInputLabel-outlined": {
                            display: !!params.inputProps.value
                              ? "none"
                              : "block",
                            color: "#000",
                            fontWeight: 100,
                            "&.Mui-focused": {
                              display: !!params.inputProps.value
                                ? "none"
                                : "block",
                              color: "red",
                            },
                          },
                          "& .MuiOutlinedInput-root": {
                            "& input::placeholder": {
                              opacity: 1, 
                              color: "#000", 
                            },
                            "&.Mui-disabled input::placeholder": {
                              opacity: 1,
                              color: "#000", 
                            },
                            "& .MuiOutlinedInput-notchedOutline": {
                              borderColor: "#ccc",
                            },
                            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                              borderColor: "#000",
                            },
                          },
                        }}
                      />
                    )}
                  />

                  {/* Second Autocomplete (Make) */}
                  <Autocomplete
                    className="classic-search-autocomplete"
                    ref={makeSelectRef}
                    options={classicMakeList}
                    disableClearable
                    disabled={isDisabled(2)}
                    value={selectMake || ""}
                    onInputChange={handleonInputMakechange}
                    onChange={handleMakeChange}
                    open={isMakeDropdownOpen && classicMakeList.length > 0}
                    onOpen={() => setMakeDropdownOpen(true)}
                    onClose={() => setMakeDropdownOpen(false)}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        // label="Make"
                        placeholder="Make"
                        sx={{
                          backgroundColor: "#D9D9D9",
                          borderRadius: "5px",
                          "& .MuiFilledInput-root": {
                            display: "flex",
                            justifyContent: "center",
                            backgroundColor: "#D9D9D9",
                            width: "100%",
                            height: "100%",
                            borderRadius: "50px",
                            paddingRight: "4px!important",
                            "& input": {
                              marginBottom: 2,
                            },
                          },
                          "& .MuiInputLabel-outlined": {
                            display: !!params.inputProps.value
                              ? "none"
                              : "block",
                            color: "#000",
                            fontWeight: 100,
                            "&.Mui-focused": {
                              display: !!params.inputProps.value
                                ? "none"
                                : "block",
                              color: "red",
                            },
                          },
                          "& .MuiOutlinedInput-root": {
                            "& input::placeholder": {
                              opacity: 1, 
                              color: "#000", 
                            },
                            "&.Mui-disabled input::placeholder": {
                              opacity: 1,
                              color: "#000",
                            },
                            "& .MuiOutlinedInput-notchedOutline": {
                              borderColor: "#ccc",
                            },
                            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                              borderColor: "#000",
                            },
                          },
                        }}
                      />
                    )}
                  />
                  {/* autocomplete-3  model */}
                  <Autocomplete
                    className="classic-search-autocomplete"
                    options={classicModelList}
                    value={selectModel || ""}
                    disableClearable
                    onChange={handleModelChange}
                    disabled={isDisabled(3)}
                    onInputChange={handleonInputModelchange}
                    open={isModelDropdownOpen && classicModelList.length > 0}
                    onOpen={() => setModelDropdownOpen(true)}
                    onClose={() => setModelDropdownOpen(false)}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder="Model"
                        sx={{
                          backgroundColor: "#D9D9D9",
                          borderRadius: "5px",
                          "& .MuiFilledInput-root": {
                            display: "flex",
                            justifyContent: "center",
                            backgroundColor: "#D9D9D9",
                            width: "100%",
                            height: "100%",
                            borderRadius: "50px",
                            paddingRight: "4px!important",

                            "& input": {
                              marginBottom: 2,
                            },
                          },
                          "& .MuiInputLabel-outlined": {
                            display: !!params.inputProps.value
                              ? "none"
                              : "block",
                            color: "#000",
                            fontWeight: 100,
                            "&.Mui-focused": {
                              display: !!params.inputProps.value
                                ? "none"
                                : "block",
                              color: "red",
                            },
                          },
                          "& .MuiOutlinedInput-root": {
                            "& input::placeholder": {
                              opacity: 1, 
                              color: "#000", 
                            },
                            "&.Mui-disabled input::placeholder": {
                              opacity: 1, 
                              color: "#000", 
                            },
                            "& .MuiOutlinedInput-notchedOutline": {
                              borderColor: "#ccc",
                            },
                            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                              borderColor: "#000",
                            },
                          },
                        }}
                      />
                    )}
                  />
                  {/* autocomplete 4 engine */}
                  <Autocomplete
                    className="classic-search-autocomplete"
                    options={classicEngineList}
                    value={selectEngine || ""}
                    disableClearable
                    onChange={handleEngineChange}
                    disabled={isDisabled(4)}
                    onInputChange={handleonInputEnginechange}
                    open={isEngineDropdownOpen && classicEngineList.length > 0}
                    onOpen={() => setEngineDropdownOpen(true)}
                    onClose={() => setEngineDropdownOpen(false)}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder="Engine"
                        sx={{
                          backgroundColor: "#D9D9D9",
                          borderRadius: "5px",
                          "& .MuiFilledInput-root": {
                            display: "flex",
                            justifyContent: "center",
                            backgroundColor: "#D9D9D9",
                            width: "100%",
                            height: "100%",
                            borderRadius: "50px",
                            paddingRight: "4px!important",

                            "& input": {
                              marginBottom: 2,
                            },
                          },
                          "& .MuiInputLabel-outlined": {
                            display: !!params.inputProps.value
                              ? "none"
                              : "block",
                            color: "#000",
                            fontWeight: 100,
                            "&.Mui-focused": {
                              display: !!params.inputProps.value
                                ? "none"
                                : "block",
                              color: "red",
                            },
                          },
                          "& .MuiOutlinedInput-root": {
                            "& input::placeholder": {
                              opacity: 1, // Ensure placeholder opacity is 1
                              color: "#000", // Optional: Customize placeholder color
                            },
                            "&.Mui-disabled input::placeholder": {
                              opacity: 1, // Set disabled placeholder opacity to 1
                              color: "#000000", // Optional: Customize disabled placeholder color
                            },
                            "& .MuiOutlinedInput-notchedOutline": {
                              borderColor: "#ccc",
                            },
                            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                              borderColor: "#000",
                            },
                          },
                        }}
                      />
                    )}
                  />
                  {selectYear &&
                  quallifierCollection &&
                  quallifierCollection.hasOwnProperty("name") &&
                  quallifierCollection.values.length > 1 ? (
                    // if drive types are more than 1(select 5- drive type)
                    <>
                      <FormControl className="classic-search-autocomplete">
                        <InputLabel
                          sx={{
                            color: "#000000",
                            display: driverType ? "none" : "flex",

                            "&.Mui-focused": {
                              display: "none",
                            },
                          }}
                        >
                          {`Select ${quallifierCollection.name[0]}`}
                        </InputLabel>
                        <Select
                          open={isDriverDropdownOpen}
                          onOpen={() => setDriverDropdownOpen(true)}
                          onClose={() => setDriverDropdownOpen(false)}
                          label={`Select ${quallifierCollection.name[0]}`}
                          value={driverType || ""}
                          onChange={handleChange}
                          input={<OutlinedInput />}
                          sx={{
                            backgroundColor: "#D9D9D9",
                            borderRadius: "5px",
                            "& .MuiFilledInput-root": {
                              display: "flex",
                              justifyContent: "center",
                              backgroundColor: "#D9D9D9",
                              width: "100%",
                              height: "100%",
                              borderRadius: "50px",
                              paddingRight: "4px!important",

                              "& input": {
                                marginBottom: 2,
                              },
                            },
                            "& .MuiInputLabel-outlined": {
                              color: "#000",
                              fontWeight: 100,
                              "&.Mui-focused": {
                                display: !!driverType ? "none" : "block",
                                color: "red",
                              },
                            },
                            "& .MuiOutlinedInput-root": {
                              "& .MuiOutlinedInput-notchedOutline": {
                                borderColor: "#ccc",
                              },
                              "&.Mui-focused .MuiOutlinedInput-notchedOutline":
                                {
                                  borderColor: "#000",
                                },
                            },
                          }}
                        >
                          {quallifierCollection.values.map((data, ind) => {
                            return (
                              <MenuItem
                                value={data.id}
                                name={data.values}
                                id={data.values}
                              >
                                {data.values}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                    </>
                  ) : (
                    // if drive type is only one select 5- drive type
                    selectYear &&
                    quallifierCollection &&
                    quallifierCollection.hasOwnProperty("name") && (
                      <>
                        <InputLabel
                          sx={{
                            color: "#000000",
                            display: quallifierCollection.values[0].id
                              ? "none"
                              : "flex",

                            "&.Mui-focused": {
                              display: "none",
                            },
                          }}
                        >
                          {" "}
                          {`select ${quallifierCollection.name[0]}`}
                        </InputLabel>
                        <Select
                          disabled
                          labelId="demo-select-small-label"
                          //                 open={isDropdownOpen}
                          //             onOpen={() => setSubmodalOpen(true)}
                          // onClose={() => setSubmodalOpen(false)}
                          id="demo-select-small"
                          value={quallifierCollection.values[0].id}
                          defaultValue={quallifierCollection.values[0].id}
                          // disabled
                          sx={{
                            backgroundColor: "#D9D9D9",
                            borderRadius: "5px",
                            "& .MuiFilledInput-root": {
                              display: "flex",
                              justifyContent: "center",
                              backgroundColor: "#D9D9D9",
                              width: "100%",
                              height: "100%",
                              borderRadius: "50px",
                              paddingRight: "4px!important",

                              "& input": {
                                // Center align the input text
                                marginBottom: 2,
                              },
                            },
                            "& .MuiInputLabel-outlined": {
                              color: "#000",
                              fontWeight: 100,
                              "&.Mui-focused": {
                                display: !!driverType ? "none" : "block",
                                color: "red",
                              },
                            },
                            "& .MuiOutlinedInput-root": {
                              "& .MuiOutlinedInput-notchedOutline": {
                                borderColor: "#ccc",
                              },
                              "&.Mui-focused .MuiOutlinedInput-notchedOutline":
                                {
                                  borderColor: "#000",
                                },
                            },
                            "& .MuiSelect-select": {
                              color: "#000",
                              fontWeight: "500",
                            },
                          }}
                        >
                          <MenuItem value={quallifierCollection.values[0].id}>
                            {quallifierCollection.values[0].values}
                          </MenuItem>
                        </Select>
                      </>
                    )
                  )}
                  {/* select 6- sub modal */}
                  {selectYear &&
                    subModel &&
                    subModel.hasOwnProperty("name") && (
                      <FormControl className="classic-search-autocomplete">
                        <InputLabel
                          sx={{
                            color: "#000000",
                            display: currentSubModelVal ? "none" : "flex",

                            "&.Mui-focused": {
                              display: "none",
                            },
                          }}
                        >
                          {`Select ${subModel.name[0]}`}{" "}
                        </InputLabel>
                        <Select
                          labelId="demo-select-small-label"
                          // disabled={isDisabled(7)}
                          // disabled
                          id="demo-select-small"
                          value={currentSubModelVal || ""}
                          // value={selectedProduct}
                          open={isSubmodalOpen}
                          onOpen={() => setSubmodalOpen(true)}
                          onClose={() => setSubmodalOpen(false)}
                          defaultValue="select sub modal"
                          label="Select sub model"
                          onChange={handlesubModelChange}
                          sx={{
                            backgroundColor: "#D9D9D9",
                            borderRadius: "5px",
                            "& .MuiFilledInput-root": {
                              display: "flex",
                              justifyContent: "center",
                              backgroundColor: "#D9D9D9",
                              width: "100%",
                              height: "100%",
                              borderRadius: "50px",
                              paddingRight: "4px!important",

                              "& input": {
                                marginBottom: 2,
                              },
                            },
                            "& .MuiInputLabel-outlined": {
                              color: "#000",
                              fontWeight: 100,
                              display: currentSubModelVal ? "block" : "none",
                              // "&.Mui-focused": {
                              //   display: !!selectedYear ? "none" : "block",
                              //   color: "red",
                              // },
                            },
                            "& .MuiOutlinedInput-root": {
                              "& .MuiOutlinedInput-notchedOutline": {
                                borderColor: "#ccc",
                              },
                              "&.Mui-focused .MuiOutlinedInput-notchedOutline":
                                {
                                  borderColor: "#000",
                                },
                            },
                          }}
                        >
                          <MenuItem value="" disabled>
                            <span style={{ color: "#000", fontWeight: 500 }}>
                              Select Sub Modal
                            </span>
                          </MenuItem>

                          {subModel.values.map((data, ind) => (
                            <MenuItem key={ind} value={data.id}>
                              {data.values}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    )}
                </Box>
              </Box>
            </Box>
            <Divider
              orientation="vertical"
              flexItem
              sx={{
                borderRightWidth: "0.7px",
                borderColor: "#808080",
                marginLeft: "40px",
              }}
            />
            <Box
              ref={productContainerRef}
              className="productListItemMain"
              sx={{
                width: "100%",
                padding: 3,
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap",
                gap: 3,
              }}
            >
              {/* {productList &&
                productList.length > 0 &&
                productList.map((product, index) => {
                  return ( */}
              {productList && productList.length > 0 ? (
                productList.map((product, index) => {
                  console.log("product list... ", product);
                  return (
                    <Box
                      className="productListItemSub"
                      sx={{
                        width: "31%",
                        display: "flex",
                        flexDirection: "column",
                        borderRadius: "14px",
                        borderColor: "#808080",
                        borderWidth: "1px",
                        borderStyle: "solid",
                        justifyContent: "space-between",
                        paddingBottom: 1,
                      }}
                    >
                      <Box
                        onClick={() => {
                          dispatch(
                            getProductAppData(
                              product.partNumber,
                              navigate,
                              product,
                              null,
                              setOpenModal
                            )
                          );
                          dispatch(CommonSetupActions.setSelectedPart(product));
                        }}
                        sx={{
                          width: "100%",
                          height: "270px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",

                          borderTopLeftRadius: "14px",
                          borderTopRightRadius: "14px",
                          cursor: "pointer",
                        }}
                      >
                        {product.image || product.filename ? (
                          <img
                            src={
                              product.image ? product.image : product.filename
                            }
                            style={{
                              objectFit: "contain",
                              width: "90%",
                              height: "80%",
                            }}
                            alt={product.ymm || product.partTypename}
                          />
                        ) : (
                          <img
                            src={noimage}
                            style={{
                              objectFit: "contain",
                              width: "90%",
                              height: "80%",
                            }}
                            alt="products"
                          />
                        )}
                      </Box>
                      <Box
                        sx={{
                          width: "auto",
                          height: "auto",
                          padding: 2,
                          display: "flex",
                          flexDirection: "column",
                          backgroundColor: "#F9F9F9",
                          // gap: 1,
                          // justifyContent: "space-between",
                        }}
                      >
                        <Typography
                          sx={{
                            fontWeight: 600,
                            fontSize: "16px",
                            color: "#252525",
                            fontFamily: "DM Sans",
                          }}
                        >
                          {product.partNumber} -{" "}
                          {product.hasOwnProperty("ymm") && product.ymm
                            ? product.ymm.replace(/-/g, " ")
                            : selectedProduct.type === "VIN"
                            ? `${selectedProduct.value.split("!!")[5]} ${
                                selectedProduct.value.split("!!")[3]
                              } ${selectedProduct.value.split("!!")[4]}`
                            : selectedProduct?.label
                            ? selectedProduct.label.split(" ")[0] +
                              " " +
                              selectedProduct.label.split(" ")[1]
                            : ""}{" "}
                          {product.newparttypename.replace(/-/g, " ")}
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          backgroundColor: "#F9F9F9",
                          marginBottom: 1,
                          backgroundColor: "#F9F9F9",
                        }}
                      >
                        <Button
                          variant="contained"
                          onClick={() => {
                            sessionStorage.clear()
                            dispatch(
                              getProductAppData(
                                product.partNumber,
                                navigate,
                                product,
                                null,
                                setOpenModal
                              )
                            );
                            dispatch(
                              CommonSetupActions.setSelectedPart(product)
                            );
                          }}
                          sx={{
                            width: "90%",
                            textTransform: "none",
                            backgroundColor: "#EC1B24",
                            height: "49px",
                            borderRadius: "44px",
                          }}
                          endIcon={<ArrowRightAltIcon />}
                        >
                          View Product
                        </Button>
                      </Box>
                    </Box>
                  );
                })
              ) : productlistmessage ? (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "200px",
                    textAlign: "center",
                    fontFamily: "DM Sans",
                    fontWeight: 600,
                    fontSize: "18px",
                    color: "#252525",
                  }}
                >
                  PWR parts are not currently available for your application.
                  Please check back later or use the contact form to inquire
                  further about your component needs.
                </Box>
              ) : (
                <Box></Box>
              )}
            </Box>
          </Box>
        </Box>
        <PartNoModal open={open} handleClose={handleClose} />
      </Box>
      {/* } */}
      <CommonLoader
        open={openModal}
        handleOpen={handleOpen}
        handleClose={handleClosee}
      />
      <CommonFooter />
    </LayoutComponent>
  );
};

export default Ecatlog;
