import routes from "../../AppRoutes/routes.json";
import { CommonSetupActions } from "../../Slices/Commonslice";
import { getProductAppData } from "../ProductPages/ProductDisplay/GearBoxAction";
import { ApiLink } from "../Utils/Apilink";
// import {showLoader, hideLoader} from "../../Slices/Commonslice";

export const searchProduct = (selectedProduct, navigate, setSearcheddata,setLoading, onSearchComplete,setOpenModal) => {
  return (dispatch, getState) => {
    // dispatch(CommonSetupActions.showLoader());
    try {
   if(setOpenModal){
    setOpenModal(true)
   }
        dispatch(CommonSetupActions.setProductlistMessage(false))
       dispatch(CommonSetupActions.setProductList());
          dispatch(CommonSetupActions.setSelectedPart({}))
       dispatch(CommonSetupActions.setQuallifierCollection({}));
      dispatch(CommonSetupActions.setSubModel({}));
      dispatch(CommonSetupActions.setProductList({}));
      dispatch(CommonSetupActions.setRadioValue(null))
      dispatch(CommonSetupActions.setCurrentSelectedYear(null))
      dispatch(CommonSetupActions.setCurrentSelectedEngine(""))
      dispatch(CommonSetupActions.setCurrentSelectedMake(""))
      dispatch(CommonSetupActions.setCurrentSelectedModel(""))
      dispatch(CommonSetupActions.setCurrentSelectedSubModelValue(null))

      
      const product = getState().CommonStore.selectedProduct;
      console.log("hereeeeeee", selectedProduct);
      console.log("****",product)
  
      // const baseVehicleID=
      setLoading(true)
      if (selectedProduct.type === "Applications"||selectedProduct.type==="VIN") {
        let baseVehicleID =  selectedProduct.type==="VIN"?selectedProduct.value.split("!!")[0]:selectedProduct.value.split("===")[0];
        let engineID = selectedProduct.type==="VIN"?selectedProduct.value.split("!!")[1]:selectedProduct.id.split("!")[3];
        console.log("split", baseVehicleID);
        fetch(
          `${ApiLink}qualifierCollection/${baseVehicleID}/${engineID}/noqualifier/0/0`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json; charset=utf-8",
            },
          }
        )
          .then((response) => {
            console.log("response1", response);
            return response.json();
          })
          .then((res) => {
            console.log("res", res);
            setLoading(false)
            if (res === "fail") {
              console.log('fail')
              sessionStorage.clear()
              navigate(routes.Ecatlog);
              dispatch(getProductList(ApiLink, baseVehicleID, engineID ,"noqualifier","noqualifier",onSearchComplete,setOpenModal));
            } else {
              console.log('success');
              if (res.values.length === 1) {
                fetch(
                  `${ApiLink}qualifierCollection/${baseVehicleID}/${engineID}/${res.values[0].id}/0/0`,
                  {
                    method: "GET",
                    headers: {
                      "Content-Type": "application/json; charset=utf-8",
                    },
                  }
                ).then((response12) => {
                  console.log("response1", response12);
                  return response12.json();
               
                })
                .then((res1) => {
                 console.log('resss1',res1)
                 dispatch(CommonSetupActions.setSubModel(res1))
                
                 if(setOpenModal){
                  setOpenModal(false)
                 }
                })
              }
              dispatch(CommonSetupActions.setQuallifierCollection(res));
              sessionStorage.clear()
              navigate(routes.Ecatlog,{state:{baseVehicleID:baseVehicleID,engineID:engineID}});
             if(setOpenModal) setOpenModal(false)
            }
            // setSearcheddata([]);
            
          });
      }
      else{
        fetch(
          `${ApiLink}premiumCollection/${selectedProduct.label}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json; charset=utf-8",
            },
          }
        )
          .then((responsee) => {
            console.log("response1", responsee);
            return responsee.json();
          })
          .then((ress) => {
            console.log("res*", ress, ress.ymm);
            setLoading(false)
            dispatch(CommonSetupActions.setSelectedPart(ress[0]))
           dispatch(getProductAppData(selectedProduct.label,navigate,selectedProduct,ress[0].ymm,setOpenModal))
          
          })
      
       
      }
    } catch (err) {
      console.log(err);
    }finally{
      // dispatch(CommonSetupActions.hideLoader());
    }
  };
};

export const getProductList = (link, baseVehicleID, engineID,qualifier_id,qualifier_name,onSearchComplete,setOpenModal) => {
  return (dispatch, getState) => {
   if(setOpenModal) setOpenModal(true)
    try {
     dispatch(CommonSetupActions.setProductlistMessage(false))
     

      fetch(
        `${link}websiteProductList/${baseVehicleID}/${engineID}/${qualifier_id}/${qualifier_name}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json; charset=utf-8",
          },
        }
      )
        .then((response) => {
          console.log("response1", response);
          // onSearchComplete();

          return response.json();
        })
        .then((res) => {
          console.log("res2", res);
          if(setOpenModal){
            setOpenModal(false)
          }
          dispatch(CommonSetupActions.setProductlistMessage(true))
          dispatch(CommonSetupActions.setProductList(res.partTypeArray))
          if(onSearchComplete){
            onSearchComplete()
          }

         
        })
        .catch((err) => console.log(err));
    } catch (err) {}
  };
};
