import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Box, Button, Typography } from "@mui/material";
import PhoneIcon from "@mui/icons-material/Phone";
import Download from "../../../Assets/download.png";
import "../ProductDisplay/Productpage.css";
import { ImagesModal } from "../ProductDisplay/ImagesModal";
export const ProductSpecification = () => {
  const [open, setOpen] = useState(false);
  const handleClick = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const { productImages } = useSelector((state) => state.CommonStore);
  console.log("product img: ", productImages);
  const {
    specification,
    selectedProduct,
    selectedPart,
    selectYear,
    selectMake,
    selectModel,
  } = useSelector((state) => state.CommonStore);
  const [currentImg, setCurrentImg] = useState(productImages[0]);

  console.log("slectedpart*", selectedPart, selectedProduct);

  function capitalizeWords(str) {
    return str
      .split(" ")
      .map((word) => {
        return word.charAt(0).toUpperCase() + word.slice(1);
      })
      .join(" ");
  }

  const getProductName = () => {
    const formatNewPartTypeName = (name) =>
      name
        .replace(/-/g, " ") // Replace hyphens with spaces
        .toLowerCase() // Convert to lowercase
        .replace(/\b\w/g, (char) => char.toUpperCase()); // Capitalize the first letter of each word

    if (selectedProduct.type === "Part Numbers") {
      console.log("test1");
      return `${selectedProduct.label} - ${
        selectedProduct.value.split("===")[0]
      }`;
    } else if (
      selectedProduct.type === "Applications" ||
      selectedProduct.type === "VIN"
    ) {
     if(selectedProduct.type === "VIN"){
     return  `${selectedPart.partNumber} - ${formatNewPartTypeName(selectedPart.newparttypename)}`
     }
     else{
      return `${selectedPart.partNumber} - ${
        selectedProduct.label.split(" ")[0]
      }  ${selectedProduct.label.split(" ")[1]}  ${
        selectedProduct.label.split(" ")[2]
      }  ${formatNewPartTypeName(selectedPart.newparttypename)}`;
     }
       
      console.log("test2");
     
    } else {
      console.log("test3");
      if (selectedPart.hasOwnProperty("ymm")) {
        return `${selectedPart.partNumber} - ${selectedPart.ymm.replace(
          /-/g,
          " "
        )}  ${formatNewPartTypeName(selectedPart.newparttypename)}`;
      } else {
        return `${selectedPart.partNumber} - ${selectYear}  ${selectMake.label} ${
          selectModel.label
        }  ${formatNewPartTypeName(selectedPart.newparttypename)}`;
      }
    }
  };

  const getProductDescription = () => {
    if (
      selectedPart.newparttypename === "Rack and Pinion Assembly" ||
      selectedPart.newparttypename === "rack-and-pinion-assembly"
    ) {
      return `At PWR Steer, we’re committed to delivering power steering products that exceed expectations. Engineered for precision and built for longevity, our 100% new Rack and Pinion systems eliminate the risks of remanufactured parts. Every unit is designed to optimize installation efficiency, reduce failures, and enhance customer satisfaction.
`;
    } else if (
      selectedPart.newparttypename === "power-steering-pump" ||
      selectedPart.newparttypename === "power steering pump"
    ) {
      return `PWR Power Steering Pumps are engineered to deliver unmatched durability, precision, and reliability. Every pump is designed to enhance installation efficiency and provide long-lasting performance under real-world conditions. From advanced testing to innovative design, PWR pumps deliver the confidence technicians and drivers demand.
`;
    } else if (
      selectedPart.newparttypename === "steering-gear" ||
      selectedPart.newparttypename === "steering gear"
    ) {
      return `PWR Gear Boxes are engineered to deliver the durability and precision required for seamless operation. Every unit is 100% new, eliminating the challenges associated with remanufactured products, such as inconsistent performance and early failure rates. PWR delivers the confidence technicians and drivers demand.
`;
    }
  };
  return (
    <Box
      className="productspecification_main_container"
      sx={{
        width: "100%",
        display: "flex",
        flexWrap: "wrap",
        gap: "20px",
        justifyContent: "space-between",
      }}
    >
      {/* First Div */}
      <Box
        className="product_description_box"
        sx={{
          width: specification?.length > 0 ? "30%" : "45%",
          // maxWidth: "356px",
          height: "auto",
          color: "#252525",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography
          sx={{
            width:'100%',
            // width: "360px",
            fontFamily: "DM Sans",
            fontWeight: 800,
            fontSize: "23px",
            lineHeight: "35px",
            color: "#d1191f",
          }}
        >
          {getProductName()}
        </Typography>

        <hr
          style={{
            width: "100%",
            // marginTop: "25px",
            color: "#3F3F3F",
          }}
        />

        <Typography
          sx={{
            fontFamily: "DM Sans",
            fontWeight: 700,
            fontSize: "16px",
          }}
        >
          Product Description
        </Typography>
        <Typography
          sx={{
            fontFamily: "DM Sans",
            fontWeight: 500,
            fontSize: "14px",
            lineHeight: "22px",
            marginTop: "10px",
          }}
        >
          {getProductDescription()}
        </Typography>
        <hr
          style={{
            width: "100%",
            marginTop: "25px",
            color: "#3F3F3F",
          }}
        />
        <Typography
          sx={{
            width: "111px",
            height: "11px",
            fontFamily: "DM Sans",
            fontWeight: 700,
            fontSize: "16px",
            lineHeight: "27px",
            color: "#252525",
          }}
        >
          To Know More
        </Typography>
        <Box
          sx={{
            width: "349px",
            height: "50px",
            border: "1px solid #EC1B24",
            borderRadius: "60px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: "30px",
          }}
          tabIndex={0}
          onKeyDown={(e) => {
            if (e.key === "Enter" || e.key === " ") {
              window.open(
                "https://www.pwrsteer.com/uploads/PWRBrochureSinglePage1124.pdf",
                "_blank",
                "noopener,noreferrer"
              );
            }
          }}
        >
          <Button
            onClick={() => {
              window.open(
                "https://www.pwrsteer.com/uploads/PWRBrochureSinglePage1124.pdf",
                "_blank",
                "noopener,noreferrer"
              );
            }}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: "8px",
              width: "100%",
              height: "100%",
              border: "none",
              padding: "0",
              textTransform: "none",
              color: "#FFFFFF",
            }}
           
                    
          >
            <img
              src={Download}
              sx={{
                width: "22px",
                height: "18.33px",
                color: "#FFFFFF",
              }}
            />
            <span
              style={{
                fontFamily: "DM Sans",
                fontWeight: 500,
                fontSize: "14px",
                lineHeight: "22px",
                color: "#EC1B24",
              }}
            >
              Download Brochure
            </span>
          </Button>
        </Box>
      </Box>

      {/* Image Section .. second div */}
      <Box
        className="product_second_div"
        sx={{
          width: specification?.length > 0 ? "30%" : "45%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: "15px",
        }}
      >
        <Box
          onClick={handleClick}
          tabIndex={0}
          onKeyDown={(e) => {
            if (e.key === "Enter" || e.key === " ") {
              handleClick();
            }
          }}
          sx={{
            // width: specification?.length > 0 ? "" : "45%",
            width: "100%",
            // width: "400px",
            height: "416px",
            borderRadius: "10px",
            border: "0.5px solid #ccc",
            overflow: "hidden",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            // marginTop: "-25px",
          }}
        >
          {currentImg && currentImg.fileName ? (
            <img
              src={currentImg.fileName}
              style={{
                width: "100%",
                height: "100%",
                objectFit: "contain",
              }}
              alt={currentImg.partNumber || "Image"}
            />
          ) : (
            <Typography
              sx={{
                fontFamily: "DM Sans",
                fontWeight: 600,
                fontSize: "18px",
                color: "#808080",
              }}
            >
              Photo Coming Soon
            </Typography>
          )}
        </Box>
        {/* Thumbnails */}
        <Box
          sx={{
            display: "flex",
            gap: "6px",
            width: "100%",
            justifyContent:'center',
            alignItems:'center',
            cursor: "pointer",
            flexDirection:'row',
            flexWrap:'wrap'
          }}
        >
          {productImages && productImages.length > 1
            ? productImages.map((img) => {
                const isActive = currentImg.fileName === img.fileName;
                return (
                  <Box
                    key={img.img_id}
                    sx={{
                      width: "64.84px",
                      height: "49.28px",
                      border: isActive
                        ? "0.5px solid #EC1B24"
                        : "0.5px solid #808080",
                      borderRadius: "10px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      padding: "2px",
                    }}
                    onClick={() => setCurrentImg(img)}
                    tabIndex={0}
                    onKeyDown={(e) => {
                      if (e.key === "Enter" || e.key === " ") {
                        setCurrentImg(img);
                      }
                    }}
                  >
                    <img
                      src={img.fileName}
                      alt={img.partNumber}
                      style={{
                        width: "42.3px",
                        height: "42.3px",
                        objectFit: "cover",
                      }}
                    />
                  </Box>
                );
              })
            : null}
        </Box>
      </Box>

      {/* Specification Section .. third div*/}
      {specification?.length > 0 && (
        <Box
          className="product-third-div"
          sx={{
            width: "30%",
            // marginTop: "-30px",
            height: "auto",
            maxHeight: "500px",
            overflow: "hidden",
          }}
        >
          <Box
            sx={{
              borderRadius: "13px",
              border: "1px solid #ccc",
              backgroundColor: "#EAEAEA",
            }}
          >
            <Typography
              sx={{
                fontFamily: "DM Sans",
                fontWeight: 700,
                fontSize: "16px",
                color: "#55120B",
                padding: "20px",
              }}
            >
              Product Specification
            </Typography>
            <Box
              sx={{
                height: "auto",
                maxHeight: "415px",
                marginBottom: "10px",
                overflowY: "auto",
                overflowX: "hidden",
                "&::-webkit-scrollbar": {
                  width: "3px",
                },
                "&::-webkit-scrollbar-thumb": {
                  backgroundColor: "#EC1B24",
                  borderRadius: "4px",
                },
                "&::-webkit-scrollbar-track": {
                  backgroundColor: "#D3D3D3",
                },
              }}
            >
              <table style={{ width: "100%", borderCollapse: "collapse" }}>
                <tbody>
                  {specification.slice(0, 9).map((spec, index) => (
                    <tr
                      key={spec.PAID}
                      style={{
                        backgroundColor:
                          index % 2 === 0 ? "#FFFFFF" : " #EAEAEA",
                      }}
                    >
                      <td style={{ textAlign: "left", padding: "20px" }}>
                        {spec.product_specs_name}
                      </td>
                      <td style={{ textAlign: "center", padding: "20px" }}>
                        {spec.product_specs_value}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </Box>
          </Box>
        </Box>
      )}
      <ImagesModal
        open={open}
        handleClose={handleClose}
        currentImg={currentImg}
        setCurrentImg={setCurrentImg}
      />
    </Box>
  );
};
