import * as React from "react";
import { useSelector } from "react-redux";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80%",
  height: "80%",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,

  display: "flex",
  flexWrap: "wrap",
  flexDirection: "row",
};

export const ImagesModal = ({
  open,
  handleClose,
  currentImg,
  setCurrentImg,
}) => {
  const { productImages, selectedPart } = useSelector(
    (state) => state.CommonStore
  );
  console.log("part: ", selectedPart)
  return (
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
       <Box className="main-modal-cont" sx={style}>
        <Box className="modal-head" sx={{
            width:'100%',
            display:'flex',
          alignItems:'center',
            height:'100px',
            height:'auto',
            margin:0,
            paddingLeft:'2%',
            backgroundColor:'#F0F3F3',
            textAlign:'left'
        }}>
          <Typography sx={{fontFamily:'DM Sans', fontSize:'30px', fontWeight:500}}>
            {selectedPart.partNumber}
          </Typography>
          {/* <IconButton
            onClick={handleClose}
            sx={{
              color: "#EC1B24",
              marginLeft:'70%',
            }}
          >
            <CloseIcon />
          </IconButton> */}

        </Box>

          
            {/* Thumbnails */}
            <Box className="thumbNails"
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: 2,
                width: "10%",
                paddingLeft:'5px',
                paddingTop:'10px',
                flexWrap: "wrap",
                height:'90%'
                // p:'5px'
                // border:'2px solid red'
              }}
            >
              {productImages && productImages.length > 1
                ? productImages.map((img) => {
                    const isActive = currentImg.fileName === img.fileName;
                    return (
                      <Box
                        key={img.img_id}
                        sx={{
                          width: "64.84px",
                          height: "49.28px",
                          border: isActive
                            ? "0.5px solid #EC1B24"
                            : "0.5px solid #808080",
                          borderRadius: "10px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          padding: "2px",
                          cursor: "pointer",
                        }}
                        onClick={() => setCurrentImg(img)}
                        tabIndex={0}
                        onKeyDown={(e) => {
                          if (e.key === "Enter" || e.key === " ") {
                            setCurrentImg(img);
                          }
                        }}
                      >
                        <img
                          src={img.fileName}
                          alt={img.partNumber}
                          style={{
                            width: "42.3px",
                            height: "42.3px",
                            objectFit: "cover",
                          }}
                        />
                      </Box>
                    );
                  })
                : null}
            </Box>
            {/* Centered Image */}
            <Box className="centeredImage"
              sx={{
                width: "80%",
                height: "80%",
                borderRadius: "10px",
                overflow: "hidden",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                // backgroundColor: "#F9F9F9",
                // marginTop: "-25px",
              }}
            >
              {currentImg && currentImg.fileName ? (
                <img
                  src={currentImg.fileName}
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "contain",
                  }}
                  alt={currentImg.partNumber || "Image"}
                />
              ) : (
                <Typography
                  sx={{
                    fontFamily: "DM Sans",
                    fontWeight: 600,
                    fontSize: "18px",
                    color: "#808080",
                    textAlign: "center",
                  }}
                >
                  Photo Coming Soon
                </Typography>
              )}
            </Box>
          {/* </Box> */}
        </Box>
      </Modal>
  );
};
