import React, { useEffect, useRef, useState } from "react";
import LayoutComponent from "../../Layout/LayoutComponent";
import CommonFooter from "../Footer/CommonFooter";
import { Box, Typography } from "@mui/material";
import bgImg from "../../Assets/Rack&Pinions/rackBg.png";
import { useNavigate } from "react-router-dom";
import routes from "../../AppRoutes/routes.json";
import rackImg from "../../Assets/Rack&Pinions/rack1.png";
import gearIcon from "../../Assets/Rack&Pinions/gearIcon.png";
import SteerPumpFig from "../../Assets/Rack&Pinions/PWR_Pump_Details.jpg";
import SteerPump from "../../Assets/ProductImages/steer-pump1.png";
import MaskGroup from "../../Assets/Rack&Pinions/Pump.jpg";

import rack2 from "../../Assets/Rack&Pinions/steer_pumpsub.jpg";
import Video from "../CommonComponent/CommonVideos";
import "../Ecatlog/SteerPump.css";

export const SteeringPump = () => {
  const navigate = useNavigate();

  const textRef = useRef(null);
  const [hrWidth, setHrWidth] = useState(0);

  useEffect(() => {
    if (textRef.current) {
      setHrWidth(textRef.current.offsetWidth);
    }
  }, []);

  useEffect(() => {
    if (!sessionStorage.getItem("hasRefreshed")) {
      sessionStorage.setItem("hasRefreshed", "true"); // Mark as refreshed
      window.location.reload();
    }
  }, []);

  const STEERING_PUMP_FEATURES = [
    "Pre-Installed OE-Specific Pulley: Application-specific pulleys are pre-installed where applicable, reducing installation time.",
    "Integral & External Reservoirs: Designed to meet OE requirements, with a new reservoir cap included when needed.",
    "Pre-Attached Sensors: OE-designed sensors are included for accurate performance in select applications.",
    "Return Line Fittings: Pre-installed return line fittings for seamless system integration.",
    "100% New Components: Built entirely from new materials, eliminating the risks associated with remanufactured parts.",
    "Comprehensive Bench Testing: Each pump is performance-tested to meet or exceed OE standards for pressure, flow, and leakage.",
    "No Pressure Consolidation: Ensures precise pressure output tailored to the application.",
    "Proprietary Packaging: Custom die-cut foam inserts and residual fluid containment bags ensure pumps arrive in perfect condition.",
  ];

  <Box component="ul">
    {STEERING_PUMP_FEATURES.map((feature, index) => (
      <li key={index} style={{ marginBottom: "10px" }}>
        {feature}
      </li>
    ))}
  </Box>;

  return (
    <LayoutComponent>
      <Box
        sx={{
          position: "relative",
          width: "100%",
          boxSizing: "border-box",
          display: "flex",
          flexDirection: "column",
          // marginBottom: "2px",
        }}
      >
        {/* 1st Row */}
        <Box className="bgSection" sx={{ position: "relative", zIndex: 1 }}>
          <Box
            className="bgSubSection"
            sx={{
              position: "relative",
              width: "100%",
              height: "auto",
              overflow: "hidden",
            }}
          >
            {/* Image */}
            <img
              src={bgImg}
              className="bgImage"
              alt="Steering Pump"
              style={{
                objectFit: "cover",
                width: "100%",
                height: "100%",
                filter: "brightness(0.7)",
              }}
            />
            {/* Dark Overlay */}
            <Box
              className="darkOverlay"
              sx={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                backgroundColor: "rgba(0, 0, 0, 0.6)",
              }}
            ></Box>
          </Box>

          {/* Navigation Box */}
          <Box
            className="navBox"
            sx={{
              position: "absolute",
              top: "10%",
              left: "8%",
            }}
          >
            <Typography
              className="navTypography"
              sx={{
                fontSize: "14px",
                // fontWeight: 500,
                display: "flex",
                alignItems: "center",
                gap: "12px",
                color: "#FFFFFF",
              }}
            >
              <Typography
                className="navLink"
                tabIndex={0}
                onClick={() => {
                  sessionStorage.clear();
                  navigate(routes.Home);
                }}
                onKeyDown={(e) => {
                  if (e.key === "Enter" || e.key === " ") {
                    sessionStorage.clear();
                    navigate(routes.Home);
                    window.scrollTo({ top: 0 });
                  }
                }}
                sx={{
                  fontFamily: "DM Sans",
                  fontWeight: 400,
                  lineHeight: "27px",
                  textAlign: "center",
                  color: "#FFFFFF",
                  textDecoration: "none",
                  cursor: "pointer",
                }}
              >
                Home
              </Typography>
              <span
                style={{
                  color: "#EC1B24",
                }}
              >
                &gt;
              </span>

              <Typography
                className="navLink"
                sx={{
                  color: "#FFFFFF",
                  fontWeight: 700,
                  fontFamily: "DM Sans",
                  textDecoration: "none",
                  lineHeight: "27px",
                  textAlign: "center",
                  cursor: "pointer",
                }}
              >
                Power Steering Pumps
              </Typography>
            </Typography>
          </Box>
          {/* end of navigation */}

          {/* Floating Box */}
          <Box
            className="floatMainBox"
            id="pump-floatmainbox-id"
            sx={{
              position: "absolute",
              top: "95%",
              left: "50%",
              transform: "translate(-50%, -90%)",
              width: "80%",

              // width: "1500px",
              // height: "630px",
              // maxHeight: "635px",

              backgroundColor: "white",
              borderRadius: "13px",
              boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
              border: "0.7px solid #808080",
              display: "flex",
              flexDirection: "row",
              // justifyContent: "space-between",
              justifyContent: "center",
              alignItems: "center",
              padding: "40px",
              gap: 0,
              // marginTop: "6%",
              // marginTop:'20px'
            }}
          >
            {/* left box */}
            <Box
              className="floatLeftBox"
              sx={{
                width: "40%",
                // height: "102px",
              }}
            >
              <Box sx={{ width: "fit-content" }}>
                <Typography
                  ref={textRef}
                  className="floatHeading"
                  sx={{
                    fontFamily: "DM Sans",
                    fontWeight: 700,
                    fontSize: "51px",
                    lineHeight: "65.79px",
                    letterSpacing: "1%",
                    // color: "#0E1415",textDecoration: 'underline',
                    // textDecorationColor: 'red',
                    // textUnderlineOffset: '15px'
                  }}
                >
                  Power Steering Pumps
                  <hr
                    style={{
                      width: `auto`,
                      color: "#E30000",
                      borderRadius: "5px",
                      border: "2px solid #E30000",
                    }}
                  />
                </Typography>
              </Box>
            </Box>
            {/* right side box */}
            <Box
              className="floatImageBox"
              sx={{
                width: "60%",
                height: "auto",
                display: "contents",
                
                // height: "640px",
              }}
            >
              <Box sx={{ width: "100%", height: "100%",
              display:'flex',
              justifyContent:'center',
              alignItems:'center'
            //  backgroundImage: `url("${SteerPumpFig}")`,
            // backgroundSize: "100% 100%",
            // backgroundPosition: "center",
            // backgroundRepeat: "no-repeat",
           }}
            >
                <img
                 
                  src={SteerPumpFig}
                  // style={{
                  //   width: "818px",
                  //   height: "auto",
                  //   objectFit: "contain",
                  // }}
                  alt="Power Steering Pumps"
                  className="rightImgBox"
                />
              </Box>
            </Box>
          </Box>
        </Box>
        <Box
          className="fourth-fifth-sixth-main"
          sx={{
            padding: "60px",
            maxWidth: "100vw",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {/* 2nd Row */}
          <Box
            className="keyFeatMain"
            id="pump-key-feat-main-id"
            sx={{
              position: "relative",
              marginTop: "12%",
              display: "flex",
              // flexWrap: 'wrap',
              flexDirection: "row",
              justifyContent: "center",
              // alignItems: "center",
              gap: 6,
              width: "100%",
              // padding:'20px'
            }}
          >
            {/* Left Box */}
            <Box
              className="leftMain_container"
              sx={{ width: "50%", height: "100%" }}
            >
              <Box
                className="rightMainSection"
                sx={{
                  width: "100%",
                  // marginTop: "24px",
                  display: "flex",
                  flexDirection: "column",
                  gap: 3,
                }}
              >
                <Box
                  className="rightMainHead"
                  sx={{
                    width: "100%",
                    // height: "102px",
                  }}
                >
                  <Typography
                    className="rightHeading"
                    sx={{
                      fontFamily: "DM Sans",
                      fontWeight: 700,
                      fontSize: "50px",
                      lineHeight: "65px",
                      color: "#000000",
                    }}
                  >
                    Performance and Reliability You Can Count On
                  </Typography>
                </Box>
                <Box
                  className="rightContentSection"
                  sx={{
                    width: "100%",
                  }}
                >
                  <Typography
                    className="rightContent"
                    sx={{
                      fontFamily: "DM Sans",
                      fontWeight: "400px",
                      fontSize: "14px",
                      lineHeight: "25px",
                      color: "#252525",
                      width: "90%",
                    }}
                  >
                    PWR Power Steering Pumps are engineered to deliver unmatched
                    durability, precision, and reliability. Every pump is
                    designed to enhance installation efficiency and provide
                    long-lasting performance under real-world conditions. From
                    advanced testing to innovative design, PWR pumps deliver the
                    confidence technicians and drivers demand.
                  </Typography>
                </Box>
                <Box
                  className="third-box-main"
                  sx={{
                    width: "100%",
                    height: "409px",
                    // backgroundColor: "#FAFAFA",
                  }}
                >
                  <Box
                    className="third-box-sub"
                    sx={{
                      width: "100%",
                      height: "100%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <img
                      className="steerPump3img"
                      src={SteerPump}
                      alt="Power Steering Pump"
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "contain",
                      }}
                    />
                  </Box>
                </Box>
              </Box>
            </Box>

            {/* Right Box */}

            <Box
              className="leftMain_container"
              sx={{
                width: "50%",
                height: "100%",

                display: "flex",
                justifyContent: "center",
                alignItems: "flex-start",
              }}
            >
              <Box
                className="leftMain"
                sx={{
                  width: "100%",
                  height: "auto",
                  // height: "630px",
                  borderRadius: "19px",
                  backgroundColor: "#F0F3F3",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                  padding: "40px",
                }}
              >
                <Box
                  className="Left-sub-section"
                  sx={{
                    width: "90%",
                    // height: "553px",
                    height: "auto",
                    backgroundColor: "#FFFFFF",
                    borderRadius: "10px",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    padding: "40px",
                    // marginTop: "38px",
                    gap: "10px",
                  }}
                >
                  <Box
                    className="leftHeadingBox"
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: "10px",
                      width: "100%",
                    }}
                  >
                    <img
                      className="gearIconImg"
                      src={gearIcon}
                      alt="key points"
                      style={{
                        width: "32px",
                        height: "32px",
                      }}
                    />
                    <Typography
                      className="leftHeadingText"
                      sx={{
                        fontFamily: "DM Sans",
                        fontWeight: 700,
                        fontSize: "19px",
                        lineHeight: "27px",
                        color: "#000",
                      }}
                    >
                      Key Features
                    </Typography>
                  </Box>

                  <Box
                    className="listSection"
                    sx={{
                      // width: "432px",
                      // height: "461px",
                      color: "#252525",
                    }}
                  >
                    <ul className="leftList">
                      {STEERING_PUMP_FEATURES.map((feature, index) => {
                        const [boldText, ...rest] = feature.split(": ");
                        return (
                          <li
                            className="listItem"
                            key={index}
                            style={{
                              width: "auto",
                              height: "auto",
                              color: "#252525",
                              marginBottom: "10px",
                              fontWeight: 400,
                              fontSize: "14px",
                              lineHeight: "22px",
                              fontFamily: "DM Sans",
                            }}
                          >
                            <strong>{boldText}</strong>
                            {rest.length > 0 && `: ${rest.join(": ")}`}
                          </li>
                        );
                      })}
                    </ul>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>

          {/* 3rd Row */}
          {/* <Box
          className="third-box-main"
          sx={{
            width: "100%",
            height: "409px",
            // backgroundColor: "#FAFAFA",
            marginTop: 5,
          }}
        >
          <Box
            className="third-box-sub"
            sx={{
              width: "100%",
              height: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img className="steerPump3img" src={SteerPump} alt="Power Steering Pump" style={{width:'100%', height:'100%', objectFit:'contain'}}/>
          </Box>
        </Box> */}

          {/* 4th row */}

          <Box
            className="fourth-box-main"
            sx={{
              width: "100%",
              height: "auto",
              display: "flex",
              flexWrap: "wrap",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              // gap: 5,
              marginTop: "3%",
            }}
          >
            {/* left box */}
            <Box
              className="fourth-left-box"
              sx={{
                width: "50%",
                // justifyContent:'center',
                // alignItems:'center',
                // display:'flex',
                // flexDirection:'column'
              }}
            >
              {/* <Box sx={{width:'80%'}}> */}
              <Typography
                className="fourth-left-heading"
                sx={{
                  width: "100%",
                  color: "#000000",
                  fontFamily: "DM Sans",
                  fontWeight: 700,
                  fontSize: "24px",
                  lineHeight: "41px",
                }}
              >
                Why Choose PWR Power Steering Pumps?
              </Typography>

              <Typography
                className="fourth-left-content"
                sx={{
                  width: "100%",
                  height: "auto",
                  fontFamily: "DM Sans",
                  fontWeight: 400,
                  fontSize: "14px",
                  lineHeight: "25px",
                  color: "#0B0F11",
                }}
              >
                At PWR Steer, we specialize in designing and manufacturing power
                steering pumps that address the challenges technicians face with
                remanufactured alternatives. Built from 100% new materials, our
                pumps are engineered for peak performance, seamless
                installation, and long-lasting durability.
              </Typography>

              <Typography
                className="fourth-question"
                sx={{
                  width: "100%",
                  fontFamily: "DM Sans",
                  fontWeight: 700,
                  fontSize: "16px",
                  lineHeight: "22px",
                  color: "#000000",
                  marginTop: "10px",
                }}
              >
                What Sets Us Apart?
              </Typography>
              <ol>
                <li className="fouth-list-item">
                  <Typography
                    className="list-item"
                    sx={{
                      width: "100%",
                      // height: "224px",
                      fontFamily: "DM Sans",
                      fontWeight: 600,
                      fontSize: "14px",
                      lineHeight: "22px",
                      color: "#000000",
                    }}
                  >
                    Decades of Collective Automotive Expertise
                    <br />
                    <span
                      className="list-item-content"
                      style={{
                        fontFamily: "DM Sans",
                        fontWeight: 400,
                        fontSize: "14px",
                        lineHeight: "22px",
                        color: "#000000",
                      }}
                    >
                      Our engineering and manufacturing teams bring extensive
                      experience across the automotive industry, specializing in
                      power steering systems, product development, and quality
                      assurance.
                    </span>
                  </Typography>
                </li>
                <li className="fouth-list-item">
                  <Typography
                    className="list-item"
                    sx={{
                      // width: "561px",
                      width: "100%",
                      marginTop: "10px",
                      fontFamily: "DM Sans",
                      fontWeight: 600,
                      fontSize: "14px",
                      lineHeight: "22px",
                      color: "#000000",
                    }}
                  >
                    Advanced Quality Standards
                    <br />
                    <span
                      className="list-item-content"
                      style={{
                        fontFamily: "DM Sans",
                        fontWeight: 400,
                        fontSize: "14px",
                        lineHeight: "22px",
                        color: "#000000",
                      }}
                    >
                      ISO/TS 16949-compliant processes ensure that every product
                      meets or exceeds industry standards for durability,
                      performance, and safety.
                    </span>
                  </Typography>
                </li>

                <li className="fouth-list-item">
                  <Typography
                    className="list-item"
                    sx={{
                      // width: "561px",
                      width: "100%",
                      marginTop: "10px",
                      fontFamily: "DM Sans",
                      fontWeight: 600,
                      fontSize: "14px",
                      lineHeight: "22px",
                      color: "#000000",
                    }}
                  >
                    Design Innovation
                    <br />
                    <span
                      className="list-item-content"
                      style={{
                        fontFamily: "DM Sans",
                        fontWeight: 400,
                        fontSize: "14px",
                        lineHeight: "22px",
                        color: "#000000",
                      }}
                    >
                      Each product is engineered to address common OE design
                      flaws, delivering improved durability and long-lasting
                      performance.
                    </span>
                  </Typography>
                </li>
              </ol>
              <Typography
                className="list-item-content1"
                sx={{
                  width: "100%",

                  fontFamily: "DM Sans",
                  fontWeight: 400,
                  fontSize: "14px",
                  lineHeight: "25px",
                  color: "#000000",
                }}
              >
                By choosing PWR Power Steering Pumps, you get a product that’s
                ready for installation and guaranteed to perform, giving both
                technicians and drivers peace of mind.
              </Typography>
              {/* </Box> */}
            </Box>
            {/* right box */}
            <Box
              className="fourth-right-box"
              sx={{ width: "50%", display: "flex", justifyContent: "center" }}
            >
              {/* <Box sx={{display:'flex',width:'100%',justifyContent:'center',alignItems:'center'}}> */}
              <Box
                className="fourth-right-box-main"
                sx={{
                  borderRadius: "19px",
                  backgroundColor: "#F0F3F3",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "80%",
                  padding: "30px",
                }}
              >
                <Box
                  className="fourth-right-box-sub"
                  sx={{
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <img
                    className="fourth-right-image"
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "contain", // Ensures the image fits without distortion
                      display: "block", // Re
                      borderRadius: "12px",
                    }}
                    src={MaskGroup}
                    alt="Image"
                  />
                </Box>
              </Box>
              {/* </Box> */}
            </Box>
          </Box>

          {/* 5th row */}
          <Box
            className="fifth-box-main"
            sx={{
              width: "100%",
              height: "auto",
              flexWrap: "wrap",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              // gap: 5,
              marginTop: "5%",
            }}
          >
            {/* right box */}
            <Box className="fifth-image-right-box" sx={{ width: "50%" }}>
              <Box
                className="fourth-right-box-main"
                sx={{
                  borderRadius: "19px",
                  backgroundColor: "#F0F3F3",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "80%",
                  padding: "30px",
                }}
              >
                <Box
                  className="fourth-right-box-sub"
                  sx={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    overflow: "hidden",
                  }}
                >
                  <img
                    className="fourth-right-image"
                    id="reservoir-fourth-right-image"
                    style={{
                      width: "100%",
                      height: "400px",
                      maxWidth: "100%",
                      objectFit: 'fill',
                      borderRadius: "12px",
                      display: "block",
                    }}
                    src={rack2}
                    alt="Steering Pump"
                  />
                </Box>
              </Box>
            </Box>
            {/* left box */}
            <Box
              className="fourth-left-box"
              sx={{
                width: "50%",
                height: "auto",
                // width: "591px",
                // height: "547px",
              }}
            >
              <Typography
                className="fourth-left-heading"
                sx={{
                  width: "100%",
                  // width: "553px",
                  // height: "17px",
                  color: "#000000",
                  fontFamily: "DM Sans",
                  fontWeight: 700,
                  fontSize: "20px",
                  lineHeight: "22px",
                  // marginBottom: "30px",
                }}
              >
                New Product Development Quality Control
              </Typography>

              <ol >
                <li className="fouth-list-item">
                  <Typography
                    className="list-item-content"
                    style={{
                      fontFamily: "DM Sans",
                      fontWeight: 500,
                      fontSize: "14px",
                      lineHeight: "22px",
                      color: "#000000",
                      // marginTop: "20px",
                    }}
                  >
                    <strong> Built-In Quality from the Start:</strong> Every
                    product is developed using our Advanced Product Quality
                    Planning (APQP) process, ensuring a flawless launch.
                  </Typography>
                </li>
                <li>
                  <Typography
                    className="list-item-content"
                    style={{
                      fontFamily: "DM Sans",
                      fontWeight: 500,
                      fontSize: "14px",
                      lineHeight: "22px",
                      color: "#000000",
                      marginTop: "20px",
                    }}
                  >
                    <strong> Collaborative Engineering: </strong> Our teams work
                    directly with manufacturers to develop products that meet
                    OE-level standards for fit, form, and function.
                  </Typography>
                </li>

                <li>
                  <Typography
                    className="list-item-content"
                    style={{
                      fontFamily: "DM Sans",
                      fontWeight: 500,
                      fontSize: "14px",
                      lineHeight: "22px",
                      color: "#000000",
                      marginTop: "20px",
                    }}
                  >
                    <strong> Comprehensive Validation:</strong> Each component
                    undergoes extensive validation and testing for real-world
                    performance and reliability.
                  </Typography>
                </li>
                <li>
                  <Typography
                    className="list-item-content"
                    style={{
                      fontFamily: "DM Sans",
                      fontWeight: 500,
                      fontSize: "14px",
                      lineHeight: "22px",
                      color: "#000000",
                      marginTop: "20px",
                    }}
                  >
                    <strong>Customer-First Approach:</strong> Designed with
                    technicians and end-users in mind, PWR products combine ease
                    of installation, durability, and exceptional value, backed
                    by industry-leading warranties.
                  </Typography>
                </li>
              </ol>
            </Box>
          </Box>

          {/* 6th row */}
          <Box
            className="sixth-box-main"
            sx={{
              width: "100%",
              // padding: "0 80px",
              boxSizing: "border-box",
              marginTop: "60px",
            }}
          >
            <Video />
          </Box>
        </Box>
      </Box>
      <CommonFooter />
    </LayoutComponent>
  );
};
