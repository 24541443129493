import React, { useEffect } from 'react'
import LayoutComponent from "../../Layout/LayoutComponent";
import CommonFooter from "../Footer/CommonFooter";
import{ Box, Typography } from "@mui/material";
import CommonVideos from '../CommonComponent/CommonVideos';
import "../Videos/Videos.css";

export const Videos = () => {

     useEffect(()=>{
          if (!sessionStorage.getItem("hasRefreshed")) {
            sessionStorage.setItem("hasRefreshed", "true"); // Mark as refreshed
            window.location.reload();
          }
        },[])
  return (
    <LayoutComponent>

<Box className="video-main-section" 
        sx={{ position: "relative", width: "100%", height: "auto", padding:'50px', boxSizing:'border-box' }}
>
   <Box className="video-sub-secion" sx={{
    marginTop:'5%'
   }}>
    <Typography className='videosHead' sx={{
        width:'204px',
        // height:'42px',
        fontFamily:'DM Sans',
        fontWeight:800,
        fontSize:'60px',
        lineHeight:'27px',
        color:'#000000',
        // marginTop:'2%'
        paddingLeft:'40px'
    }}>
    Videos
    </Typography>

    <hr style={{
        width:'97%',
        color:"#D9D9D9",
        marginTop:"20px",
        marginLeft:'40px'

    }} />

    <CommonVideos />
    </Box>
</Box>
        <CommonFooter />
    </LayoutComponent>
)
}
