import React, { useEffect, useState } from "react";
import LayoutComponent from "../../Layout/LayoutComponent";
import CommonFooter from "../../Pages/Footer/CommonFooter";
import { Box, Typography } from "@mui/material";
import bgImg from "../../Assets/Rack&Pinions/rackBg.png";
import { useNavigate } from "react-router-dom";
import routes from "../../AppRoutes/routes.json";
import rackImg from "../../Assets/Rack&Pinions/rack1.png";
import gearIcon from "../../Assets/Rack&Pinions/gearIcon.png";
import pinion from "../../Assets/Rack&Pinions/pinion1.png";
import MaskGroup from "../../Assets/Rack&Pinions/Rack_and_Pinion.jpeg";
import rack2 from "../../Assets/Rack&Pinions/Rack_&_pinion_3.jpg";
import Video from "../../Pages/CommonComponent/CommonVideos";
import "../Ecatlog/SteerPump.css";

export const RackPinion = () => {
  const navigate = useNavigate();
  useEffect(() => {
    if (!sessionStorage.getItem("hasRefreshed")) {
      sessionStorage.setItem("hasRefreshed", "true"); // Mark as refreshed
      window.location.reload();
    }
  }, []);
  const RACK_PINION_FEATURES = [
    "Factory-Installed Integral Rack Mounting Bushings: Designed for a perfect fit and easy installation.",
    "Precision-Manufactured Internal Components: Engineered to meet OE specifications for exceptional reliability.",
    "PWR Specification Compliant Tie Rod, Boot, and Boot Seal Designs",
    "OE Specification-Compliant Tie Rod, Boot, and Seal Designs: Matches original equipment for guaranteed compatibility.",
    "Exact Routed and Coated Transfer Tubes: Provides corrosion resistance and long-lasting performance. Comprehensive Functional Testing: Each unit undergoes 100% functional testing to ensure it’s ready for installation.",
    "ISO/TS Certified Manufacturing: Manufactured through globally recognized, quality-assured processes.",
    "U.S.-Based Engineering Expertise: Innovative solutions developed at our Memphis, TN engineering facility.",
    "Real-World Proven Durability: Tested and validated in the field to ensure compatibility and reliability.",
  ];

  <Box component="ul">
    {RACK_PINION_FEATURES.map((feature, index) => (
      <li key={index} style={{ marginBottom: "10px" }}>
        {feature}
      </li>
    ))}
  </Box>;

  return (
    <LayoutComponent>
      <Box
        sx={{
          position: "relative",
          width: "100%",
          boxSizing: "border-box",
          display: "flex",
          flexDirection: "column",
        }}
      >
        {/* 1st Row */}
        <Box className="bgSection" sx={{ position: "relative", zIndex: 1 }}>
          <Box
            className="bgSubSection"
            sx={{
              position: "relative",
              width: "100%",
              height: "auto",
              overflow: "hidden",
            }}
          >
            {/* Image */}
            <img
              src={bgImg}
              className="bgImage"
              alt="Rack & Pinions"
              style={{
                objectFit: "cover",
                width: "100%",
                height: "100%",
                filter: "brightness(0.7)",
              }}
            />
            {/* Dark Overlay */}
            <Box
              className="darkOverlay"
              sx={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                backgroundColor: "rgba(0, 0, 0, 0.6)",
              }}
            ></Box>
          </Box>

          {/* Navigation Box */}
          <Box
            className="navBox"
            sx={{
              position: "absolute",
              top: "10%",
              left: "8%",
            }}
          >
            <Typography
              className="navTypography"
              sx={{
                fontSize: "14px",
                fontWeight: 500,
                display: "flex",
                alignItems: "center",
                gap: "12px",
                color: "#FFFFFF",
              }}
            >
              <Typography
                className="navLink"
                onClick={() => {
                  sessionStorage.clear();
                  navigate(routes.Home);
                }}
                tabIndex={0}
                onKeyDown={(e) => {
                  if (e.key === "Enter" || e.key === " ") {
                    navigate(routes.Home);
                    window.scrollTo({ top: 0 });
                  }
                }}
                sx={{
                  fontFamily: "DM Sans",
                  fontWeight: 400,
                  lineHeight: "27px",
                  textAlign: "center",
                  color: "#FFFFFF",
                  textDecoration: "none",
                  cursor: "pointer",
                }}
              >
                Home
              </Typography>
              <span
                style={{
                  color: "#EC1B24",
                }}
              >
                &gt;
              </span>

              <Typography
                className="navLink"
                sx={{
                  color: "#FFFFFF",
                  fontWeight: 700,
                  fontFamily: "DM Sans",
                  textDecoration: "none",
                  lineHeight: "27px",
                  textAlign: "center",
                  cursor: "pointer",
                }}
              >
                Racks and Pinions
              </Typography>
            </Typography>
          </Box>
          {/* end of navigation */}

          {/* Floating Box */}
          <Box
            className="floatMainBox"
            sx={{
              position: "absolute",
              top: "95%",
              left: "50%",
              transform: "translate(-50%, -90%)",
              width: "80%",

              // width: "1500px",
              height: "auto",
              backgroundColor: "white",
              borderRadius: "13px",
              boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
              border: "0.7px solid #808080",
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              padding: "40px",
              gap: 0,
              // marginTop: "20px",
            }}
          >
            <Box
              className="floatLeftBox"
              sx={{
                width: "40%",
                // height: "102px",
              }}
            >
              <Box sx={{ width: "fit-content" }}>
                <Typography
                  className="floatHeading"
                  sx={{
                    fontFamily: "DM Sans",
                    fontWeight: 700,
                    fontSize: "51px",
                    lineHeight: "65.79px",
                    letterSpacing: "1%",
                    color: "#0E1415",
                    // textDecoration: 'underline',
                    // textDecorationColor: 'red',
                    // textUnderlineOffset: '15px'
                  }}
                >
                  New Rack and Pinions
                  <hr
                    style={{
                      color: "#E30000",
                      borderRadius: "5px",
                      border: "2px solid #E30000",
                    }}
                  />
                </Typography>
              </Box>
              {/* <Box >
                <hr
                  style={{
                    width: "100%",
                    color: "#E30000",
                    borderRadius: "5px",
                    border: "2px solid #E30000",
                  }}
                />
              </Box> */}
            </Box>

            <Box
              className="floatImageBox"
              sx={{
                width: "60%",
                height: "100%",
                // display:'contents'
              }}
            >
              <Box
                className="floatImageSubBox"
                sx={{ width: "100%", height: "90%" }}
              >
                <img
                  src={rackImg}
                  alt="Rack & Pinions"
                  className="rightImgBox"
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "contain",
                  }}
                />
              </Box>
            </Box>
          </Box>
        </Box>

        <Box
          className="fourth-fifth-sixth-main"
          sx={{
            padding: "60px",
            padding: "60px",
            maxWidth: "100vw",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {/* 2nd Row */}
          <Box
            className="keyFeatMain"
            sx={{
              position: "relative",
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              gap: 6,
              width: "100%",
            }}
          >
            {/* Left Box */}
            <Box
              className="leftMain_container"
              sx={{
                width: "50%",
                height: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "flex-start",
              }}
            >
              <Box
                className="rightMainSection"
                sx={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  gap: 2,
                }}
              >
                <Box
                  className="rightMainHead"
                  sx={{
                    width: "100%",
                  }}
                >
                  <Typography
                    className="rightHeading"
                    sx={{
                      fontFamily: "DM Sans",
                      fontWeight: 700,
                      fontSize: "50px",
                      lineHeight: "65px",
                      color: "#000000",
                      // textAlign:"justify"
                    }}
                  >
                    Performance and Reliability You Can Count On
                  </Typography>
                </Box>
                <Box
                  className="rightContentSection"
                  sx={{
                    width: "100%",
                  }}
                >
                  <Typography
                    className="rightContent"
                    sx={{
                      fontFamily: "DM Sans",
                      fontWeight: "400px",
                      fontSize: "14px",
                      lineHeight: "25px",
                      color: "#252525",
                      textAlign: "justify",
                    }}
                  >
                    At PWR Steer, we’re committed to delivering power steering
                    products that exceed expectations. Engineered for precision
                    and built for longevity, our 100% new Rack and Pinion
                    systems eliminate the risks of remanufactured parts. Every
                    unit is designed to optimize installation efficiency, reduce
                    failures, and enhance customer satisfaction.
                  </Typography>
                </Box>
                <Box
                  className="third-box-main"
                  sx={{
                    width: "100%",
                    height: "auto",
                    marginTop: 5,
                  }}
                >
                  <Box
                    className="third-box-sub"
                    sx={{
                      width: "100%",
                      height: "100%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <img
                      className="steerPump3img"
                      src={pinion}
                      alt="Rack and Pinion"
                      style={{
                        width: "100%",
                        height: "auto",
                        objectFit: "contain",
                      }}
                    />
                  </Box>
                </Box>
              </Box>
            </Box>

            {/* Right Box */}
            <Box
              className="leftMain_container"
              sx={{
                width: "50%",
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "flex-start",
              }}
            >
              <Box
                className="leftMain"
                sx={{
                  width: "100%",
                  height: "auto",

                  borderRadius: "19px",
                  backgroundColor: "#F0F3F3",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                  padding: "40px",
                }}
              >
                <Box
                  className="Left-sub-section"
                  sx={{
                    width: "90%",
                    height: "auto",
                    backgroundColor: "#FFFFFF",
                    borderRadius: "10px",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    padding: "40px",
                  }}
                >
                  <Box
                    className="leftHeadingBox"
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: "10px",
                      width: "100%",
                    }}
                  >
                    <img
                      className="gearIconImg"
                      src={gearIcon}
                      alt="key points"
                      style={{
                        width: "32px",
                        height: "32px",
                      }}
                    />
                    <Typography
                      className="leftHeadingText"
                      sx={{
                        fontFamily: "DM Sans",
                        fontWeight: 700,
                        fontSize: "19px",
                        lineHeight: "27px",
                        color: "#000",
                      }}
                    >
                      Key Features
                    </Typography>
                  </Box>

                  <Box
                    className="listSection"
                    sx={{
                      color: "#252525",
                    }}
                  >
                    <ul className="leftList">
                      {RACK_PINION_FEATURES.map((feature, index) => {
                        const [boldText, ...rest] = feature.split(": ");

                        return (
                          <li
                            className="listItem"
                            key={index}
                            style={{
                              width: "auto",

                              height: "auto",

                              color: "#252525",

                              marginBottom: "10px",

                              fontWeight: 400,

                              fontSize: "14px",

                              lineHeight: "22px",

                              fontFamily: "DM Sans",
                            }}
                          >
                            <strong>{boldText}</strong>

                            {rest.length > 0 && `: ${rest.join(": ")}`}
                          </li>
                        );
                      })}
                    </ul>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>

          {/* 4th row */}
          <Box
            className="fourth-box-main"
            sx={{
              width: "100%",
              height: "auto",
              flexWrap: "wrap",
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              marginTop: "4%",
            }}
          >
            {/* left box */}
            <Box
              className="fourth-left-box"
              sx={{
                width: "50%",
              }}
            >
              <Typography
                className="fourth-left-heading"
                sx={{
                  width: "100%",
                  height: "auto",
                  color: "#000000",
                  fontFamily: "DM Sans",
                  fontWeight: 700,
                  fontSize: "24px",
                  lineHeight: "41px",
                }}
              >
                Why Choose PWR Steer Rack and Pinions?
              </Typography>

              <Typography
                className="fourth-left-content"
                sx={{
                  width: "100%",
                  height: "auto",
                  fontFamily: "DM Sans",
                  fontWeight: 400,
                  fontSize: "14px",
                  lineHeight: "25px",
                  color: "#0B0F11",
                }}
              >
                PWR Steer is proud to lead the way in power steering innovation,
                combining decades of collective automotive expertise with
                cutting-edge technology. Every rack and pinion we produce is
                built from 100% new materials, ensuring OE-grade reliability and
                eliminating the challenges of remanufactured parts.
              </Typography>

              <Typography
                className="fourth-question"
                sx={{
                  width: "100%",
                  height: "auto",
                  fontFamily: "DM Sans",
                  fontWeight: 700,
                  fontSize: "16px",
                  lineHeight: "22px",
                  color: "#000000",
                  marginTop: "10px",
                }}
              >
                What Sets Us Apart?
              </Typography>
              <ol>
                <li className="fouth-list-item">
                  <Typography
                    className="list-item"
                    sx={{
                      width: "100%",
                      fontFamily: "DM Sans",
                      fontWeight: 600,
                      fontSize: "14px",
                      lineHeight: "22px",
                      color: "#000000",
                    }}
                  >
                    Decades of Collective Automotive Expertise
                    <br />
                    <span
                      className="list-item-content"
                      style={{
                        fontFamily: "DM Sans",
                        fontWeight: 400,
                        fontSize: "14px",
                        lineHeight: "22px",
                        color: "#000000",
                      }}
                    >
                      Our engineering and manufacturing teams bring over 15
                      years of combined experience across the automotive
                      industry, specializing in power steering systems, product
                      development, and quality assurance.
                    </span>
                  </Typography>
                </li>
                <li className="fouth-list-item">
                  <Typography
                    className="list-item"
                    sx={{
                      width: "100%",
                      marginTop: "10px",
                      fontFamily: "DM Sans",
                      fontWeight: 600,
                      fontSize: "14px",
                      lineHeight: "22px",
                      color: "#000000",
                    }}
                  >
                    Advanced Quality Standards
                    <br />
                    <span
                      className="list-item-content"
                      style={{
                        fontFamily: "DM Sans",
                        fontWeight: 400,
                        fontSize: "14px",
                        lineHeight: "22px",
                        color: "#000000",
                      }}
                    >
                      ISO/TS 16949-compliant processes ensure that every product
                      meets or exceeds industry standards for durability,
                      performance, and safety.
                    </span>
                  </Typography>
                </li>

                <li className="fouth-list-item">
                  <Typography
                    className="list-item"
                    sx={{
                      width: "100%",
                      marginTop: "10px",
                      fontFamily: "DM Sans",
                      fontWeight: 600,
                      fontSize: "14px",
                      lineHeight: "22px",
                      color: "#000000",
                    }}
                  >
                    Design Innovation
                    <br />
                    <span
                      className="list-item-content"
                      style={{
                        fontFamily: "DM Sans",
                        fontWeight: 400,
                        fontSize: "14px",
                        lineHeight: "22px",
                        color: "#000000",
                      }}
                    >
                      Each product is engineered to address common OE design
                      flaws, delivering improved durability and long-lasting
                      performance.
                    </span>
                  </Typography>
                </li>
              </ol>
              <Typography
                className="list-item-content1"
                sx={{
                  width: "100%",
                  height: "60px",
                  fontFamily: "DM Sans",
                  fontWeight: 400,
                  fontSize: "14px",
                  lineHeight: "25px",
                  color: "#000000",
                }}
              >
                By choosing PWR Steer Rack and Pinions, you’re investing in
                products that are rigorously tested to perform under the
                toughest conditions, ensuring smooth, reliable steering
                performance for years to come.
              </Typography>
            </Box>
            {/* right box */}
            <Box
              className="fourth-right-box"
              sx={{ width: "50%", display: "flex", justifyContent: "center" }}
            >
              <Box
                className="fourth-right-box-main"
                sx={{
                  borderRadius: "19px",
                  backgroundColor: "#F0F3F3",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  padding: "30px",
                  width: "80%",
                }}
              >
                <Box
                  className="fourth-right-box-sub"
                  sx={{
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <img
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "contain", // Ensures the image fits without distortion
                      display: "block", // Re
                      borderRadius: "12px",
                    }}
                    className="fourth-right-image"
                    src={MaskGroup}
                  />
                </Box>
              </Box>
            </Box>
          </Box>

          {/* 5th row */}
          <Box
            className="fifth-box-main"
            sx={{
              width: "100%",
              height: "auto",
              flexWrap: "wrap",
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: 'center',
              marginTop: "5%",
            }}
          >
            {/* right box */}
            <Box className="fifth-image-right-box" sx={{ width: "50%" }}>
              <Box
                className="fourth-right-box-main"
                sx={{
                  borderRadius: "19px",
                  backgroundColor: "#F0F3F3",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "80%",
                  padding: "30px",
                }}
              >
                <Box
                  className="fourth-right-box-sub"
                  sx={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    overflow: "hidden",
                  }}
                >
                  <img
                    className="fourth-right-image"
                    id="reservoir-fourth-right-image"
                    style={{
                      width: "100%",
                      height: "400px",
                      maxWidth: "100%",
                      objectFit: 'fill',
                      borderRadius: "12px",
                      display: "block",
                    }}
                    src={rack2}
                    alt="Rack & Pinions"
                  />
                </Box>
              </Box>
            </Box>
            {/* left box */}
            <Box
              className="fourth-left-box"
              sx={{
                width: "50%",
                height: "auto",
              }}
            >
              <Typography
                className="fourth-left-heading"
                sx={{
                  width: "100%",
                  // height: "17px",
                  color: "#000000",
                  fontFamily: "DM Sans",
                  fontWeight: 700,
                  fontSize: "20px",
                  lineHeight: "22px",
                  // marginBottom: "30px",
                }}
              >
                New Product Development Quality Control
              </Typography>

              <ol>
                <li className="fouth-list-item">
                  <Typography
                    className="list-item-content"
                    style={{
                      fontFamily: "DM Sans",
                      fontWeight: 500,
                      fontSize: "14px",
                      lineHeight: "22px",
                      color: "#000000",
                      // marginTop: "20px",
                    }}
                  >
                    <strong>Built-In Quality from the Start:</strong> Every
                    product is developed using our Advanced Product Quality
                    Planning (APQP) process, ensuring a flawless launch.
                  </Typography>
                </li>
                <li>
                  <Typography
                    className="list-item-content"
                    style={{
                      fontFamily: "DM Sans",
                      fontWeight: 500,
                      fontSize: "14px",
                      lineHeight: "22px",
                      color: "#000000",
                      marginTop: "20px",
                    }}
                  >
                    <strong> Collaborative Engineering:</strong> Our teams work
                    directly with manufacturers to develop products that meet
                    OE-level standards for fit, form, and function.
                  </Typography>
                </li>

                <li>
                  <Typography
                    className="list-item-content"
                    style={{
                      fontFamily: "DM Sans",
                      fontWeight: 500,
                      fontSize: "14px",
                      lineHeight: "22px",
                      color: "#000000",
                      marginTop: "20px",
                    }}
                  >
                    <strong>Comprehensive Validation:</strong> Each component
                    undergoes extensive validation and testing for real-world
                    performance and reliability.
                  </Typography>
                </li>
                <li>
                  <Typography
                    className="list-item-content"
                    style={{
                      fontFamily: "DM Sans",
                      fontWeight: 500,
                      fontSize: "14px",
                      lineHeight: "22px",
                      color: "#000000",
                      marginTop: "20px",
                    }}
                  >
                    <strong>Customer-First Approach:</strong> Designed with
                    technicians and end-users in mind, PWR products combine ease
                    of installation, durability, and exceptional value, backed
                    by industry-leading warranties.
                  </Typography>
                </li>
              </ol>
            </Box>
          </Box>

          {/* 6th row */}
          <Box
            className="sixth-box-main"
            sx={{
              width: "100%",
              // padding: "0 80px",
              boxSizing: "border-box",
              marginTop: "60px",
            }}
          >
            <Video />
          </Box>
        </Box>
      </Box>
      <CommonFooter />
    </LayoutComponent>
  );
};
