import { CommonSetupActions } from "../../Slices/Commonslice";
import routes from "../../AppRoutes/routes.json";
import { ApiLink } from "../Utils/Apilink";



export const showYearList = () => {
  return (dispatch, getState) => {
    try {
      fetch(ApiLink + "yearCollection", {
        method: "GET",
        headers: {
          "Content-Type": "application/json; charset=utf-8",
        },
      })
        .then((resp) => resp.json())
        .then((resp) => {
          let arr = resp.map((data, ind) => JSON.stringify(data.year));
          dispatch(CommonSetupActions.setClassicYearList(arr));
        });
      // }
    } catch (err) {
      console.log("err", err);
    }
  };
};

//action for make list
export const showMakeList = () => {
  return async (dispatch, getState) => {
    try {
      const currentYear = getState().CommonStore.selectYear;
      fetch(`${ApiLink}makeCollection/${currentYear}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json; charset=utf-8",
        },
      })
        .then((resp) => resp.json())
        .then((resp) => {         
          const formatedData= resp.map((item) => ({
            id:item.MakeID,
            label:item.make
          }));
          dispatch(CommonSetupActions.setClassicMakeList(formatedData));
        });
      // }
    } catch (err) {
      console.log("err", err);
    }
  };
};

//action for model list
export const showModelList = () => {
    return async (dispatch, getState) => {
      try {
        const currentYear = getState().CommonStore.selectYear;
        const currentMake = getState().CommonStore.selectMake.id;
          fetch(`${ApiLink}modelCollection/${currentYear}/${currentMake}`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json; charset=utf-8",
          },
        })
          .then((resp) => resp.json())
          .then((resp) => {
            const formatedData= resp.map((item) => ({
              id:item.BaseVehicleID,
              label:item.model
            }));
            dispatch(CommonSetupActions.setClassicModelList(formatedData));
          });
      } catch (err) {
        console.log("err", err);
      }
    };
  };
  
  //action for engine list
export const showEngineList = () => {
    return async (dispatch, getState) => {
      try {
        const currentBaseVehicleId = getState().CommonStore.selectModel.id;
          fetch(`${ApiLink}engineCheckCollection/${currentBaseVehicleId}`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json; charset=utf-8",
          },
        })
          .then((resp) => resp.json())
          .then((resp) => {            
            const formatedData= resp.map((item) => ({
              id:item.EngineConfigID,
              label:item.engine
            }));
            dispatch(CommonSetupActions.setClassicEngineList(formatedData));
          });
      } catch (err) {
        console.log("err", err);
      }
    };
  };

   //action for drive type
export const showDriveTypes = (navigate,onSearchComplete,setOpenModal) => {
    return (dispatch, getState) => {
      dispatch(CommonSetupActions.setProductlistMessage(false))
      try {
        const baseVehicleID = getState().CommonStore.selectModel.id;
        const engineID=getState().CommonStore.selectEngine.id;
        console.log('engineID ',engineID);
          fetch(`${ApiLink}qualifierCollection/${baseVehicleID}/${engineID}/noqualifier/0/0`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json; charset=utf-8",
          },
        })
        .then((response) => {
            console.log("response1", response);
            return response.json();
          })
          .then((res) => {
            console.log("res", res);

            if (res === "fail") {
              sessionStorage.clear()
              navigate(routes.Ecatlog);
              dispatch(getProductList(ApiLink, baseVehicleID, engineID ,"noqualifier","noqualifier",onSearchComplete,setOpenModal));
            } else {
              if (res.values.length === 1) {
                fetch(
                  `${ApiLink}qualifierCollection/${baseVehicleID}/${engineID}/${res.values[0].id}/0/0`,
                  {
                    method: "GET",
                    headers: {
                      "Content-Type": "application/json; charset=utf-8",
                    },
                  }
                ).then((response12) => {
                  console.log("response1", response12);
                  return response12.json();
               
                })
                .then((res1) => {
                 console.log('resss1',res1)
                 dispatch(CommonSetupActions.setSubModel(res1))
                })
              }

              dispatch(CommonSetupActions.setQuallifierCollection(res));
              sessionStorage.clear()
              navigate(routes.Ecatlog,{state:{baseVehicleID:baseVehicleID,engineID:engineID}});
            }

            // setSearcheddata([]);
          });
      } catch (err) {
        console.log("err", err);
      }
    };
  };

  export const getProductList = (link, baseVehicleID, engineID,qualifier_id,qualifier_name,onSearchComplete,setOpenModal) => {
    return (dispatch, getState) => {

      try {
        if(setOpenModal) setOpenModal(true)
          dispatch(CommonSetupActions.setProductlistMessage(false))
        fetch(
          `${ApiLink}websiteProductList/${baseVehicleID}/${engineID}/${qualifier_id}/${qualifier_name}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json; charset=utf-8",
            },
          }
        )
          .then((response) => {
            console.log("response1", response);
            return response.json();
          })
          .then((res) => {
            console.log("res2", res);
           
            dispatch(CommonSetupActions.setProductList(res.partTypeArray))
            dispatch(CommonSetupActions.setProductlistMessage(true))
          if(setOpenModal) setOpenModal(false)
          if(onSearchComplete) onSearchComplete()

          })
          .catch((err) => console.log(err));
      } catch (err) {}
    };
  };
  
  export const getNewRealses = (partid,setOpenModal,scrollToProductList,) => {
    return (dispatch, getState) => {
      
      try {
        dispatch(CommonSetupActions.setProductlistMessage(false))
        dispatch(CommonSetupActions.setProductlistMessage(false))
        dispatch(CommonSetupActions.setProductList());
           dispatch(CommonSetupActions.setSelectedPart({}))
        dispatch(CommonSetupActions.setQuallifierCollection({}));
       dispatch(CommonSetupActions.setSubModel({}));
       dispatch(CommonSetupActions.setProductList({}));
       dispatch(CommonSetupActions.setRadioValue(null))
       dispatch(CommonSetupActions.setCurrentSelectedYear(null))
       dispatch(CommonSetupActions.setCurrentSelectedEngine(""))
       dispatch(CommonSetupActions.setCurrentSelectedMake(""))
       dispatch(CommonSetupActions.setCurrentSelectedModel(""))
       dispatch(CommonSetupActions.setCurrentSelectedSubModelValue(null))
        console.log('setOpenModal',setOpenModal)
      if(setOpenModal)  setOpenModal(true)
            dispatch(CommonSetupActions.setSelectedProduct({}));
            dispatch(CommonSetupActions.setQuallifierCollection([]))
            dispatch(CommonSetupActions.setSubModel([]))
          
        fetch(ApiLink + "newReleases/"+ partid , {
          method: "GET",
          headers: {
            "Content-Type": "application/json; charset=utf-8",
          },
        })
          .then((resp) => resp.json())
          .then((resp) => {
          
            if(setOpenModal)  setOpenModal(false)
              dispatch(CommonSetupActions.setProductlistMessage(true))
            console.log("realseses response", resp);
            dispatch(CommonSetupActions.setRadioValue(partid))
            dispatch(CommonSetupActions.setProductList(resp))
           if (scrollToProductList)  scrollToProductList()
          
  
          });
      } catch (err) {
        console.log("error in newRealse", err);
      }
    };
  };



