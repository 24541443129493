import {
  Box,
  
  IconButton,
  
  Typography,
} from "@mui/material";
import React from "react";
import youtube from "../../Assets/HeaderIcon/Youtube.png";

import linkedIn from "../../Assets/HeaderIcon/linkedIn.png";
import Pdf from "../../Assets/Home/PdfIcon.png";
import { useLocation, useNavigate } from "react-router-dom";

import business from "../../Assets/HeaderIcon/business.png";
import norton from "../../Assets/HeaderIcon/Norton.png";
import strip from "../../Assets/HeaderIcon/strip.png";
import routes from "../../AppRoutes/routes.json";
import "../Footer/Footer.css";
import { useDispatch } from "react-redux";
import { CommonSetupActions } from "../../Slices/Commonslice";


const CommonFooter = ({ scrollToGetInTouch }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  const handleAutoscrolling = () => {
    if (location.pathname !== routes.Home) {
      dispatch(CommonSetupActions.SetscrollAutomatic(true));
      sessionStorage.clear();
      navigate(routes.Home);
    } else {
      scrollToGetInTouch();
    }
  };

  // useEffect(() => {
  //   console.log('in common footer')
  //   const loadConstantContactScript = () => {
  //     console.log( "in common footer 2")
  //     const mainDiv = document.getElementsByClassName("footer_constant_contact")[0];
  //     // Remove the existing script if it exists
  //     const existingScript = document.getElementById("signupScript");
  //     if (existingScript) {
  //       console.log( "in common footer 3")
  //       existingScript.remove();
  //     }

  //     // Add the _ctct_m variable script
  //     // const variableScript = document.createElement("script");
  //     // variableScript.textContent =
  //     //   'var _ctct_m = "ea24e4f79c9b3305c9a37c19d81ec6a2";';
  //     // document.body.appendChild(variableScript);
  //     // const para=document.createElement("p")
  //     // para.innerHTML="test"
  //     // document.body.appendChild(para)
  //     // Add the Constant Contact script
  //     const script = document.createElement("script");
  //     script.id = "signupScript";
  //     script.src =
  //       `https://static.ctctcdn.com/js/signup-form-widget/current/signup-form-widget.min.js`;
  //     script.async = true;
  //     script.defer = true;
  //     document.body.appendChild(script);
  //     script.onload=()=>{
  //       const newDiv = document.createElement("div");
  //     newDiv.className = "ctct-inline-form";
  //     newDiv.setAttribute("data-form-id", "2183ca42-9806-4ec4-9a1b-126d44072385");
  //     mainDiv.appendChild(newDiv)
  //     }
  //   };

  //   loadConstantContactScript();
  // }, [location.pathname]);

  // useEffect(() => {
  //   // Dynamically create and load the script
  //   const script = document.createElement('script');
  //   'var _ctct_m = "ea24e4f79c9b3305c9a37c19d81ec6a2";';
  //   // script.src = "https://code.jquery.com/jquery-3.7.1.min.js";
  //   script.src = `https://static.ctctcdn.com/js/signup-form-widget/current/signup-form-widget.min.js`
  //   script.async = true
  //   document.body.appendChild(script);

  //   return () => {
  //     document.body.removeChild(script);
  //   };
  // }, [location.pathname]);

  return (
    <Box
      className="footer_container"
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        // marginTop: 5,
      }}
    >
      <Box
        sx={{ width: "100%", height: "11px", backgroundColor: "#EA1B24" }}
      ></Box>
      <Box
        className="footer_maincontainer"
        sx={{
          width: "100%",
          height: "auto",
          backgroundColor: "#000000",
          display: "flex",
          flexDirection: "column",
          gap: 2,
          paddingTop: 5,

          //   paddingLeft: 6,
        }}
      >
        <Box
          className="footer_first_container"
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "row",
            gap: "30px",
            justifyContent: "center",
          }}
        >
          <Box
            className="footer_first_container_sub"
            sx={{
              width: "40%",
              display: "flex",
              flexDirection: "column",
              gap: 3,
            }}
          >
            <Box sx={{width:'100%',display:'flex',flexDirection:'column',gap:1}}>
            <Typography
              sx={{
                fontWeight: 500,
                fontSize: "14px",
                color: "#ffffff",
                fontFamily: "DM Sans",
              }}
            >
              SIGN UP FOR UPDATES
            </Typography>
          
            <Box className="footer_constant_contact">
              {" "}
              <div
                className="ctct-inline-form"
                data-form-id="2183ca42-9806-4ec4-9a1b-126d44072385"
              ></div>
              </Box>
            </Box>
            <div>{/* <button id="myButton">Click Me</button> */}</div>
            <Box
              className="footer_brochure_container"
              sx={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                gap: 4,
                // justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Box>
                {" "}
                <IconButton
                 sx={{padding:0}}
                  tabIndex={0}
                  onClick={() =>
                    window.open(
                      "https://www.youtube.com/@PWRSteer",
                      "_blank",
                      "noopener,noreferrer"
                    )
                  }
                >
                  <img src={youtube} alt="youtube" />
                </IconButton>
              </Box>
              <Box>
                {" "}
                <IconButton
                sx={{padding:0}}
                  tabIndex={0}
                  onClick={() =>
                    window.open(
                      "https://www.linkedin.com/showcase/pwr-steer/",
                      "_blank",
                      "noopener,noreferrer"
                    )
                  }
                >
                  <img src={linkedIn} alt="linkedin" />
                </IconButton>
              </Box>
              {/* <Box>
                {" "}
                <IconButton onClick={()=>window.open(pdfUrl, "_blank", "noopener,noreferrer")}>
                  <img src={insta} alt="youtube" />
                </IconButton>
              </Box> */}
            </Box>
            <Box
              className="pwr_brochure_footer"
              sx={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                gap: 1,
                marginTop:2
              }}
            >
              <img src={Pdf} alt="pdf" />
              <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
                <Typography
                  sx={{
                    fontSize: "12px",
                    fontWeight: 400,
                    color: "#ffffff",
                    fontFamily: "DM Sans",
                  }}
                >
                  PWR Brochure
                </Typography>
                <Typography
                  tabIndex={0}
                  onClick={() =>
                    window.open(
                      "https://www.pwrsteer.com/uploads/PWRBrochureSinglePage1124.pdf",
                      "_blank",
                      "noopener,noreferrer"
                    )
                  }
                  onKeyDown={(e) => {
                    if (e.key === "Enter" || e.key === " ") {
                      window.open(
                        "https://www.pwrsteer.com/uploads/PWRBrochureSinglePage1124.pdf",
                        "_blank",
                        "noopener,noreferrer"
                      );
                    }
                  }}
                  // href="https://www.pwrsteer.com/uploads/PWRBrochureSinglePage1124.pdf"
                  // download="PWR_Brochure.pdf"
                  style={{
                    color: "#FF0000",
                    fontSize: "10px",
                    fontWeight: 400,
                    textDecoration: "underline",
                    cursor: "pointer",
                  }}
                >
                  Download Now
                </Typography>
              </Box>
            </Box>
          </Box>
          <Box
            className="footer_legal_contains"
            sx={{ width: "50%", gap: "80px" }}
          >
            <Box
              className="footer_information"
              sx={{
                width: "28%",
                display: "flex",
                flexDirection: "column",
                gap: 2,
              }}
            >
              <Typography
                sx={{
                  color: "#ffffff",
                  fontWeight: 500,
                  fontSize: "14px",
                  fontFamily: "DM Sans",
                }}
              >
                INFORMATION
              </Typography>
              <Typography
                onClick={() => {
                  sessionStorage.clear();
                  navigate(routes.About);
                  window.scrollTo({ top: 0 });
                }}
                tabIndex={0}
                onKeyDown={(e) => {
                  if (e.key === "Enter" || e.key === " ") {
                    sessionStorage.clear();
                    navigate(routes.About);
                    window.scrollTo({ top: 0 });
                  }
                }}
                className="footer_sub_text"
                sx={{
                  color: "#ffffff",
                  fontWeight: 400,
                  fontSize: "12px",
                  fontFamily: "DM Sans",
                  cursor: "pointer",
                  "&:hover": { textDecoration: "underline" },
                }}
              >
                About
              </Typography>
              <Typography
                className="footer_sub_text"
                onClick={() => {
                  sessionStorage.clear();
                  navigate(routes.Accessibility);
                  window.scrollTo({ top: 0 });
                }}
                tabIndex={0}
                onKeyDown={(e) => {
                  if (e.key === "Enter" || e.key === " ") {
                    sessionStorage.clear();
                    navigate(routes.Accessibility);
                    window.scrollTo({ top: 0 });
                  }
                }}
                sx={{
                  color: "#ffffff",
                  fontWeight: 400,
                  fontSize: "12px",
                  fontFamily: "DM Sans",
                  cursor: "pointer",
                }}
              >
                Accessibility
              </Typography>

              <Typography
                className="footer_sub_text"
                onClick={handleAutoscrolling}
                tabIndex={0}
                onKeyDown={(e) => {
                  if (e.key === "Enter" || e.key === " ") {
                    handleAutoscrolling();
                  }
                }}
                sx={{
                  color: "#ffffff",
                  fontWeight: 400,
                  fontSize: "12px",
                  fontFamily: "DM Sans",
                  cursor: "pointer",
                }}
              >
                Contact
              </Typography>
              <Typography
                className="footer_sub_text"
                onClick={handleAutoscrolling}
                tabIndex={0}
                onKeyDown={(e) => {
                  if (e.key === "Enter" || e.key === " ") {
                    handleAutoscrolling();
                  }
                }}
                sx={{
                  color: "#ffffff",
                  fontWeight: 400,
                  fontSize: "12px",
                  fontFamily: "DM Sans",
                  cursor: "pointer",
                }}
              >
                Help
              </Typography>
              <Typography
                className="footer_sub_text"
                onClick={() => {
                  sessionStorage.clear();
                  navigate(routes.SiteMap);
                  window.scrollTo({ top: 0 });
                }}
                tabIndex={0}
                onKeyDown={(e) => {
                  if (e.key === "Enter" || e.key === " ") {
                    sessionStorage.clear();
                    navigate(routes.SiteMap);
                    window.scrollTo({ top: 0 });
                  }
                }}
                sx={{
                  color: "#ffffff",
                  fontWeight: 400,
                  fontSize: "12px",
                  fontFamily: "DM Sans",
                  cursor: "pointer",
                }}
              >
                Site Map
              </Typography>
              <Typography
                className="footer_sub_text"
                sx={{
                  color: "#ffffff",
                  fontWeight: 400,
                  fontSize: "12px",
                  fontFamily: "DM Sans",
                  cursor: "pointer",
                }}
                onClick={() => {
                  sessionStorage.clear();
                  navigate(routes.Warranty);
                  window.scrollTo({ top: 0 });
                }}
              >
                Warranty
              </Typography>
              <Typography
                className="footer_sub_text"
                onClick={() =>
                  window.open(
                    "https://premiumguard.com/",
                    "_blank",
                    "noopener,noreferrer"
                  )
                }
                sx={{
                  color: "#ffffff",
                  fontWeight: 400,
                  fontSize: "12px",
                  fontFamily: "DM Sans",
                  cursor: "pointer",
                }}
                //   onClick={() => {
                //   navigate(routes.Warranty);
                //   window.scrollTo({top:0})

                // }}
              >
                {/* <a
                  style={{
                    color: "#ffffff",
                    fontWeight: 400,
                    fontSize: "12px",
                    fontFamily: "DM Sans",
                    cursor: "pointer",
                  }}
                  href="https://premiumguard.com/"
                > */}
                Premium Guard Inc
                {/* </a> */}
              </Typography>
            </Box>
            <Box
              className="footer_legal"
              sx={{
                width: "30%",
                display: "flex",
                flexDirection: "column",
                gap: 2,
              }}
            >
              <Typography
                sx={{
                  color: "#ffffff",
                  fontWeight: 500,
                  fontSize: "14px",
                  fontFamily: "DM Sans",
                }}
              >
                LEGAL
              </Typography>

              <Typography
                className="footer_sub_text"
                onClick={() => {
                  sessionStorage.clear();
                  navigate(routes.PrivacyPolicy);
                  window.scrollTo({ top: 0 });
                }}
                tabIndex={0}
                onKeyDown={(e) => {
                  if (e.key === "Enter" || e.key === " ") {
                    sessionStorage.clear();
                    navigate(routes.PrivacyPolicy);
                    window.scrollTo({ top: 0 });
                  }
                }}
                sx={{
                  color: "#ffffff",
                  fontWeight: 400,
                  fontSize: "12px",
                  fontFamily: "DM Sans",
                  cursor: "pointer",
                }}
              >
                Privacy Policy
              </Typography>

              <Typography
                className="footer_sub_text"
                onClick={() => {
                  sessionStorage.clear();
                  navigate(routes.TermsOfService);
                  window.scrollTo({ top: 0 });
                }}
                tabIndex={0}
                onKeyDown={(e) => {
                  if (e.key === "Enter" || e.key === " ") {
                    sessionStorage.clear();
                    navigate(routes.TermsOfService);
                    window.scrollTo({ top: 0 });
                  }
                }}
                sx={{
                  color: "#ffffff",
                  fontWeight: 400,
                  fontSize: "12px",
                  fontFamily: "DM Sans",
                  cursor: "pointer",
                }}
              >
                Terms of Use{" "}
              </Typography>
            </Box>
          </Box>
        </Box>

        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Box
            className="footer_copyright"
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              width: "93%",

              alignItems: "center",
              marginTop: 2,
              marginBottom: 2,
            }}
          >
            <Typography
              sx={{
                fontWeight: 400,
                fontSize: "12px",
                color: "#B4B4B4",
                fontFamily: "DM Sans",
              }}
            >
              Copyright © 2024 PWR STEER. All Rights Reserved.
            </Typography>
            <Box
              className="footerImg"
              sx={{
                display: "flex",
                flexDirection: "row",
                gap: 2,
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img src={business} alt="Accredited Business" />
              <img src={norton} alt="Northon" />
              <img src={strip} alt="Stripe" />
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default CommonFooter;
