import {
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  Typography,
} from "@mui/material";
import React from "react";
import pump from "../../Assets/ProductImages/HydraulicPump.png";
import rack from "../../Assets/ProductImages/SteeringRack.png";
import gear from "../../Assets/ProductImages/GearBox.png";
import reservoir from "../../Assets/ProductImages/Reservoir.png";
import GridViewIcon from "@mui/icons-material/GridView";
import routes from "../../AppRoutes/routes.json";
import { useNavigate } from "react-router-dom";

const OurProduct = () => {
  const navigate = useNavigate();
  const cardContent = [
    { image: pump, name: "Hydraulic Pump", route: routes.SteeringPump },
    { image: rack, name: "Steering Rack", route: routes.RackPinion },
    { image: gear, name: "Gear Box", route: routes.SteeringGearBox },
    { image: reservoir, name: "Reservoir", route: routes.Reservoirs },
  ];
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        paddingBottom: 10,
      }}
    >
      <Box>
        <Typography
          sx={{
            fontWeight: 500,
            fontSize: "36px",
            color: "#55120B",
            marginTop: "30px",
            fontFamily: "DM Sans",
            paddingBottom: 3,
          }}
        >
          Our Products
        </Typography>
      </Box>
      <Box className="our-product-main-content-container" sx={{ width: "60%" }}>
        <Typography
          sx={{
            textAlign: "center",
            fontFamily: "DM Sans",
            fontWeight: 400,
            fontSize: "14px",
            color: "#333333",
          }}
        >
          PWR Steer products are crafted to meet stringent engineering
          standards, ensuring top-tier performance and reliability you can
          trust. Each component is meticulously tested to uphold high levels of
          quality and durability, delivering superior steering solutions built
          to excel in any condition. Choose PWR Steer for the confidence that
          comes with rigorously tested, dependable products designed with
          excellence at every step.
        </Typography>

       
      </Box>
      <Box
        className="our_product_img_container"
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
          gap: 3,
          marginTop: 5,
          width: "100%",
          justifyContent: "center",
          alignItems: "center",
          flexWrap: "wrap",
        }}
      >
        {cardContent.map((data, ind) => {
          return (
            <Card
              className="our-product-img-card-main"
              onClick={() => {
                sessionStorage.clear();
                
                navigate(data.route);
                window.scrollTo({ top: 0 });
              }}
              key={ind}
              sx={{
                width: "20%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                cursor: "pointer",
                // boxShadow: '0px 12px 24px 0px #0000001A',

                borderRadius: "8px",
              }}
            >
              <Box
                className="our-product-img-card-sub"
                sx={{
                  width: "100%",
                  height: "250px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  backgroundColor: "#F2F2F2",
                }}
              >
                <Box
                  className="our-product-imgage-box"
                  sx={{
                    width: "200px",
                    height: "220px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <img
                    className="our-product-imgage"
                    src={data.image}
                    alt={data.name}
                    style={{
                      maxWidth: "100%",
                      maxHeight: "100%",
                      objectFit: "contain", // Ensures the image scales properly
                    }}
                  />
                </Box>
              </Box>
              <CardContent>
                <Typography sx={{ fontWeight: 600, fontSize: "20px" }}>
                  {data.name}
                </Typography>
              </CardContent>
            </Card>
          );
        })}
      </Box>
    </Box>
  );
};

export default OurProduct;
