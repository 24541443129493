import React from 'react'
import { Backdrop, Button, CircularProgress } from '@mui/material';


const CommonLoader = ({open,handleOpen,handleClose}) => {
  return (
    <div>
    
      <Backdrop
        sx={(theme) => ({ color: '#fff', zIndex: theme.zIndex.drawer + 1 })}
        open={open}
        onClick={handleClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );

  
}

export default CommonLoader