import { combineReducers, configureStore } from "@reduxjs/toolkit";
import CommonSliceReducer, { CommonSetupActions } from "../Slices/Commonslice";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage"; 


const persistConfig = {
  key: "root", // The key in localStorage where the state will be saved
  storage,     // Use localStorage (or sessionStorage if you prefer)
  whitelist: ["CommonStore"], // List of reducers you want to persist
};

const appReducer = combineReducers({
  CommonStore: CommonSliceReducer,
});


const persistedReducer = persistReducer(persistConfig, appReducer);

const Store  = configureStore({
    reducer:persistedReducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: false, // Avoid warnings related to redux-persist
      }),
  
})

export const persistor = persistStore(Store); // Create a persistor instance

export default Store;