import React, { useState, useRef, useEffect } from "react";
import { Box, Typography, CircularProgress, InputBase } from "@mui/material";
import LayoutComponent from "../../Layout/LayoutComponent";
import CommonFooter from "../Footer/CommonFooter";
import SearchIcon from "@mui/icons-material/Search";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import "../Articles/Articles.css";
import { ApiLink } from "../Utils/Apilink";
import { CommonSetupActions } from "../../Slices/Commonslice";

const useStyles = {
  customScrollbar: {
    overflowY: "auto",
    height: "581px",
    "&::-webkit-scrollbar": {
      width: "4px",
    },
    "&::-webkit-scrollbar-track": {
      backgroundColor: "#f1f1f1",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#ec1b24",
      borderRadius: "4px",
    },
    "&::-webkit-scrollbar-thumb:hover": {
      backgroundColor: "#d1191f",
    },
    "&::-webkit-scrollbar-button": {
      display: "none",
      width: "0",
      height: "0",
    },
    // scrollbarColor: "#ec1b24 #f1f1f1",
    // scrollbarWidth: "thin",
  },
};

export const Articles = () => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [isTruncated, setIsTruncated] = useState(false);
  const [blob, setBlob] = useState();
  const [searchItem, setSearchItem] = useState("");
  const [debouncedSearchItem, setDebouncedSearchItem] = useState(searchItem);
  const [activeBlog, setActiveBlog] = useState();
  const textRef = useRef(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const controllerRef = useRef(null);
  
useEffect(()=>{
    if (!sessionStorage.getItem("hasRefreshed")) {
      sessionStorage.setItem("hasRefreshed", "true"); // Mark as refreshed
      window.location.reload();
    }
  },[])

  const slugify = (text) => {
    return text
      .toString()
      .toLowerCase()
      .trim()
      .replace(/[\s]+/g, "-") // Replace spaces with -
      .replace(/[^\w\-]+/g, ""); // Remove all non-word chars
  };

  const fetchBlog = (searchitem) => {
    setBlob([]);
    setActiveBlog();

    console.log("&&", searchitem);
    if (controllerRef.current) {
      controllerRef.current.abort();
    }

    controllerRef.current = new AbortController();

    const link2 =
      searchitem || searchItem !== ""
        ? `${ApiLink}articles/ ${searchitem}`
        : ApiLink + "articles";
    fetch(link2, {
      method: "GET",
      headers: {
        "Content-Type": "application/json; charset=utf-8",
      },
      signal: controllerRef.current.signal,
    })
      .then((resp) => {
        return resp.json();
      })
      .then((response) => {
        console.log("responseblog", response);

        if (response.length === 0) {
          setSearchItem("");
        }
        setBlob(response);

        setActiveBlog(response[0]);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  useEffect(() => {
    fetchBlog();
  }, []);

  const searchOptions = [
    { label: "option1", id: 1 },
    { label: "option2", id: 2 },
    { label: "option 3", id: 3 },
    { label: "option4", id: 4 },
    { label: "option5", id: 5 },
  ];

  useEffect(() => {
    const timer = setTimeout(() => {
      setDebouncedSearchItem(searchItem);
    }, 500); // Adjust the timeout value (500ms here) to your preference

    return () => clearTimeout(timer); // Clear the timeout on each change
  }, [searchItem]);

  useEffect(() => {
    if (debouncedSearchItem) {
      fetchBlog(debouncedSearchItem); // Call API after debounced search term is set
    }
  }, [debouncedSearchItem]);

  const handleSearchChange = (e) => {
    console.log("%%%", e.target.value);
    setSearchItem(e.target.value);
  };

  useEffect(() => {
    if (searchItem === "" || searchItem === undefined) {
      fetchBlog();
    }
  }, [searchItem]);

  useEffect(() => {
    if (textRef.current) {
      setIsTruncated(
        textRef.current.scrollHeight > textRef.current.clientHeight
      );
    }
  }, [activeBlog]);
  const handleToggle = () => {
    setIsExpanded(!isExpanded);
  };

  const parseHtml = (content) => {
    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = content;
    const firstP = tempDiv.querySelector("p");
    return firstP.innerHTML;
  };
  const handleImageClick = (postTitle) => {
    dispatch(CommonSetupActions.setActiveArticle(activeBlog));
    // navigate(`/articles/${activeBlog.post_title}`)
    const slug = slugify(postTitle); // Convert the title to a slug
    sessionStorage.clear()
    navigate(`/articles/${slug}`); // Navigate with the slug
  };
  return (
    <LayoutComponent>
      <Box
        className="articles_mainContainer"
        sx={{ position: "relative", width: "100%", height: "auto", padding:'60px', boxSizing:'border-box', paddingBottom:0 }}
      >
        <Box className="articles_mainContainer_sub" sx={{ padding: 0 }}>
          {/* blogs heading and search bar main div */}
          <Box
            className="blog_heading_box"
            sx={{
              // marginTop:"3%",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              // margin
            }}
          >
            {/* blogs heading */}
            <Box>
              <Typography
                className="blog-head-typo"
                sx={{
                  fontFamily: "DM Sans",
                  fontWeight: 800,
                  fontSize: "60px",
                  color: "#000000",
                }}
              >
                Blogs
              </Typography>
            </Box>

            {/* Autocomplete search bar */}

            <Box
              className="auto-articles-search-section"
              sx={{
                width: "350px",
                height: "30px",
                display: "flex",
                alignItems: "center",
                backgroundColor: "#EDEDED",
                borderRadius: "44px",
                padding: "5px 10px",
              }}
            >
              {/* Search Icon */}
              <SearchIcon
                sx={{
                  color: "black",
                  marginRight: "8px",
                  fontSize: "18px",
                  height: "25px",
                  width: "25px",
                }}
              />

              {/* Input Field */}
              <InputBase
                placeholder="Search for anything"
                sx={{
                  fontFamily: "DM Sans",
                  fontWeight: 500,
                  fontSize: "14px",
                  lineHeight: "12px",
                  color: "#646464",
                  width: "100%",
                }}
                value={searchItem}
                onChange={handleSearchChange}
              />
            </Box>
          </Box>

          <hr
            style={{
              marginTop: "1%",
              border: "0.7px solid #D9D9D9",
            }}
          />

          {activeBlog ? (
            <>
              <Box
                className="article_activeblog_main"
                sx={{
                  width: "296px",
                  height: "35px",
                  marginTop: "25px",
                }}
              >
                <Typography
                  className="featured-articles-typo"
                  sx={{
                    fontFamily: "DM Sans",
                    fontWeight: 700,
                    fontSize: "34px",
                    lineHeight: "47px",
                    color: "#0E1415",
                    textDecoration: 'underline',
                    textDecorationColor: 'red',
                    textUnderlineOffset: '15px',
                    textDecorationThickness:'3.5px',
                  }}
                >
                  Featured Articles
                </Typography>
                {/* <hr
                  style={{
                    width: "268px",
                    marginTop: "5px",
                    border: "3px solid #EC1B24",
                    borderRadius: "5px",
                    marginLeft: "0",
                    marginBottom: "10px",
                  }}
                /> */}
              </Box>

              <Box
                className="article_active_blog_container"
                sx={{
                  width: "100%",
                  height: "auto",
                  marginTop: "30px",
                  boxSizing: "border-box",
                  display: "flex",
                  flexDirection: "column",
                  // justifyContent:'center'
                }}
              >
                {/* blog title */}
                {activeBlog && (
                  <Box
                    className="article_activeblog_title"
                    sx={{
                      width: "100%",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      className="article-activeBlog-title-typo"
                      variant="h4"
                      sx={{
                        fontFamily: "DM Sans",
                        fontWeight: 700,
                        fontSize: "27px",
                        lineHeight: "43px",
                        color: "#0E1415",
                      }}
                    >
                      {activeBlog.post_title}
                    </Typography>
                  </Box>
                )}
                {/* selected blog details and other blogs */}
                <Box
                  className="Article_inner_container"
                  sx={{
                    width: "100%",
                    height: "650px",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    marginTop: "10px",
                    padding: "2px",
                    gap: 10,
                  }}
                >
                  {/* active blog */}
                  <Box
                    className="article_activeblog_text_container"
                    sx={{
                      width: "50%",
                      height: "630px",
                      display: "flex",
                      flexDirection: "column",
                      gap: 4,
                    }}
                  >
                    {/* Author and Date */}
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      {/* Date */}
                      {activeBlog && (
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            gap: 1,
                          }}
                        >
                          <Typography
                            sx={{
                              fontFamily: "DM Sans",
                              fontWeight: 700,
                              fontSize: "14px",
                              color: "#EC1B24",
                            }}
                          >
                            Article
                          </Typography>
                          <Box
                            sx={{
                              width: "2px",
                              height: "20px",
                              backgroundColor: "#0E1415",
                            }}
                          />
                          <Typography
                            sx={{
                              fontWeight: 400,
                              fontSize: "14px",
                              color: "#0E1415",
                            }}
                          >
                            {activeBlog.new_date}
                          </Typography>
                        </Box>
                      )}
                    </Box>

                    {/* Blog Image and Date */}
                    {activeBlog && (
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          // justifyContent: "flex-end",
                          // alignItems: "center",
                          height: "100%",
                        }}
                      >
                        <Box
                          sx={{ cursor: "pointer" }}
                          onClick={() =>
                            handleImageClick(activeBlog.post_title)
                          }
                        >
                          <img
                            src={activeBlog.post_image}
                            alt={activeBlog && activeBlog.post_title}
                            style={{
                              width: "100%",
                              height: "auto",
                              // maxHeight: "420px",
                              borderRadius: "12px",
                            }}
                          />
                        </Box>
                        <Typography
                          ref={textRef}
                          sx={{
                            fontWeight: 500,
                            fontSize: "14px",
                            color: "#0B0F11",
                            marginTop: "10px",
                            textAlign: "left",
                            display: "-webkit-box",
                            WebkitLineClamp: isExpanded ? "unset" : 3,
                            WebkitBoxOrient: "vertical",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            wordWrap: "break-word",
                          }}
                        >
                          <div
                            dangerouslySetInnerHTML={{
                              __html: parseHtml(activeBlog.post_content),
                            }}
                          />
                          {/* {parseHtml(activeBlog.post_content)} */}
                        </Typography>
                        <Typography>
                          {isTruncated && !isExpanded && (
                            <span
                              style={{
                                width: "100%",
                                fontWeight: 700,
                                fontSize: "14px",
                                color: "#EF3840",
                                cursor: "pointer",
                                justifyContent: "flex-start",
                                display: "flex",
                                alignItems: "flex-start",
                              }}
                              onClick={handleToggle}
                              tabIndex={0}
                              onKeyDown={(e) => {
                                if (e.key === "Enter" || e.key === " ") {
                                  handleToggle()
                                 }
                              }}
                            >
                              Read More
                            </span>
                          )}
                          {isExpanded && (
                            <span
                              style={{
                                width: "100%",
                                fontWeight: 700,
                                fontSize: "14px",
                                color: "#EF3840",
                                cursor: "pointer",
                                justifyContent: "flex-start",
                                display: "flex",
                              }}
                              onClick={handleToggle}
                              tabIndex={0}
                              onKeyDown={(e) => {
                                if (e.key === "Enter" || e.key === " ") {
                                  handleToggle()
                                 }
                              }}
                            >
                              Show Less
                            </span>
                          )}
                        </Typography>
                      </Box>
                    )}
                  </Box>

                  {/* other blogs */}
                  <Box
                    className="article_other_blog"
                    sx={{
                      ...useStyles.customScrollbar,
                      width: "50%",
                      height: "630px",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "flex-start",
                      alignItems: "flex-start",
                      gap:1
                      //   overflowY: "auto",
                    }}
                  >
                    {blob &&
                      blob.length > 0 &&
                      blob.map((blog, index) => {
                        return (
                          <Box
                            className="article_other_blog1_section"
                            key={index}
                            sx={{
                              width: "100%",
                              height: "auto",
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "flex-start",
                              alignItems: "center",
                              gap: 4,
                              marginBottom: "20px",
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              setActiveBlog(blog);
                              setIsExpanded(false);
                              setIsTruncated(false);
                            }}
                            tabIndex={0}
                              onKeyDown={(e) => {
                                if (e.key === "Enter" || e.key === " ") {
                                  setActiveBlog(blog);
                                  setIsExpanded(false);
                                  setIsTruncated(false);                                 }
                              }}
                          >
                            {/* Image */}
                            <Box
                              className="Article_blog_list_image_Container"
                              sx={{
                                width: "231px",
                                height: "171px",
                                borderRadius: "10px",
                                overflow: "hidden",
                                flexShrink: 0,
                              }}
                            >
                              <img
                                src={blog.post_image}
                                alt={blog && blog.post_title}                 
                               style={{
                                  width: "100%",
                                  height: "100%",
                                  objectFit: "cover",
                                  borderRadius: "10px",
                                }}
                              />
                            </Box>

                            {/* Title and Description */}
                            <Box
                              className="Article_blog_list_image_Container"
                              sx={{
                                width: "calc(100% - 140px)",
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "flex-start",
                                alignItems: "flex-start",
                              }}
                            >
                              <Typography
                                className="article-blog-list-title-typo"
                                sx={{
                                  fontWeight: 700,
                                  fontSize: "18px",
                                  fontFamily: "DM Sans",
                                  lineHeight: "27px",
                                  color: "#0E1415",
                                  whiteSpace: "normal",
                                  overflowWrap: "break-word",
                                  wordBreak: "break-word",
                                }}
                              >
                                {blog.post_title}
                              </Typography>
                              <Typography
                                sx={{
                                  fontFamily: "DM Sans",
                                  fontSize: "14px",
                                  fontWeight: 500,
                                  lineHeight: "22px",
                                  color: "#0B0F11",
                                  // marginTop: "10px",
                                  maxHeight: "44px",
                                  overflow: "hidden",
                                  display: "-webkit-box",
                                  WebkitLineClamp: 2,
                                  WebkitBoxOrient: "vertical",
                                  textOverflow: "ellipsis",
                                }}
                              >
                                {parseHtml(blog.post_content)}
                              </Typography>

                              {/* Blog Metadata */}


                              <Box
                                sx={{
                                  display: "flex",
                                  justifyContent: "flex-start",
                                  alignItems: "center",
                                  gap: 1.5,
                                  marginTop: "10px",
                                  width: "100%",
                                }}
                              >
                              </Box>
                              </Box>
                          </Box>
                        );
                      })}
                  </Box>
                </Box>
              </Box>
            </>
          ) : (
            <Box
              sx={{
                width: "100%",
                height: "600px",
                justifyContent: "center",
                alignItems: "center",
                display: "flex",
              }}
            >
              <CircularProgress
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              />
            </Box>
          )}
        </Box>
      </Box>

      <CommonFooter />
    </LayoutComponent>
  );
};
