import React from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import routes from "./routes.json";
import AppIndex from "./AppIndex";
import HomePage from "../Pages/Home/HomePage";
import AboutPwr from "../Pages/AboutUs/AboutPwr";
import { WarrantyStatement } from "../Pages/Warranty/WarrantyStatement";
import Ecatlog from "../Pages/ProductPages/Ecatlog";
import { Articles } from "../Pages/Articles/Articles";
import { GearBox } from "../Pages/ProductPages/ProductDisplay/GearBox";
import AticleDetailed from "../Pages/Articles/AticleDetailed";
import { RackPinion } from "../Pages/ProductPages/RackPinion";
import { SteeringPump } from "../Pages/ProductPages/SteeringPump";
import { SteeringGearBox } from "../Pages/ProductPages/SteeringGearBox";
import { Videos } from "../Pages/Videos/Videos";
import SiteMap from "../Pages/Footer/SiteMap";
import PrivacyPolicy from "../Pages/Footer/PrivacyPolicy";
import TermsOfService from "../Pages/Footer/TermsOfService";
import { Accessibility } from "../Pages/Footer/Accessibility";
import { Reservoirs } from "../Pages/ProductPages/Reservoirs";

const appRoutes = createBrowserRouter([
  {
    path: routes.AppIndex,
    element: <AppIndex />,
    children: [
      {
        path: routes.Home,
        element: <HomePage/>,
      },
     
    ],

  },
  {
    path: routes.About,
    element: <AboutPwr/>,
  },
  {
    path:routes.Warranty,
    element:<WarrantyStatement />
  },
  {
    path:routes.Ecatlog,
    element:<Ecatlog/>
  },
  {
    path:routes.Articles,
    element:<Articles />
  },
  {
    path:routes.GearBox,
    element:<GearBox />
  },
  {
    path:routes.GearBox1,
    element:<GearBox />
  },
  {
    path:routes.DetailedArticle,
    element:<AticleDetailed/>
  },

  {
    path:routes.RackPinion,
    element:<RackPinion />
  },
  {
    path:routes.SteeringPump,
    element:<SteeringPump />
  },
  {
    path:routes.SteeringGearBox,
    element:<SteeringGearBox />
  },
  {
    path:routes.Reservoirs,
    element:<Reservoirs />
  },
  {
    path:routes.Videos,
    element:<Videos />
  },
  {
    path:routes.SiteMap,
    element:<SiteMap/>
  },
  {
    path:routes.PrivacyPolicy,
    element:<PrivacyPolicy/>
  },
  {
    path:routes.TermsOfService,
    element:<TermsOfService/>
  },
  {
    path:routes.Accessibility,
    element:<Accessibility />
  },
  {
    path: "*",
    element: <div>no page found</div>,
  },
]);

const AppRoutes = () => {
  return  <>
  <RouterProvider router={appRoutes} />
</>
};

export default AppRoutes;
