import React, { useEffect } from "react";
import CommonFooter from "./CommonFooter";
import LayoutComponent from "../../Layout/LayoutComponent";
import { Box, Typography } from "@mui/material";

const TermsOfService = () => {

     useEffect(()=>{
          if (!sessionStorage.getItem("hasRefreshed")) {
            sessionStorage.setItem("hasRefreshed", "true"); 
            window.location.reload();
          }
        },[])
  return (
    <LayoutComponent>
      <Box
        sx={{
          width: "100%",
          height: "auto",
          boxSizing: "border-box",
          display: "flex",
          flexDirection: "column",
          fontFamily: "DM Sans",
        }}
      >
        <Box
          className="main-war-state-box"
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            boxSizing: "border-box",
            margin: "70px",
            flexWrap: "wrap",
          }}
        >
          {/* warranty statement heading */}
          <Box
            className="warr-head-box"
            sx={{
              height: "42px",
               marginTop:"4%"
            }}
          >
            <Typography
              className="head-typo-access"
              sx={{
                fontFamily: "DM Sans",
                fontSize: "60px",
                fontWeight: 800,
                lineHeight: "27px",
                color: "#000000",
              }}
            >
              Terms Of Service
            </Typography>
            <hr
              className="war-hr-line"
              style={{
                width: "100%",
                marginTop: "40px",
              }}
            />
          </Box>

          <Box className="privacy-content-main" sx={{ width: "100%", marginTop: 5 }}>
            <Box>
              <Typography
                className="state-head-typo"
                sx={{
                  width: "100%",
                  height: "auto",
                  fontFamily: "DM Sans",
                  fontWeight: 600,
                  fontSize: "25px",
                  lineHeight: "27px",
                  color: "#55120B",
                  padding:"10px 0px"
                }}
              >
                OVERVIEW
              </Typography>
            </Box>
            <Box>
              <Typography
                className="content-first-second-row-typo"
                sx={{
                  fontFamily: "DM Sans",
                  fontWeight: 400,
                  fontSize: "18px",

                  lineHeight: "30px",
                  color: "#000000",
                }}
              >
                This website is operated by Premium Guard Inc. (PGI). Throughout
                the site, the terms “we”, “us” and “our” refer to PGI. PGI
                offers this website, including all information, tools, and
                services available from this site to you, the user, conditioned
                upon your acceptance of all terms, conditions, policies, and
                notices stated here.
              </Typography>
              <Typography
                className="content-first-second-row-typo"
                sx={{
                  fontFamily: "DM Sans",
                  fontWeight: 400,
                  fontSize: "18px",

                  lineHeight: "30px",
                  color: "#000000",
                }}
              >
                By visiting our site, using our subscription service, and/or
                purchasing something from us, you engage in our services
                (collectively, the “Services”) and agree to be bound by the
                following terms and conditions (referred to herein as “Terms of
                Service” or “Terms”), including those additional terms and
                conditions and policies incorporated by reference herein,
                including but not limited to via hyperlink. These Terms of
                Service apply to all users of the site, including without
                limitation users who are browsers, vendors, customers,
                merchants, and/or contributors of content.
              </Typography>
              <Typography
                className="content-first-second-row-typo"
                sx={{
                  fontFamily: "DM Sans",
                  fontWeight: 400,
                  fontSize: "18px",
                  lineHeight: "30px",
                  color: "#000000",
                }}
              >
                Please read these Terms of Service carefully before accessing or
                using our website. By accessing or using any part of the site,
                you agree to be bound by these Terms of Service. If you do not
                agree to all the terms and conditions of this agreement, then
                you may not access the website or use any Services. If these
                Terms of Service are considered an offer, acceptance is
                expressly limited to the acceptance of these Terms of Service.
              </Typography>
              <Typography
                className="content-first-second-row-typo"
                sx={{
                  fontFamily: "DM Sans",
                  fontWeight: 400,
                  fontSize: "18px",
                  lineHeight: "30px",
                  color: "#000000",
                }}
              >
                PGI may modify, revise, and/or add or remove features of,
                services of, and/or items for sale via the Services at its sole
                discretion, without notice to you.  Any such additions,
                revisions, removals, and/or modifications shall also be subject
                to the Terms of Service.
              </Typography>
            </Box>
            <Box marginTop={2}>
              <Typography
                className="state-head-typo"
                sx={{
                  width: "100%",
                  height: "auto",
                  fontFamily: "DM Sans",
                  fontWeight: 600,
                  fontSize: "25px",
                  lineHeight: "27px",
                  color: "#55120B",
                  padding:"10px 0px"
                }}
              >
                SECTION 1 - ONLINE STORE TERMS
              </Typography>
            </Box>
            <Box>
              <Typography
                className="content-first-second-row-typo"
                sx={{
                  fontFamily: "DM Sans",
                  fontWeight: 400,
                  fontSize: "18px",

                  lineHeight: "30px",
                  color: "#000000",
                }}
              >
                By agreeing to these Terms of Service, you represent that you
                are at least the age of majority in your state or province of
                residence, or that you are the age of majority in your state or
                province of residence and you have given us your consent to
                allow any of your minor dependents to use this site.
              </Typography>
              <Typography
                className="content-first-second-row-typo"
                sx={{
                  fontFamily: "DM Sans",
                  fontWeight: 400,
                  fontSize: "18px",

                  lineHeight: "30px",
                  color: "#000000",
                }}
              >
                {" "}
                You may not use our products for any illegal or unauthorized
                purpose nor may you, in the use of the Service, violate any laws
                in your jurisdiction (including but not limited to copyright
                laws). 
              </Typography>
              <Typography
                className="content-first-second-row-typo"
                sx={{
                  fontFamily: "DM Sans",
                  fontWeight: 400,
                  fontSize: "18px",

                  lineHeight: "30px",
                  color: "#000000",
                }}
              >
                You must not transmit any worms or viruses or any code of a
                destructive nature.
              </Typography>
              <Typography 
              className="content-first-second-row-typo"
              sx={{
                  fontFamily: "DM Sans",
                  fontWeight: 400,
                  fontSize: "18px",
                  lineHeight: "30px",
                  color: "#000000",
                }}>
                Should you breach any condition of these Terms, PGI may
                immediately terminate the provision of any Services to you, and
                any of your open orders, at its sole discretion.
              </Typography>
            </Box>

            <Box marginTop={2}>
              <Typography
                className="state-head-typo"
                sx={{
                  width: "100%",
                  height: "auto",
                  fontFamily: "DM Sans",
                  fontWeight: 600,
                  fontSize: "25px",
                  lineHeight: "27px",
                  color: "#55120B",
                  padding:"10px 0px"
                }}
              >
                SECTION 2 - GENERAL CONDITIONS
              </Typography>
            </Box>
            <Box>
              <Typography
                className="content-first-second-row-typo"
                sx={{
                  fontFamily: "DM Sans",
                  fontWeight: 400,
                  fontSize: "18px",

                  lineHeight: "30px",
                  color: "#000000",
                }}
              >
                We reserve the right to refuse service to anyone for any reason
                at any time. You understand that your content (not including
                credit card information), may be transferred unencrypted and
                involve (a) transmissions over various networks; and (b) changes
                to conform and adapt to technical requirements of connecting
                networks or devices. Credit card information is always encrypted
                during transfer over networks. 
              </Typography>

              <Typography
                className="content-first-second-row-typo"
                sx={{
                  fontFamily: "DM Sans",
                  fontWeight: 400,
                  fontSize: "18px",

                  lineHeight: "30px",
                  color: "#000000",
                }}
              >
                You agree not to reproduce, duplicate, copy, sell, resell or
                exploit any portion of the Service, use of the Service, or
                access to the Service or any contact on the website through
                which the Service is provided, without express written
                permission by us. 
              </Typography>
              <Typography
                className="content-first-second-row-typo"
                sx={{
                  fontFamily: "DM Sans",
                  fontWeight: 400,
                  fontSize: "18px",

                  lineHeight: "30px",
                  color: "#000000",
                }}
              >
                PGI may, at its sole discretion, terminate or suspend your
                access to the Services at any time without notice, if it
                determines in its sole discretion that you are in violation of
                any of these Terms.  All amounts due will immediately
                accelerate, and you will remain liable for all amounts due up to
                and including the date of termination or suspension.
              </Typography>
            </Box>
            <Box marginTop={2}>
              <Typography
                className="state-head-typo"
                sx={{
                  width: "100%",
                  height: "auto",
                  fontFamily: "DM Sans",
                  fontWeight: 600,
                  fontSize: "25px",
                  lineHeight: "27px",
                  color: "#55120B",
                  padding:"10px 0px"
                }}
              >
                SECTION 3 - ACCURACY, COMPLETENESS, AND TIMELINESS OF
                INFORMATION
              </Typography>
            </Box>
            <Box>
              <Typography
                className="content-first-second-row-typo"
                sx={{
                  fontFamily: "DM Sans",
                  fontWeight: 400,
                  fontSize: "18px",
                  lineHeight: "30px",
                  color: "#000000",
                }}
              >
                We are not responsible if information made available on this
                site is not accurate, complete, or current. The material on this
                site is provided for general information only and should not be
                relied upon or used as the sole basis for making decisions
                without consulting primary, more accurate, more complete or more
                timely sources of information. The material provided herein is
                “as-is” without warranty of any kind, and any reliance on the
                material on this site is at your own risk.
              </Typography>
              <Typography
                className="content-first-second-row-typo"
                sx={{
                  fontFamily: "DM Sans",
                  fontWeight: 400,
                  fontSize: "18px",
                  lineHeight: "30px",
                  color: "#000000",
                }}
              >
                This site may contain certain historical information. Historical
                information, necessarily, is not current and is provided for
                your reference only. We reserve the right to modify the contents
                of this site at any time, but we have no obligation to update
                any information on our site. You agree that it is your
                responsibility to monitor changes to our site.
              </Typography>
            </Box>
            <Box marginTop={2}>
              <Typography
                className="state-head-typo"
                sx={{
                  width: "100%",
                  height: "auto",
                  fontFamily: "DM Sans",
                  fontWeight: 600,
                  fontSize: "25px",
                  lineHeight: "27px",
                  color: "#55120B",
                  padding:"10px 0px"
                }}
              >
                SECTION 4 - MODIFICATIONS TO THE SERVICE AND PRICES
              </Typography>
            </Box>
            <Box>
              <Typography
                className="content-first-second-row-typo"
                sx={{
                  fontFamily: "DM Sans",
                  fontWeight: 400,
                  fontSize: "18px",
                  lineHeight: "30px",
                  color: "#000000",
                }}
              >
                Prices for our products and Services are subject to change
                without notice.  We reserve the right at any time to modify or
                discontinue the Service (or any part or content thereof) without
                notice at any time.  We shall not be liable to you or to any
                third-party for any modification, price change, suspension or
                discontinuance of the Service.
              </Typography>
            </Box>
            <Box marginTop={2}>
              <Typography
                className="state-head-typo"
                sx={{
                  width: "100%",
                  height: "auto",
                  fontFamily: "DM Sans",
                  fontWeight: 600,
                  fontSize: "25px",
                  lineHeight: "27px",
                  color: "#55120B",
                  padding:"10px 0px"
                }}
              >
                SECTION 5 - PRODUCTS AND SERVICES
              </Typography>
            </Box>
            <Box>
              <Typography
                className="content-first-second-row-typo"
                sx={{
                  fontFamily: "DM Sans",
                  fontWeight: 400,
                  fontSize: "18px",
                  lineHeight: "30px",
                  color: "#000000",
                }}
              >
                Products or Services may have limited quantities.  Products may
                be returned only with a supplied RMA#.  All sales are final
                thirty (30) days after order.  All returns and exchanges are
                subject to our Refund Policy, which is hereby incorporated by
                reference and can be found (Link to our Return Policy)
              </Typography>
              <Typography
                className="content-first-second-row-typo"
                sx={{
                  fontFamily: "DM Sans",
                  fontWeight: 400,
                  fontSize: "18px",
                  lineHeight: "30px",
                  color: "#000000",
                }}
              >
                We have made every effort to display as accurately as possible
                the colors and images of our products that appear at the store.
                We cannot guarantee that any computer monitor's display of any
                color will be accurate. 
              </Typography>
              <Typography
                className="content-first-second-row-typo"
                sx={{
                  fontFamily: "DM Sans",
                  fontWeight: 400,
                  fontSize: "18px",
                lineHeight: "30px",
                  color: "#000000",
                }}
              >
                We reserve the right but are not obligated, to limit the sales
                of our products or Services to any person, geographic region, or
                jurisdiction. We may exercise this right on a case-by-case
                basis. We reserve the right to limit the quantities of any
                products or Services that we offer. All descriptions of products
                or product pricing are subject to change at any time without
                notice, at the sole discretion of us. We reserve the right to
                discontinue any product at any time. Any offer for any product
                or Service made on this site is void where prohibited.
              </Typography>
              <Typography
                className="content-first-second-row-typo"
                sx={{
                  fontFamily: "DM Sans",
                  fontWeight: 400,
                  fontSize: "18px",
                  lineHeight: "30px",
                  color: "#000000",
                }}
              >
                Subscription Terms.  PGI offers subscription service whereby you
                agree to purchase and receive deliveries of selected products at
                the duration of your choice.  You may cancel the Subscription
                Service with not less than thirty (30) days advanced notice via
                your account page.
              </Typography>
            </Box>
            <Box marginTop={2}>
              <Typography
                className="state-head-typo"
                sx={{
                  width: "100%",
                  height: "auto",
                  fontFamily: "DM Sans",
                  fontWeight: 600,
                  fontSize: "25px",
                  lineHeight: "27px",
                  color: "#55120B",
                  padding:"10px 0px"
                }}
              >
                SECTION 6 - ACCURACY OF BILLING AND ACCOUNT INFORMATION
              </Typography>
            </Box>
            <Box>
              <Typography
                className="content-first-second-row-typo"
                sx={{
                  fontFamily: "DM Sans",
                  fontWeight: 400,
                  fontSize: "18px",
                  lineHeight: "30px",
                  color: "#000000",
                }}
              >
                We reserve the right to refuse any order you place with us. We
                may, in our sole discretion, limit or cancel quantities
                purchased per person, per household or per order. These
                restrictions may include orders placed by or under the same
                customer account, the same credit card, and/or orders that use
                the same billing and/or shipping address. In the event that we
                make a change to or cancel an order, we may attempt to notify
                you by contacting the e-mail and/or billing address/phone number
                provided at the time the order was made. We reserve the right to
                limit or prohibit orders that, in our sole judgment, appear to
                be placed by dealers, resellers, or distributors.
              </Typography>
              <Typography
                className="content-first-second-row-typo"
                sx={{
                  fontFamily: "DM Sans",
                  fontWeight: 400,
                  fontSize: "18px",
                  lineHeight: "30px",
                  color: "#000000",
                }}
              >
                You agree to provide current, complete, and accurate purchase
                and account information for all purchases made at our store. You
                agree to promptly update your account and other information,
                including your email address and credit card numbers and
                expiration dates so that we can complete your transactions and
                contact you as needed. For more detail, please review our
                Returns Policy.
              </Typography>
            </Box>
            <Box marginTop={2}>
              <Typography
                className="state-head-typo"
                sx={{
                  width: "100%",
                  height: "auto",
                  fontFamily: "DM Sans",
                  fontWeight: 600,
                  fontSize: "25px",
                  lineHeight: "27px",
                  color: "#55120B",
                  padding:"10px 0px"
                }}
              >
                SECTION 7 - OPTIONAL TOOLS
              </Typography>
            </Box>
            <Box>
              <Typography
                className="content-first-second-row-typo"
                sx={{
                  fontFamily: "DM Sans",
                  fontWeight: 400,
                  fontSize: "18px",
                  lineHeight: "30px",
                  color: "#000000",
                }}
              >
                We may provide you with access to third-party tools over which
                we neither monitor nor have any control nor input.  You
                acknowledge and agree that we provide access to such tools ”as
                is” and “as available” without any warranties, representations
                or conditions of any kind and without any endorsement. We shall
                have no liability whatsoever arising from or relating to your
                use of optional third-party tools. 
              </Typography>
              <Typography
                className="content-first-second-row-typo"
                sx={{
                  fontFamily: "DM Sans",
                  fontWeight: 400,
                  fontSize: "18px",
                  lineHeight: "30px",
                  color: "#000000",
                }}
              >
                Any use by you of optional tools offered through the site is
                entirely at your own risk and discretion and you should ensure
                that you are familiar with and approve of the terms on which
                tools are provided by the relevant third-party provider(s). 
              </Typography>
              <Typography
                className="content-first-second-row-typo"
                sx={{
                  fontFamily: "DM Sans",
                  fontWeight: 400,
                  fontSize: "18px",
                  lineHeight: "30px",
                  color: "#000000",
                }}
              >
                We may also, in the future, offer new services and/or features
                through the website (including, the release of new tools and
                resources). Such new features and/or services shall also be
                subject to these Terms of Service.
              </Typography>
            </Box>

            <Box marginTop={2}>
              <Typography
                className="state-head-typo"
                sx={{
                  width: "100%",
                  height: "auto",
                  fontFamily: "DM Sans",
                  fontWeight: 600,
                  fontSize: "25px",
                  lineHeight: "27px",
                  color: "#55120B",
                  padding:"10px 0px"
                }}
              >
                SECTION 8 - THIRD-PARTY LINKS
              </Typography>
            </Box>
            <Box>
              <Typography
                className="content-first-second-row-typo"
                sx={{
                  fontFamily: "DM Sans",
                  fontWeight: 400,
                  fontSize: "18px",

                  lineHeight: "30px",
                  color: "#000000",
                }}
              >
                Certain content, products, and services available via our
                Service may include materials from third-parties. Third-party
                links on this site may direct you to third-party websites that
                are not affiliated with us. We are not responsible for examining
                or evaluating the content or accuracy and we do not warrant and
                will not have any liability or responsibility for any
                third-party materials or websites, or for any other materials,
                products, or services of third-parties. 
              </Typography>
              <Typography
                className="content-first-second-row-typo"
                sx={{
                  fontFamily: "DM Sans",
                  fontWeight: 400,
                  fontSize: "18px",

                  lineHeight: "30px",
                  color: "#000000",
                }}
              >
                We are not liable for any harm or damages related to the
                purchase or use of goods, services, resources, content, or any
                other transactions made in connection with any third-party
                websites.
              </Typography>
              <Typography
                className="content-first-second-row-typo"
                sx={{
                  fontFamily: "DM Sans",
                  fontWeight: 400,
                  fontSize: "18px",

                  lineHeight: "30px",
                  color: "#000000",
                }}
              >
                Please review carefully the third-party's policies and practices
                and make sure you understand them before you engage in any
                transaction. Complaints, claims, concerns, or questions
                regarding third-party products should be directed to the
                third-party.
              </Typography>
              <Box marginTop={2}>
                <Typography
                  className="state-head-typo"
                  sx={{
                    width: "100%",
                    height: "auto",
                    fontFamily: "DM Sans",
                    fontWeight: 600,
                    fontSize: "25px",
                    lineHeight: "27px",
                    color: "#55120B",
                    padding:"10px 0px"
                  }}
                >
                  {" "}
                  SECTION 9 - USER COMMENTS, FEEDBACK, AND OTHER SUBMISSIONS
                </Typography>
              </Box>
              <Box>
                <Typography
                  className="content-first-second-row-typo"
                  sx={{
                    fontFamily: "DM Sans",
                    fontWeight: 400,
                    fontSize: "18px",

                    lineHeight: "30px",
                    color: "#000000",
                  }}
                >
                  If, at our request, you send certain specific submissions (for
                  example contest entries) or without a request from us you send
                  comments, creative ideas, suggestions, proposals, plans, or
                  other materials, whether online, by email, by postal mail, or
                  otherwise (collectively, “Comments”), you agree that we may,
                  at any time, without restriction, edit, copy, publish,
                  distribute, translate and otherwise use in any medium any
                  Comments that you send or otherwise make available to us. We
                  are and shall be under no obligation (1) to maintain any
                  Comments in confidence; (2) to pay compensation for any
                  Comments; or (3) to respond to any Comments. 
                </Typography>
                <Typography
                  className="content-first-second-row-typo"
                  sx={{
                    fontFamily: "DM Sans",
                    fontWeight: 400,
                    fontSize: "18px",

                    lineHeight: "30px",
                    color: "#000000",
                  }}
                >
                  We may, but have no obligation to, monitor, edit or remove
                  content that we determine in our sole discretion are unlawful,
                  offensive, threatening, libelous, defamatory, pornographic,
                  obscene or otherwise objectionable or violates any party’s
                  intellectual property or these Terms of Service. 
                </Typography>
                <Typography
                  className="content-first-second-row-typo"
                  sx={{
                    fontFamily: "DM Sans",
                    fontWeight: 400,
                    fontSize: "18px",

                    lineHeight: "30px",
                    color: "#000000",
                  }}
                >
                  You agree that your Comments will not violate any right of any
                  third-party, including copyright, trademark, privacy,
                  personality or other personal or proprietary right. You
                  further agree that your Comments will not contain libelous or
                  otherwise unlawful, abusive or obscene material, or contain
                  any computer virus or other malware that could in any way
                  affect the operation of the Service or any related website.
                  You may not use a false e-mail address, pretend to be someone
                  other than yourself, or otherwise mislead us or third-parties
                  as to the origin of any Comments. You are solely responsible
                  for any Comments you make and their accuracy. We take no
                  responsibility and assume no liability for any Comments posted
                  by you or any third-party and have no obligation to verify the
                  accuracy thereof.
                </Typography>
              </Box>
              <Box marginTop={2}>
                <Typography
                  className="state-head-typo"
                  sx={{
                    width: "100%",
                    height: "auto",
                    fontFamily: "DM Sans",
                    fontWeight: 600,
                    fontSize: "25px",
                    lineHeight: "27px",
                    color: "#55120B",
                    padding:"10px 0px"
                  }}
                >
                  SECTION 10 - PERSONAL INFORMATION
                </Typography>
              </Box>
              <Box>
                <Typography>
                  Your submission of personal information through the store is
                  governed by our Privacy Policy, which is hereby incorporated
                  by reference herein. Our Privacy Policy can be viewed at the
                  following link: PRIVACY POLICY
                </Typography>
              </Box>

              <Box marginTop={2}>
                <Typography
                  className="state-head-typo"
                  sx={{
                    width: "100%",
                    height: "auto",
                    fontFamily: "DM Sans",
                    fontWeight: 600,
                    fontSize: "25px",
                    lineHeight: "27px",
                    color: "#55120B",
                    padding:"10px 0px"
                  }}
                >
                  SECTION 11 - ERRORS, INACCURACIES, AND OMISSIONS
                </Typography>
              </Box>
              <Box>
                <Typography
                  className="content-first-second-row-typo"
                  sx={{
                    fontFamily: "DM Sans",
                    fontWeight: 400,
                    fontSize: "18px",

                    lineHeight: "30px",
                    color: "#000000",
                  }}
                >
                  Occasionally there may be information on our site or in the
                  Service that contains typographical errors, inaccuracies, or
                  omissions that may relate to product descriptions, pricing,
                  promotions, offers, product shipping charges, transit times,
                  and availability. We reserve the right to correct any errors,
                  inaccuracies or omissions, and to change or update information
                  or cancel orders if any information in the Service or on any
                  related website is inaccurate at any time without prior notice
                  (including after you have submitted your order). 
                </Typography>
                <Typography
                  className="content-first-second-row-typo"
                  sx={{
                    fontFamily: "DM Sans",
                    fontWeight: 400,
                    fontSize: "18px",

                    lineHeight: "30px",
                    color: "#000000",
                  }}
                >
                  We undertake no obligation to update, amend, or clarify
                  information in the Service or on any related website,
                  including without limitation, pricing information, except as
                  required by law. No specified update or refresh date applied
                  in the Service or on any related website should be taken to
                  indicate that all information in the Service or on any related
                  website has been modified or updated.
                </Typography>
              </Box>
              <Box marginTop={2}>
                <Typography
                  className="state-head-typo"
                  sx={{
                    width: "100%",
                    height: "auto",
                    fontFamily: "DM Sans",
                    fontWeight: 600,
                    fontSize: "25px",
                    lineHeight: "27px",
                    color: "#55120B",
                    padding:"10px 0px"
                  }}
                >
                  SECTION 12 - PROHIBITED USES
                </Typography>
              </Box>
              <Box>
                <Typography
                  className="content-first-second-row-typo"
                  sx={{
                    fontFamily: "DM Sans",
                    fontWeight: 400,
                    fontSize: "18px",

                    lineHeight: "30px",
                    color: "#000000",
                  }}
                >
                  In addition to other prohibitions as set forth in the Terms of
                  Service, you are prohibited from using the site or its
                  content: (a) for any unlawful purpose; (b) to solicit others
                  to perform or participate in any unlawful acts; (c) to violate
                  any international, federal, provincial or state regulations,
                  rules, laws, or local ordinances; (d) to infringe upon or
                  violate our intellectual property rights or the intellectual
                  property rights of others; (e) to harass, abuse, insult, harm,
                  defame, slander, disparage, intimidate, or discriminate based
                  on gender, sexual orientation, religion, ethnicity, race, age,
                  national origin, or disability; (f) to submit false or
                  misleading information; (g) to upload or transmit viruses or
                  any other type of malicious code that will or may be used in
                  any way that will affect the functionality or operation of the
                  Service or of any related website, other websites, or the
                  Internet; (h) to collect or track the personal information of
                  others; (i) to spam, phish, pharm, pretext, spider, crawl, or
                  scrape; (j) for any obscene or immoral purpose; or (k) to
                  interfere with or circumvent the security features of the
                  Service or any related website, other websites, or the
                  Internet. We reserve the right to terminate your use of the
                  Service or any related website for violating any of the
                  prohibited uses.  You shall not resell any of the products or
                  use the Services for any commercial, non-personal purpose.
                </Typography>
              </Box>
              <Box marginTop={2}>
                <Typography
                  className="state-head-typo"
                  sx={{
                    width: "100%",
                    height: "auto",
                    fontFamily: "DM Sans",
                    fontWeight: 600,
                    fontSize: "25px",
                    lineHeight: "27px",
                    color: "#55120B",
                    padding:"10px 0px"
                  }}

                >
                  SECTION 13 - DISCLAIMER OF WARRANTIES; LIMITATION OF LIABILITY
                </Typography>
              </Box>
              <Box>
                <Typography
                  className="content-first-second-row-typo"
                  sx={{
                    fontFamily: "DM Sans",
                    fontWeight: 400,
                    fontSize: "18px",

                    lineHeight: "30px",
                    color: "#000000",
                  }}
                >
                  We do not guarantee, represent, or warrant that your use of
                  our Service will be uninterrupted, timely, secure, or
                  error-free.  We do not warrant that the results that may be
                  obtained from the use of the Service will be accurate or
                  reliable. 
                </Typography>

                <Typography
                  className="content-first-second-row-typo"
                  sx={{
                    fontFamily: "DM Sans",
                    fontWeight: 400,
                    fontSize: "18px",

                    lineHeight: "30px",
                    color: "#000000",
                  }}
                >
                  You agree that from time to time we may remove the Service for
                  indefinite periods of time or cancel the Service at any time,
                  without notice to you. 
                </Typography>
                <Typography
                  className="content-first-second-row-typo"
                  sx={{
                    fontFamily: "DM Sans",
                    fontWeight: 400,
                    fontSize: "18px",

                    lineHeight: "30px",
                    color: "#000000",
                  }}
                >
                  You expressly agree that your use of, or inability to use, the
                  Service is at your sole risk. The Service and all products and
                  Services delivered to you through the Service are (except as
                  expressly stated by us) provided 'as is' and 'as available'
                  for your use, without any representation, warranties or
                  conditions of any kind, either express or implied, including
                  all implied warranties or conditions of merchantability,
                  merchantable quality, fitness for a particular purpose,
                  durability, title, and non-infringement. 
                </Typography>
                <Typography
                  className="content-first-second-row-typo"
                  sx={{
                    fontFamily: "DM Sans",
                    fontWeight: 400,
                    fontSize: "18px",

                    lineHeight: "30px",
                    color: "#000000",
                  }}
                >
                  In no case shall PGI, our directors, officers, employees,
                  affiliates, agents, contractors, interns, suppliers, service
                  providers or licensors be liable for any injury, loss, claim,
                  or any direct, indirect, incidental, punitive, special, or
                  consequential damages of any kind, including, without
                  limitation lost profits, lost revenue, lost savings, loss of
                  data, replacement costs, or any similar damages, whether based
                  in contract, tort (including negligence), strict liability or
                  otherwise, arising from your use of any of the Services or any
                  products procured using the Services, or for any other claim
                  related in any way to your use of the Services or any product,
                  including, but not limited to, any errors or omissions in any
                  content, or any loss or damage of any kind incurred as a
                  result of the use of the Services or any content (or product)
                  posted, transmitted, or otherwise made available via the
                  Services, even if advised of their possibility. Because some
                  states or jurisdictions do not allow the exclusion or the
                  limitation of liability for consequential or incidental
                  damages, in such states or jurisdictions, our liability shall
                  be limited to the maximum extent permitted by law.
                </Typography>
                <Typography
                  className="content-first-second-row-typo"
                  sx={{
                    fontFamily: "DM Sans",
                    fontWeight: 400,
                    fontSize: "18px",

                    lineHeight: "30px",
                    color: "#000000",
                  }}
                >
                  PGI, its directors, directors, officers, employees,
                  affiliates, agents, contractors, interns, suppliers, service
                  providers, and licensors total aggregate liability arising
                  from or related to the Services shall be limited to all
                  amounts actually received by PGI from you in the six (6) month
                  period preceding the event giving rise to the liability.
                </Typography>
              </Box>
              <Box marginTop={2}>
                <Typography
                  className="state-head-typo"
                  sx={{
                    width: "100%",
                    height: "auto",
                    fontFamily: "DM Sans",
                    fontWeight: 600,
                    fontSize: "25px",
                    lineHeight: "27px",
                    color: "#55120B",
                    padding:"10px 0px"
                  }}
                >
                  SECTION 14 - INDEMNIFICATION
                </Typography>
              </Box>
              <Box>
                <Typography
                  className="content-first-second-row-typo"
                  sx={{
                    fontFamily: "DM Sans",
                    fontWeight: 400,
                    fontSize: "18px",

                    lineHeight: "30px",
                    color: "#000000",
                  }}
                >
                  You agree to indemnify, defend and hold harmless PGI and our
                  parent, subsidiaries, affiliates, partners, officers,
                  directors, agents, contractors, licensors, service providers,
                  subcontractors, suppliers, interns and employees, harmless
                  from any claim or demand, including reasonable attorneys’
                  fees, made by any third party due to or arising out of your
                  breach of these Terms of Service or the documents they
                  incorporate by reference or your violation of any law or the
                  rights of a third-party.
                </Typography>
              </Box>

              <Box marginTop={2}>
                <Typography
                  className="state-head-typo"
                  sx={{
                    width: "100%",
                    height: "auto",
                    fontFamily: "DM Sans",
                    fontWeight: 600,
                    fontSize: "25px",
                    lineHeight: "27px",
                    color: "#55120B",
                    padding:"10px 0px"
                  }}
                >
                  SECTION 15 - SEVERABILITY
                </Typography>
              </Box>
              <Box>
                <Typography
                  className="content-first-second-row-typo"
                  sx={{
                    fontFamily: "DM Sans",
                    fontWeight: 400,
                    fontSize: "18px",

                    lineHeight: "30px",
                    color: "#000000",
                  }}
                >
                  In the event that any provision of these Terms of Service is
                  determined to be unlawful, void or unenforceable, such
                  provision shall nonetheless be enforceable to the fullest
                  extent permitted by applicable law, and the unenforceable
                  portion shall be deemed to be severed from these Terms of
                  Service, such determination shall not affect the validity and
                  enforceability of any other remaining provisions.
                </Typography>
              </Box>
              <Box marginTop={2}>
                <Typography
                  className="state-head-typo"
                  sx={{
                    width: "100%",
                    height: "auto",
                    fontFamily: "DM Sans",
                    fontWeight: 600,
                    fontSize: "25px",
                    lineHeight: "27px",
                    color: "#55120B",
                    padding:"10px 0px"
                  }}
                >
                  SECTION 16 - TERMINATION
                </Typography>
              </Box>
              <Box>
                <Typography
                  className="content-first-second-row-typo"
                  sx={{
                    fontFamily: "DM Sans",
                    fontWeight: 400,
                    fontSize: "18px",

                    lineHeight: "30px",
                    color: "#000000",
                  }}
                >
                  These Terms of Service are effective unless and until
                  terminated by either you or us. You may terminate these Terms
                  of Service at any time by notifying us that you no longer wish
                  to use our Services, or when you cease using our site. 
                </Typography>
                <Typography
                  className="content-first-second-row-typo"
                  sx={{
                    fontFamily: "DM Sans",
                    fontWeight: 400,
                    fontSize: "18px",

                    lineHeight: "30px",
                    color: "#000000",
                  }}
                >
                  The obligations and liabilities of the parties incurred prior
                  to the termination date shall survive the termination of this
                  agreement for all purposes. 
                </Typography>

                <Box marginTop={2}>
                  <Typography
                    className="state-head-typo"
                    sx={{
                      width: "100%",
                      height: "auto",
                      fontFamily: "DM Sans",
                      fontWeight: 600,
                      fontSize: "25px",
                      lineHeight: "27px",
                      color: "#55120B",
                      padding:"10px 0px"
                    }}
                  >
                    SECTION 17 - ENTIRE AGREEMENT
                  </Typography>
                </Box>
              </Box>

              <Box>
                <Typography
                  className="content-first-second-row-typo"
                  sx={{
                    fontFamily: "DM Sans",
                    fontWeight: 400,
                    fontSize: "18px",

                    lineHeight: "30px",
                    color: "#000000",
                  }}
                >
                  The failure of us to exercise or enforce any right or
                  provision of these Terms of Service shall not constitute a
                  waiver of such right or provision. 
                </Typography>
                <Typography
                  className="content-first-second-row-typo"
                  sx={{
                    fontFamily: "DM Sans",
                    fontWeight: 400,
                    fontSize: "18px",

                    lineHeight: "30px",
                    color: "#000000",
                  }}
                >
                  These Terms of Service and any policies or operating rules
                  posted by us on this site or in respect to the Services
                  constitutes the entire agreement and understanding between you
                  and us and govern your use of the Services and/or the purchase
                  of the products offered thereby, superseding any prior or
                  contemporaneous agreements, communications and proposals,
                  whether oral or written, between you and us (including, but
                  not limited to, any prior versions of the Terms of Service). 
                </Typography>
                <Typography
                  className="content-first-second-row-typo"
                  sx={{
                    fontFamily: "DM Sans",
                    fontWeight: 400,
                    fontSize: "18px",

                    lineHeight: "30px",
                    color: "#000000",
                  }}
                >
                  Any ambiguities in the interpretation of these Terms of
                  Service shall not be construed against the drafting party. The
                  headings used in this agreement are included for convenience
                  only and will not limit or otherwise affect these Terms.
                </Typography>
              </Box>

              <Box marginTop={2}>
                <Typography
                  className="state-head-typo"
                  sx={{
                    width: "100%",
                    height: "auto",
                    fontFamily: "DM Sans",
                    fontWeight: 600,
                    fontSize: "25px",
                    lineHeight: "27px",
                    color: "#55120B",
                    padding:"10px 0px"
                  }}
                >
                  {" "}
                  SECTION 18 - GOVERNING LAW AND JURISDICTION
                </Typography>
              </Box>
              <Box>
                <Typography
                  className="content-first-second-row-typo"
                  sx={{
                    fontFamily: "DM Sans",
                    fontWeight: 400,
                    fontSize: "18px",

                    lineHeight: "30px",
                    color: "#000000",
                  }}
                >
                  These Terms of Service and any separate agreements whereby we
                  provide you Services shall be governed by and construed in
                  accordance with the laws of the State of Delaware.
                </Typography>
                <Typography
                  className="content-first-second-row-typo"
                  sx={{
                    fontFamily: "DM Sans",
                    fontWeight: 400,
                    fontSize: "18px",

                    lineHeight: "30px",
                    color: "#000000",
                  }}
                >
                  Any and all claims will be resolved by binding arbitration,
                  rather than in court.  This includes any claims you assert
                  against us, our subsidiaries, users, or any companies offering
                  products or services through us (which are beneficiaries of
                  this arbitration agreement).  Arbitrations will be conducted
                  by the American Arbitration Association (AAA) under its rules
                  in the State of California.
                </Typography>
                <Typography
                  className="content-first-second-row-typo"
                  sx={{
                    fontFamily: "DM Sans",
                    fontWeight: 400,
                    fontSize: "18px",

                    lineHeight: "30px",
                    color: "#000000",
                  }}
                >
                  Any and all proceedings to resolve claims will be conducted
                  only on an individual basis and not in a class, consolidated,
                  or representative action.  If for any reason a claim proceeds
                  in court rather than in arbitration we each waive any right to
                  a jury trial.  An arbitration decision may be confirmed by any
                  court with competent jurisdiction
                </Typography>
              </Box>
              <Box marginTop={2}>
                <Typography
                  className="state-head-typo"
                  sx={{
                    width: "100%",
                    height: "auto",
                    fontFamily: "DM Sans",
                    fontWeight: 600,
                    fontSize: "25px",
                    lineHeight: "27px",
                    color: "#55120B",
                    padding:"10px 0px"
                  }}
                >
                  SECTION 19 - CHANGES TO TERMS OF SERVICE
                </Typography>
              </Box>

              <Box>
                <Typography
                  className="content-first-second-row-typo"
                  sx={{
                    fontFamily: "DM Sans",
                    fontWeight: 400,
                    fontSize: "18px",

                    lineHeight: "30px",
                    color: "#000000",
                  }}
                >
                  You can review the most current version of the Terms of
                  Service at any time at this page. We reserve the right, at our
                  sole discretion, to update, change or replace any part of
                  these Terms of Service by posting updates and changes to our
                  website. It is your responsibility to check our website
                  periodically for changes. Your continued use of or access to
                  our website or the Service following the posting of any
                  changes to these Terms of Service constitutes acceptance of
                  those changes.
                </Typography>
              </Box>

              <Box marginTop={2}>
                <Typography
                  className="state-head-typo"
                  sx={{
                    width: "100%",
                    height: "auto",
                    fontFamily: "DM Sans",
                    fontWeight: 600,
                    fontSize: "25px",
                    lineHeight: "27px",
                    color: "#55120B",
                    padding:"10px 0px"
                  }}
                >
                  SECTION 20 - CONTACT INFORMATION
                </Typography>
              </Box>
              <Box>
                <Typography
                  className="content-first-second-row-typo"
                  sx={{
                    fontFamily: "DM Sans",
                    fontWeight: 400,
                    fontSize: "18px",

                    lineHeight: "30px",
                    color: "#000000",
                  }}
                >
                  Questions about the Terms of Service should be sent to us
                  at Customersupport@premiumguard.com.
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
      {/* </Box> */}
      <CommonFooter />
    </LayoutComponent>
  );
};

export default TermsOfService;
