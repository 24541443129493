import React, { useEffect, useRef } from "react";
import LayoutComponent from "../../Layout/LayoutComponent";
import { Box, Typography } from "@mui/material";
import PwrProductSearch from "./PwrProductSearch";
import OurProduct from "./OurProduct";
import ReliabilityAndQuality from "./ReliabilityAndQuality";
import ViewProducts from "./ViewProducts";
import HomeSwiper from "./HomeSwiper";
import CommonFooter from "../Footer/CommonFooter";
import ExpertTips from "./ExpertTips";
import { FrequentlyAskedQuest } from "./FrequentlyAskedQuest";
import {PartNoModal} from "./PartNoModal";
import { useDispatch, useSelector } from "react-redux";
import { CommonSetupActions } from "../../Slices/Commonslice";

const HomePage = () => {
  const getInTouchRef = useRef(null);
  const {scrollAutomatic}=useSelector((state)=>state.CommonStore)
  const dispatch=useDispatch()

   useEffect(()=>{
      
    // dispatch(CommonSetupActions.resetState())

      if (!sessionStorage.getItem("hasRefreshed")) {
        sessionStorage.setItem("hasRefreshed", "true"); // Mark as refreshed
        window.location.reload();
      };
     
    },[])

 
    //   // Wait for the Constant Contact form to load
    //   const interval = setInterval(() => {
    //     const inputField = document.querySelector('.ctct-inline-form input.ctct-form-element');
    //     if (inputField) {
    //       inputField.setAttribute('placeholder', 'Enter your email');
    //       clearInterval(interval); // Stop checking once the input field is found and updated
    //     }
    //   }, 100); // Check every 100 milliseconds
    // });
  
  // Function to scroll to the GetInTouchForm component
  const scrollToGetInTouch = () => {
    getInTouchRef.current?.scrollIntoView({ behavior: "smooth" });
  };
  const [open, setOpen] = React.useState(false);

  useEffect(()=>{
  if(scrollAutomatic){
    scrollToGetInTouch()
  }
  },[scrollAutomatic])

  useEffect(()=>{
    dispatch(CommonSetupActions.resetState())
  },[])
  return (
    <LayoutComponent scrollToGetInTouch={scrollToGetInTouch}>
      <PwrProductSearch />
      {/* <div class="ctct-inline-form" data-form-id="df4ff37a-7a61-40bb-9ac1-e3c8f874a6c6"></div> */}
      <OurProduct />
       <ReliabilityAndQuality />
      <ViewProducts />
      <HomeSwiper />
      <ExpertTips/>
      <div ref={getInTouchRef}><FrequentlyAskedQuest />
     </div>
      
        <PartNoModal open={false} setOpen={setOpen} />
      <CommonFooter scrollToGetInTouch={scrollToGetInTouch} />
    </LayoutComponent>
  );
};

export default HomePage;
