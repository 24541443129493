import React, { useState, useRef } from "react";
import {
  Box,
  Typography,
  Button,
  TextField,
  InputAdornment,
  IconButton,
} from "@mui/material";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import FooterImg from "../../Assets/Home/Group 5959.png";
// import john from "../../Assets/"
import "../Home/HomeSwiperCss.css";
import cardIcon1 from "../../Assets/Home/cardIcon1.png";
import cardIcon2 from "../../Assets/Home/cardIcon2.png";
import cardIcon3 from "../../Assets/Home/cardIcon3.png";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import { FaArrowRight, FaArrowLeft } from "react-icons/fa";
import SendIcon from "@mui/icons-material/Send";
import routes from "../../AppRoutes/routes.json";
import { useNavigate } from "react-router-dom";
import { useMediaQuery } from "@mui/material";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
const HomeSwiper = () => {
  const [cardIndex, setCardIndex] = useState(1);
  console.log(FooterImg); // Logs the resolved path

  const navigate = useNavigate();
  const sliderRef = useRef(null);
  const isMobile = useMediaQuery("(max-width:665px)");
  const [cards, setCards] = useState([
    {
      id: 1,
      image: cardIcon1,
      title: "First-to-Market",
      desc: "Stay ahead with PWR Steer. We lead the industry in bringing coverage for late-model applications, ensuring you always have access to the most up-to-date solutions for modern vehicles.",
    },
    {
      id: 2,
      image: cardIcon2,
      title: "Crafted with Precision",
      desc: "Crafted with precision, every PWR Steer product meets or exceeds OE quality standards, giving you the dependable performance that professionals demand. Built to last, trusted by experts.",
    },
    {
      id: 3,
      image: cardIcon3,
      title: "Installation Ready",
      desc: "PWR Steer products come fully equipped for a hassle-free installation, packed with everything you need to complete the job on the first try. No missing pieces, no delays—just straightforward solutions that save you time and money, delivering exceptional fit and function.",
    },
    {
      id: 4,
      image: cardIcon1,
      title: "First-to-Market",
      desc: "Stay ahead with PWR Steer. We lead the industry in bringing coverage for late-model applications, ensuring you always have access to the most up-to-date solutions for modern vehicles.",
    },
    {
      id: 5,
      image: cardIcon2,
      title: "Crafted with Precision",
      desc: "Crafted with precision, every PWR Steer product meets or exceeds OE quality standards, giving you the dependable performance that professionals demand. Built to last, trusted by experts.",
    },
    {
      id: 6,
      image: cardIcon3,
      title: "Installation Ready",
      desc: "PWR Steer products come fully equipped for a hassle-free installation, packed with everything you need to complete the job on the first try. No missing pieces, no delays—just straightforward solutions that save you time and money, delivering exceptional fit and function.",
    },
  ]);
  const NextArrow = ({ onClick }) => (
    <IconButton
      onClick={onClick}
      sx={{
        position: "absolute",
        top: "50%",
        right: "-20px",
        transform: "translateY(-50%)",
        zIndex: 10,
        backgroundColor: "#EC1B24",
        color: "#FFFFFF",
        "&:hover": { backgroundColor: "#B7181E" },
      }}
    >
      <ArrowForwardIosIcon />
    </IconButton>
  );

  const PrevArrow = ({ onClick }) => (
    <IconButton
      onClick={onClick}
      sx={{
        position: "absolute",
        top: "50%",
        left: "-20px",
        transform: "translateY(-50%)",
        zIndex: 10,
        backgroundColor: "#EC1B24",
        color: "#FFFFFF",
        "&:hover": { backgroundColor: "#B7181E" },
      }}
    >
      <ArrowBackIosNewIcon />
    </IconButton>
  );

  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    centerMode: true,
    centerPadding: "0",
    focusOnSelect: true,
    initialSlide: 1,
    arrows: isMobile, // Show arrows only on mobile
    prevArrow: isMobile ? <PrevArrow /> : null,
    nextArrow: isMobile ? <NextArrow /> : null,
    responsive: [
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
    beforeChange: (oldIndex, newIndex) => {
      setCardIndex(newIndex);
    },
  };

  return (
    <>
      {/* Main Container */}
      <Box
        className="swiper_main_container"
        sx={{
          width: "100%",
          height:'auto',
          // height: "1016px",
          // top: "3105px",
          backgroundColor: "#FFFFFF",
        }}
      >
        {/* Slider Section */}
        <Box
          className="slider-box"
          sx={{
            width: "100%",
            height:'auto',
            // height: "637px",
            // top: "3105px",
            backgroundColor: "#FFFFFF",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            marginTop: "70px",
            marginBottom:'70px',
            gap:8
          }}
        >
          <Box
            className="slider-div-cont"
            sx={{
              height: "181px",
              // top: "3105px",
            }}
          >
            <Typography
              className="swiper_text_main"
              variant="h5"
              sx={{
                fontWeight: 500,
                fontSize: "40px",
                lineHeight: "50.8px",
                letterSpacing:'-1.5px',
                textAlign: "center",
                color: "#55120B",
                fontFamily: "DM Sans",
              }}
            >
              Your Trusted Source for
              <br />
              <span
                style={{
                  color: "#EC1B24",
                  fontFamily: "DM Sans",
                }}
              >
                New Power Steering Products
              </span>
            </Typography>
            <Box
              className="swiper_sub_text_container"
              sx={{
                width: "716px",
                height: "56px",
                // top: "3214px",
                justifyContent: "center",
                alignItems: "center",
                marginTop: 2,
              }}
            >
              <Typography
                className="swiper_sub_text"
                variant="body1"
                sx={{
                  fontWeight: 400,
                  size: "14px",
                  lineHeight: "23px",
                  textAlign: "center",
                  color: "#333333",
                  justifyContent: "center",
                  alignItems: "center",
                  fontFamily: "DM Sans",
                }}
              >
                PWR STEER's new power steering racks, pumps, and gear boxes are
                designed to improve operational efficiency and are supplied as
                installation ready to further address historic quality issues.
                Experience the unmatched confidence of USA engineering with PWR
                Steer products.
              </Typography>
            </Box>
          </Box>
          <Box
            className="learn_more_btv_container"
            sx={{
              width: "100%",
              height: "auto",
              // top: "3300px",
              display:'flex',
              // flexDirection:'row',
              justifyContent:'center',
              alignItems:'center',
          
            }}
          >
            <Button
              onClick={() => { sessionStorage.clear();navigate(routes.About);window.scrollTo({top:0})}}
              variant="contained"
              sx={{
                height:'50px',
                width:'159px',
                fontWeight: "500",
                fontSize: "17px",
                lineHeight: "12px",
                color: "#FFFFFF",
                textAlgin: "center",
                textTransform: "none",
                fontFamily: "DM Sans",
                borderRadius: "100px",
                backgroundColor: "#EC1B24",
              //  padding:'10px',  
              }}
              endIcon={<ArrowRightAltIcon />}
            >
              Learn More
              
            </Button>
          </Box>
          <Box
            className="carousel_main"
            sx={{
              width: "100%",
              display: "flex",
              direction: "row",
              justifyContent: "center",
              alignItems: "center",
              textAlign: "center",
            }}
          >
            <Box
              className="carousel_container"
              sx={{
                width: "70%",
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center",
                marginTop:'-36px'
              }}
            >
              <Slider ref={sliderRef} {...settings}>
                {cards.map((card, index) => {
                  const isActive = index === cardIndex;
                  const isBefore = index === cardIndex - 1;
                  const isAfter = index === cardIndex + 1;

                  return (
                    <Box
                      key={card.id}
                      className={isActive ? "slide_activeSlide" : "slide"}
                      sx={{

                        // width: { sx: isActive ? "331px" : "338px", sm: isActive ? "340px !important" : "348px !important", md: isActive ? "331px !important" : "338px !important"  },
                        // width: isActive ? "351px" : "368px",
                        width: isActive ? "30%" : "40%",
                        // height: isActive ? "452px" : "384px",

                        height: isActive ? "399px" : "344px",
                        marginTop: isActive ? "20px" : "35px",
                        textAlign: "center",
                        cursor: "pointer",
                        background: isActive
                          ? "linear-gradient(100deg, #860F14, #EC1B24)"
                          : "#000000",
                        color: isActive ? "#fff" : "#ccc",
                        // padding: "10px",
                        borderRadius: isActive
                          ? "10px"
                          : isBefore
                          ? "10px 0 0 10px"
                          : isAfter
                          ? "0 10px 10px 0"
                          : "10px",
                        boxSizing: "border-box",
                        transform: isActive
                          ? "translateY(0)"
                          : isBefore || isAfter
                          ? "translateY(20px)"
                          : "translateY(20px)",
                        transition: "transform 0.3s ease",
                      }}
                    >
                      {/* Inner Box for Content */}
                      <Box
                        sx={{
                          width: "100%",
                          height: "auto",
                          // margin: "0 auto",
                          display: "column",
                          direction: "row",
                          justifyContent: "center",
                          alignItems: "center",
                          textAlign: "center",

                          paddingTop: isActive ? "60px" : "30px",
                        }}
                      >
                        {/* Image */}
                        <Box
                          sx={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            textAlign: "center",
                            // padding: "5px",
                          }}
                        >
                          <img
                            src={card.image}
                            className="card-img"
                            style={{
                              width: "32.38px",
                              height: "42px",
                            }}
                            alt={card.title}
                          />
                        </Box>
                        {/* Title */}
                        <Box>
                        <Typography
                          variant="h5"
                          sx={{
                            color: isActive ? "#fff" : "#EC1B24",
                            fontWeight: 500,
                            size: "20px",
                            lineHeight: "30px",
                            marginTop: "30px",
                            fontFamily: "DM Sans",
                          }}
                        >
                          {card.title}
                        </Typography>
                        </Box>
                        <Box sx={{
                          width:'100%',
                          display:'flex',
                          justifyContent:'center',
                          alignItems:'center'
                        }}>
                        {/* Description */}
                        <Typography
                          variant="body2"
                          sx={{
                            width:'77%',
                            height: "auto",
                            color: "#FFFFFF",
                            fontWeight: 400,
                            fontSize: "14px",
                            lineHeight: "22.4px",
                            textAlign: "center",
                            marginTop: "20px",
                            fontFamily: "DM Sans",
                          }}
                        >
                          {card.desc}
                        </Typography>
                        </Box>
                      </Box>
                    </Box>
                  );
                })}
              </Slider>
            </Box>
          </Box>
        </Box>

        {/* Footer Section */}
        <Box
          sx={{
            width: "100%",
            // height: "315px",
            // backgroundColor: "#000000",
            backgroundImage: `url("${FooterImg}")`,
            backgroundSize:'cover',
            backgroundRepeat:'no-repeat',
            display:'flex',
            justifyContent:'center',
            flexDirection:"column",
            // alignItems:'center'
          }}
        >
          {/* Text */}
          <Box
            className="swiper_footer"
            sx={{
              width: '50%',
              display: "flex",
              flexDirection: "column",
               gap:2,
             
              color: "white",
              paddingTop: '45px',
              marginLeft: '45%',
              justifyContent:'center',
              // alignItems:'center'
            }}
          >
            <Typography className="Sign-up-typo"
              sx={{
                display: "flex",
                fontFamily: "DM Sans",
                fontWeight: "500",
                fontSize: "36px",
                lineHeight: "46px",
                color: "#FFFFFF",
                textAlign: "left",
                alignItems: "center",
                justifyContent: "fles-start",
                fontFamily: "DM Sans",
              }}
            >
              Sign up for new product updates and program announcements.
            </Typography>

            {/* Email Input */}
            {/* <Box className="email-input-main-section"
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "#FFFFFF",
                borderRadius: "100px",
                padding: "5px",
                height: "40px",
                width: "100%",
                maxWidth: "400px",
                marginTop: "20px",
              }}
            >
              <TextField
                id="custom-textfield"
                variant="standard"
                placeholder="Enter your email"
                fullWidth
                InputProps={{
                  disableUnderline: true,
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton className="icon-button"
                        sx={{
                          backgroundColor: "#EC1B24",
                          color: "#FFFFFF",
                          borderRadius: "50%",
                          width: "45px",
                          height: "45px",
                          "&:hover": {
                            backgroundColor: "#B7181E",
                          },
                        }}
                      >
                        <SendIcon className="mui-svg-icon"
                          sx={{
                            transform: "rotate(-45deg)",
                            color: "transparent",
                            stroke: "#FFFFFF",
                            strokeWidth: "1.5",
                            width: "24px",
                            height: "24px",
                          }}
                        />
                      </IconButton>
                    </InputAdornment>
                  ),
                  sx: {
                    backgroundColor: "transparent",
                    borderRadius: "100px",
                    padding: "10px",
                    fontSize: "16px",
                  },
                }}
              />
            </Box> */}
       <Box className="homeswipper-subscribe-main-div" sx={{}}>     
        
        <div className="ctct-inline-form" data-form-id="51e7fc16-bd6d-4d80-b982-50de61a033a8">
        {/* <hr/> */}
        </div>
         
        </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default HomeSwiper;
